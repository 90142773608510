<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M72.8509 3.85965C68.5876 3.85965 65.1316 7.3157 65.1316 11.5789V21.2281H95.5263C99.7895 21.2281 103.246 17.772 103.246 13.5088V11.5789C103.246 7.3157 99.7895 3.85965 95.5263 3.85965H72.8509ZM65.1316 25.0877H95.5263C101.921 25.0877 107.105 19.9036 107.105 13.5088V11.5789C107.105 5.18407 101.921 0 95.5263 0H72.8509C66.456 0 61.2719 5.18407 61.2719 11.5789V21.2281H21.2281V25.0877H61.2719V44.386H65.1316V25.0877Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M34.2544 51.1404C38.5176 51.1404 41.9737 47.6843 41.9737 43.4211V33.7719H11.5789C7.31569 33.7719 3.85965 37.228 3.85965 41.4912V43.4211C3.85965 47.6843 7.31569 51.1404 11.5789 51.1404H34.2544ZM41.9737 29.9123H11.5789C5.18407 29.9123 0 35.0964 0 41.4912V43.4211C0 49.8159 5.18407 55 11.5789 55H34.2544C40.6493 55 45.8333 49.8159 45.8333 43.4211V33.7719H85.8772V29.9123H45.8333V10.614H41.9737V29.9123Z"
  />
</template>
