<script setup lang="ts">
import { computed, onBeforeMount, onMounted } from 'vue'

import { useAuthStore } from '@/stores'

import IconBase from '@/components/icons/IconBase.vue'
import TechnicalSupport from '@/components/icons/TechnicalSupport.vue'
import BackgroundContextMenu from '../background/BackgroundContextMenu.vue'
import IconClose from '@/components/icons/IconClose.vue'

const authStore = useAuthStore()

const supportIsOpen = computed(() => authStore.supportIsOpen)

function toggleSupport(e?: Event) {
  if (e) e.stopPropagation()

  authStore.toggleSupport()
}

onMounted(() => {
  document.addEventListener('click', closeSupportPopup)
})

onBeforeMount(() => {
  document.removeEventListener('click', closeSupportPopup)
})

function closeSupportPopup(e: Event) {
  const t = e.target as HTMLElement

  const isPopup = t.closest('.support__content')
  const isOpenBtn = t.closest('.support__btn')
  const isOpenBtnInForm = t.closest('.support-btn') || t.classList.contains('support-btn')

  if (!isPopup && !isOpenBtn && !isOpenBtnInForm && supportIsOpen.value) toggleSupport()
}
</script>

<template>
  <div class="support">
    <div class="support__btn" :class="{ active: supportIsOpen }" @click="toggleSupport">
      <IconBase view-box="0 0 24 21" class="support-btn__icon">
        <TechnicalSupport />
      </IconBase>
    </div>
    <Transition name="fade">
      <div v-if="supportIsOpen" class="support__content">
        <IconBase
          view-box="0 0 13 13"
          class="support-content__close"
          icon-color="white"
          @click="toggleSupport"
        >
          <IconClose />
        </IconBase>
        <div class="support-content__bg">
          <BackgroundContextMenu></BackgroundContextMenu>
        </div>
        <div class="support-content__text">
          <span>По вопросам технической поддержки обращаться по адресу</span>
          <br /><br />
          <a class="link" href="mailto:support@i-digit.ru">support@i-digit.ru</a>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
.support {
  &__btn {
    svg {
      transition: all 0.2s linear;
      fill: $c-white;
    }

    &:hover {
      svg {
        fill: #2b2b2b;
      }
    }

    &.active {
      svg {
        fill: #2b2b2b;
      }
    }
  }
}

.theme-light {
  .support-content {
    &__bg {
      .app-background-animate {
        background-color: rgba(255 255 255 / 7%);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.support {
  &__btn {
    display: flex;
    position: fixed;
    z-index: 100;
    right: 60px;
    bottom: 60px;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: all 0.2s linear;
    border-radius: 100%;
    background-color: #2b2b2b;
    cursor: pointer;

    &:hover {
      background-color: $c-orange;
    }

    &.active {
      background-color: $c-orange;
    }
  }

  &__content {
    position: fixed;
    z-index: 100;
    right: 60px;
    bottom: 130px;
    width: 500px;
    height: 132px;
  }
}

.support-content {
  &__bg {
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  &__text {
    position: absolute;
    z-index: 200;
    inset: 0;
    padding: 30px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, transparent, rgba(#fff, 59%)) 30;
    color: $c-white;
    font-weight: 700;

    span {
      font-family: $f-family-caption;
      font-size: 14px;
    }
  }

  &__close {
    position: absolute;
    z-index: 800;
    top: 30px;
    right: 30px;
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}

.support-btn {
  &__icon {
    width: 24px;
    height: 21px;
  }
}
</style>
