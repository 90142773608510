import * as echarts from 'echarts'

import { getLegends } from '@/utils/map'

import { ChartProps, ChartTooltip } from '@/types/Chart'
import { LIMIT_IRI_VALUE } from '@/utils/consts'

const colorBorder = '#b31010'

const lineColors = [
  '#6269f0',
  '#e89568',
  '#9162f0',
  '#62aef0',
  '#f03030',
  '#f062ed',
  '#f0bd30',
  '#84e8e1',
  '#46488c',
  '#8c6146',
  '#a993bf',
  '#f06d30'
]

class Chart {
  data: number[][] = []
  canvas = {} as HTMLCanvasElement
  ctx = {} as CanvasRenderingContext2D
  dpi = 2
  width = 1480
  height = 800
  padding = 0
  delta: string[] = []
  maxX = 0
  maxY = 0
  colors = ['red', 'green']
  scale = {} as HTMLCanvasElement
  sign = ''
  settings = {} as echarts.EChartsCoreOption
  series?: unknown[] = []
  options = {} as Partial<ChartProps>
  chart?: echarts.ECharts = undefined

  constructor(options: Partial<ChartProps>) {
    this.options = options

    const legends = getLegends(options)

    this.series = this.getSeries(options, legends)

    if (this.series?.length) this.settings = this.getSettings(this.series, options)
  }

  public getSeries(options: Partial<ChartProps>, legends: string[]) {
    const lines = options.data?.lines
    if (!lines?.length) return

    const coors = lines.map((lines) => lines?.map((line) => line.coors))

    return coors.map((chart, index) => {
      const name = legends.at(index)
      const isBorder = name === LIMIT_IRI_VALUE
      const isIri = options.id === 'chart__wrapper'

      return {
        name: isBorder || !isIri ? name : `Полоса ${index + 1}`,
        type: options.type,
        showSymbol: !isBorder,
        symbolSize: 8,
        data: chart,
        itemStyle: {
          color: options.colors ? options.colors[index] : isBorder ? colorBorder : lineColors[index]
        },
        lineStyle: isBorder
          ? {
              type: [25, 10],
              width: 4
            }
          : {
              width: 3
            }
      }
    })
  }

  private getSettings(series: unknown[], options: Partial<ChartProps>) {
    const tooltip: Partial<ChartTooltip> | undefined = options.tooltip

    const graphic = options.graphic ?? undefined

    const settings: echarts.EChartsCoreOption = {
      tooltip,
      grid: {
        left: options.left
      },
      xAxis: {
        name: options?.metric?.x
      },
      yAxis: {
        name: options?.metric?.y
      },
      series,
      dataZoom: this.zoom(options),
      graphic,
      legend: {
        show: false
      }
    }

    if (options.highlightColor) {
      settings.emphasis = {
        itemStyle: { color: options.highlightColor }
      }
    }

    return settings
  }

  public draw() {
    const chart: HTMLElement | null = document.querySelector(`.${this.options.id}`)

    if (!chart) return

    const height = this.options.height ? this.options.height : 0

    if (!this.chart) {
      this.chart = echarts.init(chart, null, {
        renderer: 'canvas',
        height
      })
    }

    this.settings.stateAnimation = {
      duration: 100,
      easing: 'cubicOut'
    }

    if (this.chart) {
      this.chart.setOption(this.settings, {
        lazyUpdate: true
      })
    }

    return this.chart
  }

  zoom(options: Partial<ChartProps>) {
    return [
      {
        type: 'inside',
        ...(options.dataZoom ? { ...options.dataZoom } : {})
      },
      {
        type: 'slider'
      }
    ]
  }
}

export default (options: Partial<ChartProps>) => {
  return new Chart(options)
}
