import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import RoadIRI from '@/composition/useIRI'
import { fetchWrapper } from '@/api/fetchApi'
import { useSegmentsStore } from './road-segments'

import { RoadDefect, RoadSegment } from '@/types/Road'
import { RoadDiagnosticState, IriState } from '@/types/RoadDiagnostic'

import { ChartData } from '@/types/Chart'
import { useRoadStore } from './road'
import { standartIRI } from '@/assets/data/road-iri'

const useIRI = new RoadIRI()

export const useRoadDiagnosticStore = defineStore('road-diagnostic', () => {
  const roadStore = useRoadStore()
  const segmentsStore = useSegmentsStore()

  const road = computed(() => roadStore.getRoad)

  const state: RoadDiagnosticState = reactive({
    iri: {
      data: [] as RoadDefect[],
      chart: {} as ChartData,
      tables: [],
      correspondence: [],
      currentSegment: 0,
      cached: {
        chart: [] as ChartData[],
        tables: []
      },
      labels: [],
      avg: undefined,
      avgChart: undefined
    },
    segments: [],
    id: undefined
  })

  const getDiagnostic = computed(() => state.iri)
  const getDiagnosticChartData = computed(() => state.iri.chart)
  const getDiagnosticTab = computed(() => state.iri.currentSegment)
  const getDiagnosticChartLabels = computed(() => state.iri.labels)
  const getDiagnosticTableData = computed(() => state.iri.tables)
  const getCorrespondenceData = computed(() => state.iri.correspondence)
  const getAvgIri = computed(() => state.iri.avg)
  const getAvgIriChart = computed(() => state.iri.avgChart)
  const getLimitIri = computed(
    () => standartIRI[useIRI.getRoadCategory(road.value!.technical_category)!]
  )

  const getState = computed(() => state)
  const getSegmentTabs = computed(() => {
    return state.segments.map((segment) => ({ name: segment.name, id: segment.id }))
  })

  async function fetchIRI(roadId: number) {
    if (state.id === roadId) return

    state.id = roadId

    try {
      const response = (await fetchWrapper.get(`/roads/${roadId}/measure_iri/`, {})) as RoadDefect[]
      const segments = (await fetchWrapper.get(`/roads/${roadId}/segments/`, {})) as RoadSegment[]

      useIRI.updateChart(response, segments, setChartCache, changeSegment, 'iri')
      updateTable(response)
      segmentsStore.setSegments(segments)

      if (road.value?.technical_category)
        state.iri.correspondence = useIRI.getCorrespondence(response, road.value.technical_category)

      state.iri.data = response
      state.iri.avg = calcAvgIri()
      state.iri.avgChart = calcAvgIriChart()
    } catch (e) {
      throw new Error(e)
    }
  }

  function calcAvgIri() {
    if (!state.iri.data.length) return 0

    const sum = state.iri.data.reduce((initial, current) => initial + (current.value || 0), 0)

    if (sum === 0) return 0

    const avg = sum / state.iri.data.length

    return +avg.toFixed(2)
  }

  function setChartCache(iri: ChartData[]) {
    state.iri.cached.chart = iri
  }

  function calcAvgIriChart() {
    if (!state.iri.avg) return [0, 0, 0]

    const hasValue = state.iri.data.filter((el) => el.value)
    const value = hasValue.map((iri) => iri.value as number)

    if (!value.length) return [0, state.iri.avg, 0]

    const max = Math.max(...value)

    const limit = getLimitIri.value

    const maxValue = limit > max ? limit : max

    const percent = maxValue / 100

    const limitPercent = limit / percent
    const avgPercent = state.iri.avg / percent
    const maxPercent = max / percent

    return [limitPercent, avgPercent, maxPercent]
  }

  function changeSegment(segmentId: number, type: string) {
    const current = state[type] as IriState

    if (!current) return

    current.currentSegment = segmentId

    const chart = current.cached.chart.find((el) => el.segmentId === segmentId)

    if (chart) current.chart = chart

    if (!current.cached.tables) return

    current.tables = current.cached.tables.filter((el) => el.segmentId === segmentId)
  }

  function updateTable(response: RoadDefect[]) {
    if (!road.value?.technical_category) return

    state.iri.cached.tables = useIRI.toTableData(response, road.value.technical_category)

    state.iri.tables = state.iri.cached.tables.filter(
      (el) => el.segmentId === state.iri.currentSegment
    )
  }

  async function fetchSegments(roadId: number) {
    try {
      state.segments = (
        (await fetchWrapper.post(`/roads/${roadId}/defects/`, {})) as RoadSegment[]
      ).sort((a, b) => a.id - b.id)
    } catch (e) {
      throw new Error(e)
    }
  }

  return {
    getDiagnostic,
    getDiagnosticChartData,
    getDiagnosticChartLabels,
    getDiagnosticTableData,
    getDiagnosticTab,
    getCorrespondenceData,
    getState,
    getSegmentTabs,
    getAvgIri,
    getAvgIriChart,
    getLimitIri,

    setChartCache,

    fetchIRI,
    fetchSegments,

    changeSegment
  }
})
