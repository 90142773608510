<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import { LngLatBoundsLike, Map } from 'maplibre-gl'
import { useRoadStore } from '@/stores'
import { Breadcrumb } from '@/utils/breadcrumbs'

import AppMap from '@/components/layouts/AppMap.vue'
import AppRoadAbout from '@/components/layouts/road/AppRoadAbout.vue'
import AppRoadMoreInfo from '@/components/layouts/road/AppRoadMoreInfo.vue'
import AppRoadWidgets from '@/components/layouts/road/AppRoadWidgets.vue'

import { TBreadcrumb } from '@/types/Breadcrumb'
import AppMapBtn from '@/components/ui/AppMapBtn.vue'
import { RoadGeoJSON } from '@/types/Road'
import { AppMapIds } from '@/utils/lists'

const emits = defineEmits(['setBr'])

const route = useRoute()
const roadStore = useRoadStore()

const map = ref()

const road = computed(() => roadStore.getRoad)
const geojsonData = computed(() => roadStore.roadAxis)
const bbox = computed(() => roadStore.bbox)

const layers = reactive([
  {
    data: computed(() => geojsonData.value),
    params: {
      showed: true,
      name: 'road',
      lineWidth: ['interpolate', ['exponential', 2], ['zoom'], 1, 2, 15, 10],
      color: '#D68166'
    }
  }
])

const mapOptions = reactive({
  bounds: undefined as LngLatBoundsLike | undefined,
  center: undefined,
  interactive: false,
  fitToBounds: 1800
})

function getRoadInfo(value?: RoadGeoJSON) {
  mapOptions.bounds = value ? bbox.value : undefined
}

onMounted(() => {
  setBreadcrumb()
  getRoadInfo(geojsonData.value)
})

function mapMounted(instance: Map) {
  if (map.value) map.value.remove()

  map.value = instance

  map.value.on('idle', flyTo)
}

function flyTo() {
  if (mapOptions.fitToBounds != null) {
    nextTick(() => {
      map.value?.fitBounds(mapOptions.bounds, { duration: mapOptions.fitToBounds })
    })
  }
}

function setBreadcrumb() {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Общая информация', null))
}

onUnmounted(() => {
  map.value?.off('idle', flyTo)
})
</script>

<template>
  <main class="road-page__main">
    <h2 class="road-page__title">Общая информация</h2>
    <h3 class="road-page__route">
      {{ road?.short_name }}
    </h3>
    <div v-if="road" class="road-page__info">
      <AppRoadAbout />
      <div class="road-page__map">
        <AppMap
          v-if="mapOptions.bounds"
          :id="AppMapIds.MAIN_ROAD_PAGE"
          class="road-map"
          :layers="layers"
          :map-options="mapOptions"
          @map-mounted="mapMounted"
        />
        <div class="to-map main">
          <AppMapBtn
            :animate="true"
            text="Перейти на карту"
            :to="{ name: 'Map', query: { id: +route.params.id } }"
          />
        </div>
      </div>
      <div class="road-page__more">
        <AppRoadMoreInfo />
        <AppRoadWidgets />
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.road-main {
  &__title {
    color: $c-gray-65;
    font-family: $f-family-base;
    font-size: 1em;
    font-weight: 700;
  }

  &__value {
    margin-top: 0.625rem;
    transition: color 0.3s $main-animation;
    color: $c-white;
    font-family: $f-family-base;
    font-size: 1.27em;
    font-weight: 700;

    @include color('fontColor');

    &.empty {
      color: $c-gray-65;
    }
  }
}

.to-map.main {
  margin-top: 0;
  transition: all 0.2s linear;

  .map-btn {
    width: 270px;
    transition: all 0.2s linear;

    &__map-icon,
    &__map-arrow {
      transition: all 0.2s linear;
    }
  }
}
</style>

<style scoped lang="scss">
.road-page {
  &__main {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.25rem 0;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, max-content);
    transition: color 0.3s $main-animation;

    @include color('fontColor');
  }

  &__info {
    display: grid;
    grid-gap: 1.25rem 0;
  }

  &__map {
    display: block;
    position: relative;
    width: 100%;
    height: 300px;
  }

  &__more {
    display: grid;
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 80px;
  }

  &__route {
    margin-top: 0.625rem;
    padding: 1.1875rem 1.875rem;
    font-size: 1.275em;

    @include background-color('backgroundGeomteryLayer');
  }

  &__title {
    transition: color 0.3s $main-animation;
    font-family: $f-family-caption;
    font-size: 1.275em;
    font-weight: 700;

    @include color('fontColor');
  }
}

.to-map {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 50px;
  width: 274px;
  height: 50px;
  text-decoration: none;

  .map-btn {
    &::before {
      left: 1.25rem;
    }

    &::after {
      right: 1.5rem;
    }
  }
}

@media (width <= 1845px) {
  .road-page {
    &__info {
      grid-template-columns: 100%;
    }

    &__about {
      grid-gap: 1.25rem 0;
      grid-template-areas:
        'id'
        'belong'
        'location';
      grid-template-columns: 100%;
    }

    &__more {
      grid-gap: 1.25rem 0;
      grid-template-columns: 100%;
    }
  }
}
</style>
