import { SemaphoresGost } from '@/utils/lists/road-semaphores-gost'
import T1 from '@/assets/images/road-semaphores/T.1.png'
import T1r from '@/assets/images/road-semaphores/T.1.r.png'
import T1l from '@/assets/images/road-semaphores/T.1.l.png'
import T1rl from '@/assets/images/road-semaphores/T.1.rl.png'
import T2 from '@/assets/images/road-semaphores/T.2.png'
import T3 from '@/assets/images/road-semaphores/T.3.png'
import T3l from '@/assets/images/road-semaphores/T.3.l.png'
import T3r from '@/assets/images/road-semaphores/T.3.r.png'
import T4 from '@/assets/images/road-semaphores/T.4.png'
import T4y from '@/assets/images/road-semaphores/T.4.y.png'
import T5 from '@/assets/images/road-semaphores/T.5.png'
import T6 from '@/assets/images/road-semaphores/T.6.png'
import T6d from '@/assets/images/road-semaphores/T.6.d.png'
import T7 from '@/assets/images/road-semaphores/T.7.png'
import T8 from '@/assets/images/road-semaphores/T.8.png'
import T9 from '@/assets/images/road-semaphores/T.9.png'
import T10 from '@/assets/images/road-semaphores/T.10.png'
import P1 from '@/assets/images/road-semaphores/P.1.png'
import P2 from '@/assets/images/road-semaphores/P.2.png'
import T1h from '@/assets/images/road-semaphores/T.1.h.png'

import { GEOMETRY_CONSTANTS } from '@/utils/consts'

export const roadSemaphores = [
  {
    gost: SemaphoresGost.T1,
    img: T1,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T1r,
    img: T1r,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T1l,
    img: T1l,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T1rl,
    img: T1rl,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T1h,
    img: T1h,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T2,
    img: T2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T3,
    img: T3,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T3l,
    img: T3l,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T3r,
    img: T3r,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T4,
    img: T4,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T4y,
    img: T4y,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T5,
    img: T5,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T6,
    img: T6,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T6d,
    img: T6d,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T7,
    img: T7,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T8,
    img: T8,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T9,
    img: T9,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.T10,
    img: T10,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.P1,
    img: P1,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  },
  {
    gost: SemaphoresGost.P2,
    img: P2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    isSemaphore: true
  }
]
