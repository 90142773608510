<script lang="ts" setup>
import { RouterLink, useRoute } from 'vue-router'

import IconBase from '@/components/icons/IconBase.vue'
import IconLogo from '@/components/icons/IconLogo.vue'

import { AppTitleProps } from '@/types/AppTitle/AppTitle'

const route = useRoute()

withDefaults(defineProps<Partial<AppTitleProps>>(), {
  logoWidth: 77.8,
  logoHeight: 40,
  logoViewBox: '0 0 108 55',
  logoIconName: 'logo'
})

const isLoginPage = route.path.includes('/login')
</script>

<template>
  <div class="appTitle">
    <RouterLink
      aria-label="Главная страница"
      :to="{ name: 'Main' }"
      class="appTitle__logo"
      :class="{ 'auth-page': isLoginPage }"
    >
      <icon-base
        :width="logoWidth"
        :height="logoHeight"
        :view-box="logoViewBox"
        :icon-name="logoIconName"
      >
        <icon-logo />
      </icon-base>
    </RouterLink>
    <h1 class="appTitle__title" :class="{ 'auth-page': isLoginPage }">
      Система управления мониторингом состояния объектов жилищно-коммунального хозяйства<br />
      (теплотрасс, благоустройства (газоны, парки, городские леса), зданий (кровля, фасады),<br />
      в части модуля управления мониторингом состояния региональных и муниципальных дорог
    </h1>
  </div>
</template>

<style scoped lang="scss">
.appTitle {
  display: flex;
  align-items: center;
  height: max-content;

  &__logo {
    margin-right: 1.88rem;
  }

  &__title {
    flex-shrink: 0;
    width: max-content;
    color: $c-white;
    font-family: $f-family-base;
    font-size: 1.125em;
    font-weight: 500;
    line-height: 121%;

    &.auth-page {
      color: white;
    }
  }
}

@media (width <= 1500px) {
  .appTitle {
    &__title {
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
.appTitle {
  &__logo {
    svg g {
      transition: all 0.3s $main-animation;

      @include fill('logo');
    }

    &.auth-page {
      svg g {
        fill: white;
      }
    }
  }

  &__title {
    transition: color 0.3s $main-animation;

    @include color('fontColor');
  }
}
</style>
