import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { Map } from 'maplibre-gl'
import { MapFeatureRendered } from '@/types/Map'
import { DefectMapState } from '@/types/Defects'

export const useRoadDefectsMapStore = defineStore('road-defects-map', () => {
  let map: Map | undefined

  const state: DefectMapState = reactive({
    selectedFeature: undefined,
    hovered: undefined
  })

  const selectedFeature = computed(() => state.selectedFeature)
  const hoveredFeature = computed(() => state.hovered)

  /**
   * Добавление инстанса карты
   */
  function updateInstance(instance: Map) {
    map = instance
  }

  /**
   * Обновить выбранную фичу
   */
  function updateSelectedFeature(feature: MapFeatureRendered) {
    state.selectedFeature = feature
  }

  /**
   * Обновить ховеред фичу
   */
  function updateHoveredFeature(feature?: MapFeatureRendered) {
    state.hovered = feature
  }

  /**
   * Получить инстанс карты
   */
  function getMapInstance() {
    return map
  }

  return {
    selectedFeature,
    hoveredFeature,

    getMapInstance,

    updateInstance,
    updateSelectedFeature,
    updateHoveredFeature
  }
})
