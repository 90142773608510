<script setup></script>

<template>
  <path
    d="M15.2728 2.99981H11.6364V2.24987C11.6364 1.6532 11.4066 1.08087 10.9975 0.658921C10.5883 0.236989 10.0333 0 9.45448 0H6.54556C5.96672 0 5.41172 0.236995 5.00256 0.658921C4.59342 1.08085 4.36361 1.65322 4.36361 2.24987V2.99981H0.727214C0.325425 2.99981 0 3.33568 0 3.75002C0 4.16411 0.325438 4.49996 0.727214 4.49996H2.18164V4.54496L2.82897 15.8814C2.86144 16.4551 3.10571 16.9942 3.51132 17.3882C3.91716 17.7821 4.45366 18.001 5.01094 18H10.9929C11.5499 18.001 12.0866 17.7821 12.4922 17.3882C12.8978 16.9942 13.1421 16.4551 13.1746 15.8814L13.8184 4.54496V4.49996H15.2728C15.6746 4.49996 16 4.16409 16 3.75002C16 3.33568 15.6746 2.99981 15.2728 2.99981H15.2728ZM5.8182 2.24987C5.8182 1.83579 6.1439 1.49993 6.54568 1.49993H9.45486H9.45461C9.64764 1.49993 9.83255 1.57893 9.96902 1.71966C10.1055 1.86013 10.1821 2.05108 10.1821 2.24989V2.99983H5.81822L5.8182 2.24987ZM11.7203 15.7949C11.6972 16.1914 11.3781 16.5006 10.9931 16.4998H5.00729C4.62224 16.5006 4.30313 16.1914 4.28008 15.7949L3.63655 4.4999H12.3639L11.7203 15.7949Z"
    fill="currentFill"
  />
</template>

<style></style>
