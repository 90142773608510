<script setup lang="ts">
import { computed, inject, Ref, ref, watch, onUnmounted, onMounted } from 'vue'
import {
  useDefectsStore,
  useDefectChartStore,
  useRoadStore,
  useRoadDefectsPopupStore,
  useRoadDefectsMapStore
} from '@/stores'
import { useChartStore } from '@/stores/chart'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import useMapDefects from '@/composition/defects/useMapDefects'
import useChartDefects from '@/composition/defects/useChartDefects'
import useDefectPopup from '@/composition/defects/useDefectPopup'
import useChartDefectsHighlight from '@/composition/defects/useChartDefectsHighlight'

import DiagnosticDefectPopup from '@/components/layouts/road/diagnostic/defects/DiagnosticDefectPopup.vue'
import DefectsMap from '@/components/layouts/road/diagnostic/defects/DefectsMap.vue'
import DefectsChart from '@/components/layouts/road/diagnostic/defects/DefectsChart.vue'

import type { DefectVisible } from '@/types/Defects'
import type { Point } from 'chart.js'
import type { ECElementEvent } from 'echarts'

const defectsStore = useDefectsStore()
const chartDefectsStore = useDefectChartStore()
const roadStore = useRoadStore()
const roadDefectsPopupStore = useRoadDefectsPopupStore()
const roadDefectsMapStore = useRoadDefectsMapStore()
const chartStore = useChartStore()
const roadDefectFilterStore = useDefectFilterStore()

const { rerenderMapFeatures, flyToMap } = useMapDefects()
const { openDefectPopup, closeDefectPopup } = useDefectPopup()

const { toggleSelectChartLegend } = useChartDefects()
const { chartDefectClick } = useChartDefectsHighlight()

const pageWrapper: Ref<HTMLElement | undefined> | undefined = inject('pageWrapper')

const defectPopupPos = ref()

const renderData = computed(() => roadStore.renderData)
const defects = computed(() => defectsStore.defects)
const visibleDefects = computed(() => roadDefectFilterStore.visibleDefects)
const currentDefect = computed(() => defectsStore.currentDefect)
const defectNames = computed(() => roadDefectFilterStore.defectNames)
const popupIsOpen = computed(() => roadDefectsPopupStore.isOpen)

onUnmounted(() => {
  chartDefectsStore.setSelected([])
  defectsStore.setCurrentDefect([])
  closeDefectPopup()
})

onMounted(() => {
  if (defectNames.value.length) chartDefectsStore.setChartData()
})

watch(
  defectNames,
  (val) => {
    const map = roadDefectsMapStore.getMapInstance()
    if (!val.length || !map) return

    chartDefectsStore.setChartData()

    const renderParams = {
      map,
      defects: defects.value,
      renderData: renderData.value
    }

    rerenderMapFeatures(renderParams)
  },
  {
    deep: true
  }
)

onUnmounted(() => {
  const chart = chartStore.getChartInstance()
  chart?.dispose()
})

watch(
  visibleDefects,
  (value: DefectVisible[]) => {
    const chart = chartStore.getChartInstance()
    const map = roadDefectsMapStore.getMapInstance()
    if (!map || !chart) return

    toggleSelectChartLegend(value, chart)
  },
  {
    deep: true
  }
)

function changeDefectPopupPosition(e?: Partial<ECElementEvent>) {
  const event = e?.event?.event as Point

  defectPopupPos.value = {
    x: event ? event.x : 150,
    y: (event ? event.y : 150) - (pageWrapper?.value?.scrollTop ?? 0)
  }
}

function chartClick(e: Partial<ECElementEvent>) {
  const map = roadDefectsMapStore.getMapInstance()
  if (!currentDefect.value || !map) return

  changeDefectPopupPosition(e)
  openDefectPopup(currentDefect.value)

  flyToMap(map)
}

function mapClick(e: Partial<ECElementEvent>) {
  const map = roadDefectsMapStore.getMapInstance()
  if (!map || !currentDefect.value) throw new Error('Недостаточно данных')

  changeDefectPopupPosition()
  openDefectPopup(currentDefect.value)

  const [selected, defect] = chartDefectClick(e)

  chartDefectsStore.setSelected(selected)
  defectsStore.setCurrentDefect(defect)
}
</script>

<template>
  <Transition name="fade">
    <div class="defects__chart">
      <DefectsChart @chart-clicked="chartClick" />
    </div>
  </Transition>
  <Transition name="fade">
    <DiagnosticDefectPopup
      v-if="popupIsOpen"
      :data="currentDefect"
      :position="defectPopupPos"
    ></DiagnosticDefectPopup>
  </Transition>
  <div class="defects__map">
    <DefectsMap @map-clicked="mapClick" />
  </div>
</template>

<style lang="scss" scoped>
.defects {
  &__chart {
    width: 100%;
    height: 620px;
    margin-top: 60px;
  }

  &__map {
    width: 100%;
    height: auto;
    min-height: 550px;
    margin-top: 75px;
    margin-bottom: 120px;
    overflow: hidden;
    border-radius: 25px;

    @include box-shadow('boxShadowDefectMap');

    .maplibregl-map {
      height: 100%;
    }
  }
}

.defects-navigation {
  &__btn {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: $c-orange;
    cursor: pointer;

    &.disabled {
      background-color: $c-gray-15;
      pointer-events: none;
    }
  }
}

@media (width <= 1650px) {
  .defects__map {
    margin-top: 150px;
  }
}
</style>
