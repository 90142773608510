interface BaseTab {
  id: number | string
  [index: string]: string | number | null
}

interface TabsParams<T> {
  data: T[]
  key: string
  active?: number | string
}

function dataToTabs<T extends BaseTab>(params: TabsParams<T>) {
  const { data, key, active } = params

  return data.map((item) => ({ name: item[key], id: item.id, active: item.id === active }))
}

export default function () {
  return {
    dataToTabs
  }
}
