import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { useRoadStore } from '@/stores/road/road'
import { useDefectsStore } from '@/stores/road/road-defects'
import { useSegmentsStore } from '@/stores/road/road-segments'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import useChartDefects from '@/composition/defects/useChartDefects'

import { ChartData, SelectedChartItem } from '@/types/Chart'
import { DefectsChartState } from '@/types/Defects'

export const useDefectChartStore = defineStore('defect-chart-store', () => {
  const roadStore = useRoadStore()
  const defectsStore = useDefectsStore()
  const segmentsStore = useSegmentsStore()
  const defectFilterStore = useDefectFilterStore()

  const { dataToChart } = useChartDefects()

  const state: DefectsChartState = reactive({
    data: {} as ChartData,
    settings: {
      series: {
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(217, 233, 254, 0.01)'
        },
        barGap: '0%',
        label: {
          offset: [15, 0]
        }
      }
    },
    selected: []
  })

  const defects = computed(() => defectsStore.defects)
  const currentSegment = computed(() => segmentsStore.currentTab)
  const defectNames = computed(() => defectFilterStore.defectNames)
  const road = computed(() => roadStore.getRoad)
  const selectedItems = computed(() => state.selected)

  const chartData = computed(() => state.data)
  const series = computed(() => state.settings.series)

  function setSelected(items: SelectedChartItem[]) {
    state.selected = items
  }

  /**
   * Обновление данных графика
   */
  function setChartData() {
    if (!currentSegment.value || !road.value?.id || !defects.value)
      throw new Error('Недостаточно данных')

    const params = {
      currentSegment: currentSegment.value,
      defects: defects.value,
      names: defectNames.value
    }

    state.data = dataToChart(params, road.value.id)
  }

  return {
    chartData,
    series,
    selectedItems,

    setSelected,
    setChartData
  }
})
