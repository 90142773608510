import { reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { RoadResponse } from '@/types/Road'
import { useRoadStore } from './road'
import { RoadInfoNames } from '@/utils/lists'

interface IRoadAbout {
  info: {
    [key: string]: Array<{
      name: string
      value: number | string | undefined | null
    }>
  }
  road?: RoadResponse
}

const SKDF_URL = 'https://скдф.рф/roads/'

const useRoadAboutInfo = defineStore('road-about-info', () => {
  const roadStore = useRoadStore()

  const state: IRoadAbout = reactive({
    road: computed(() => roadStore.getRoad),
    info: {
      id: [
        {
          name: RoadInfoNames.ID,
          value: computed(() => state.road?.identification_number)
        },
        {
          name: RoadInfoNames.REGISTRATION,
          value: computed(() => state.road?.account_number)
        },
        {
          name: RoadInfoNames.CORE_NETWORK,
          value: computed(() => (state.road?.is_part_reference_network ? 'Да' : 'Нет'))
        },
        {
          name: RoadInfoNames.SKDF_NUMBER,
          value: computed(() =>
            state.road?.skdf_number === '__release_later__' || !state.road?.skdf_number
              ? ''
              : SKDF_URL + state.road?.skdf_number
          )
        }
      ],
      location: [
        {
          name: RoadInfoNames.TERRITORY,
          value: computed(() => state.road?.territory?.name)
        },
        {
          name: RoadInfoNames.REGION,
          value: computed(() => state.road?.region)
        },
        {
          name: RoadInfoNames.LOCALITY,
          value: computed(() => state.road?.locality)
        }
      ],
      owner: [
        {
          name: RoadInfoNames.OWNER,
          value: computed(() => state.road?.owner)
        },
        {
          name: RoadInfoNames.BOOK_VALUE,
          value: computed(() =>
            state.road?.balance_price ? (+state.road.balance_price).toLocaleString() : ''
          )
        },
        {
          name: RoadInfoNames.RESIDUAL_VALUE,
          value: computed(() =>
            state.road?.ostatoc_price ? (+state.road.ostatoc_price).toLocaleString() : ''
          )
        }
      ]
    }
  })

  function setRoad(road: RoadResponse) {
    state.road = road
  }

  const getInfo = computed(() => state.info)

  return {
    getInfo,
    setRoad
  }
})

export default useRoadAboutInfo
