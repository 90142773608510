<script lang="ts" setup>
import { computed } from 'vue'

import { colorsFilter } from '@/assets/data/defect-filter'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import AppSelect from '@/components/ui/AppSelect.vue'
import InputField from '@/components/ui/InputField.vue'

import type { OptionsMask } from '@/types/RoadDiagnostic'
import type { SelectOption } from '@/types/Select'

interface Props {
  style: {
    val: OptionsMask
  }
}

const props = defineProps<Props>()

const defectFilterStore = useDefectFilterStore()

const style = computed(() => props.style.val)
const color = computed(() => style.value.color)
const activeColor = computed(() => colorsFilter.find((el) => el.value === color.value)!)

function changeColor(option: SelectOption) {
  defectFilterStore.changeDefectColor(option, style.value.id)
}
</script>

<template>
  <div class="customMask">
    <AppSelect
      class="customMask__select"
      :options="colorsFilter"
      :model-value="activeColor"
      mode="color"
      @update:model-value="changeColor"
    ></AppSelect>
    <div class="range">
      <InputField
        v-model="style.opacity"
        type="range"
        :min="15"
        :max="100"
        class="rangeOpacity"
      ></InputField>
      <span class="range__value"> {{ style.opacity }}% </span>
    </div>
  </div>
</template>

<style lang="scss">
.customMask {
  &__select {
    .select__list {
      overflow: hidden;
    }

    .select-list__wrapper {
      overflow-x: hidden;
    }
  }
}
</style>

<style lang="scss" scoped>
.customMask {
  display: flex;
  position: relative;
  align-items: center;
  gap: 20px;

  &__select {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1px solid $c-white;
  }
}

.selectWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 30px;

  &__btn {
    width: 100%;
    height: 30px;
    border: 1px solid white;
    cursor: pointer;
  }

  &__selectMenu {
    position: absolute;
    z-index: 2;
    top: 29px;
    width: 35px;
    height: 120px;
    overflow-y: scroll;
    border: 1px solid white;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(32 31 34 / 90%);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #d68166;
    }
  }

  &__itemMenu {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid white;
  }

  &__itemMenu:last-child {
    border: none;
  }
}

.range {
  display: flex;
  align-items: center;
  gap: 10px;

  &__value {
    display: flex;
    justify-content: center;
    width: 32px;
  }
}

.rangeOpacity {
  width: 110px;
  cursor: pointer;
}
</style>
