<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.8264 20L14 13.1071L16.0868 11L25 20L16.0868 29L14 26.8929L20.8264 20Z"
    fill="currentFill"
  />
</template>
