import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'

import { AnalyticRoadResponse } from '@/types/Road'

interface AnalyticStore {
  dashboard?: AnalyticRoadResponse
}

export const useAnalyticStore = defineStore('analytic-store', () => {
  const state: AnalyticStore = reactive({
    dashboard: undefined
  })

  const dashboard = computed(() => state.dashboard)

  async function fetchDashboard() {
    const response = (await fetchWrapper.get('/dashboard/', {})) as AnalyticRoadResponse

    state.dashboard = response
  }

  return {
    dashboard,
    fetchDashboard
  }
})
