import { capitalize } from '@/utils/string'

import type { LangDictionary } from '@/types/Language'

const dictionary: LangDictionary = {
  crack: 'трещина',
  pothole: 'выбоина',
  'linestring tool (l)': 'линия',
  'polygon tool (p)': 'многоугольник',
  'marker tool (m)': 'точка',
  delete: 'удалить',
  'reset bearing to north': 'сбросить направление',
  'incorrect username or password': 'неправильный логин или пароль',
  name: 'наименование',
  meaning: 'значение',
  identification_number: 'идентификационный номер',
  account_number: 'идентификационный номер',
  length: 'протяженность',
  skdf_number: 'номер СКДФ',
  technical_category: 'техническая категория',
  state: 'нормативное состояние'
}

export function l(key: string, reverse = false) {
  if (reverse) {
    let translate

    for (const eng in dictionary) {
      if (dictionary[eng].toLowerCase() === key.toLowerCase()) {
        translate = eng
      }
    }

    if (!translate) return key

    if (key[0] === key[0].toUpperCase()) return capitalize(translate)

    return translate
  }

  const translate = dictionary[key.toLowerCase()]
  if (!translate) return key

  if (key[0] === key[0].toUpperCase()) return capitalize(translate)

  return translate
}
