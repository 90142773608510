import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'

import { RouteLocation } from 'vue-router'
import { computed } from 'vue'

const publicPages = ['/login']

async function adminRedirect() {
  const userStore = useUserStore()

  const user = computed(() => userStore.user)

  const isAdmin = user.value?.is_admin

  if (!isAdmin) {
    return {
      path: '/'
    }
  }
}

async function appGlobalRedirect(to: RouteLocation) {
  const userStore = useUserStore()
  const authStore = useAuthStore()

  const authRequired = !publicPages.includes(to.path)

  const notAuthorithed = authRequired && !authStore.token

  if (notAuthorithed) {
    return {
      path: '/login'
    }
  }

  const isAdminRedirect = authRequired && authStore.token
  if (!isAdminRedirect) return

  await userStore.aboutMe()

  const isAdminPage = to.path === '/admin'
  if (isAdminPage) return await adminRedirect()
}

export default function () {
  return {
    appGlobalRedirect
  }
}
