<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'

import { App, LongRaceDistortion } from '@/assets/js/InfiniteLights.js'

const container = ref()

let infiniteLights: {
  resize: () => void
  destroy: () => void
  loadAssets: () => Promise<void>
  init: () => void
}

const options = {
  distortion: LongRaceDistortion,

  length: 200,
  roadWidth: 10,
  islandWidth: 5,
  lanesPerRoad: 1,

  fov: 160,
  fovSpeedUp: 150,
  speedUp: 2,
  carLightsFade: 0.4,

  totalSideLightSticks: 25,
  lightPairsPerRoadWay: 35,

  // Percentage of the lane's width
  shoulderLinesWidthPercentage: 0.05,
  brokenLinesWidthPercentage: 0.1,
  brokenLinesLengthPercentage: 0.5,

  /*** These ones have to be arrays of [min,max].  ***/
  lightStickWidth: [0.12, 0.5],
  lightStickHeight: [1.3, 1.7],

  movingAwaySpeed: [2, 8],
  movingCloserSpeed: [-2, -6],

  /****  Anything below can be either a number or an array of [min,max] ****/

  // Length of the lights. Best to be less than total length
  carLightsLength: [400 * 0.05, 400 * 0.15],
  // Radius of the tubes
  carLightsRadius: [0.05, 0.14],
  // Width is percentage of a lane. Numbers from 0 to 1
  carWidthPercentage: [0.3, 0.5],
  // How drunk the driver is.
  // carWidthPercentage's max + carShiftX's max -> Cannot go over 1.
  // Or cars start going into other lanes
  carShiftX: [-0.2, 0.2],
  // Self Explanatory
  carFloorSeparation: [0.05, 1],

  colors: {
    roadColor: 0x080808,
    islandColor: 0x0a0a0a,
    background: 0x1a191d,
    shoulderLines: 0x131318,
    brokenLines: 0x131318,
    /***  Only these colors can be an array ***/
    leftCars: [0xedb7a5, 0xeb8260, 0x92513c],
    rightCars: [0xf4f4fe, 0xcdd1ef, 0x6f707a],
    sticks: 0xa4e3e6
  }
}

function resizeHandler() {
  infiniteLights.resize()
}

onMounted(() => {
  if (!container.value) return

  infiniteLights = new App(container.value, options)
  infiniteLights.loadAssets().then(infiniteLights.init)

  window.addEventListener('resize', resizeHandler)
})

onUnmounted(() => {
  infiniteLights.destroy()
  window.removeEventListener('resize', resizeHandler)
})
</script>

<template>
  <div ref="container" class="infinityLights"></div>
</template>

<style lang="scss">
.infinityLights {
  position: absolute;
  z-index: 0;
  inset: 0;
}
</style>
