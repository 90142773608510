<script setup lang="ts">
import AppAccordion from '@/components/ui/AppAccordion.vue'
import TableWay from '@/components/layouts/table/TableGeneration/TableWay.vue'

import {
  ResponseTable,
  ResponseTableData,
  RoadObjectTypes,
  TableHeaderTabs
} from '@/types/TableGeneration'
import { ref } from 'vue'
import { useRoadTableGenerationStore } from '@/stores/road/road-tableGeneration'
import AppPagination from '@/components/ui/AppPagination.vue'

interface TableData {
  id: number
  data: {
    header: TableHeaderTabs[]
    info: ResponseTableData[]
  }
  pagination: {
    current: number
    total: number
    numberOfPages: number
    elementsOnPage: number
  }
}

interface Props {
  item: Pick<RoadObjectTypes, 'id' | 'name'>
  index: number
}

defineProps<Props>()

const objectTypeStore = useRoadTableGenerationStore()

const tableData = ref<TableData[]>([])
const accordion = ref()
const pageLoading = ref(false)

async function openAccordion(id: number) {
  try {
    await updateTable(id)
  } catch (e) {
    throw new Error(e.message)
  }
}

async function updateTable(id: number, page = 1) {
  const tableTabs = (await objectTypeStore.openTableDataHeader(id)) as TableHeaderTabs[]

  const responseData = (await objectTypeStore.openTableData(id, page)) as ResponseTable

  const hasData = tableData.value.find((el) => el.id === id)

  if (hasData) {
    hasData.data = {
      header: tableTabs.filter((el) => el.visible),
      info: responseData.data
    }

    hasData.pagination.current = responseData.page_number
  } else {
    const table = {
      id,
      data: {
        header: tableTabs.filter((el) => el.visible),
        info: responseData.data
      },
      pagination: {
        current: responseData.page_number,
        total: responseData.total,
        numberOfPages: responseData.number_of_pages,
        elementsOnPage: responseData.element_on_page
      }
    }

    tableData.value.push(table)
  }
}

function getCurrentData(id: number) {
  return tableData.value.find((el) => el.id === id)?.data
}

function getCurrentPagination(id: number) {
  return tableData.value.find((el) => el.id === id)?.pagination
}

async function changePagination(page: number, id: number) {
  const current = tableData.value.find((el) => el.id === id)

  try {
    if (current) {
      pageLoading.value = true
      accordion.value.setLoading(pageLoading.value)
      current.pagination.current = page

      await updateTable(current.id, current.pagination.current)

      const slot = accordion.value.body.querySelector('.accordion__slot')

      if (slot) accordion.value.body.style.maxHeight = `${slot.offsetHeight}px`
    }
  } catch (e) {
    throw new Error(e.message)
  } finally {
    pageLoading.value = false
    accordion.value.setLoading(pageLoading.value)
  }
}
</script>

<template>
  <AppAccordion
    ref="accordion"
    class="roadway__accordion global-accordion"
    :title="item.name"
    :async-cb="() => openAccordion(item.id)"
  >
    <TableWay :data="getCurrentData(item.id)" way-name="item.name"></TableWay>
    <AppPagination
      :class="{
        full: (getCurrentPagination(item.id)?.numberOfPages || 0) > 1,
        disabled: pageLoading
      }"
      :current="getCurrentPagination(item.id)?.current"
      :max="getCurrentPagination(item.id)?.numberOfPages"
      :left="5"
      :navigation="true"
      @change-page="(page: number) => changePagination(page, item.id)"
    ></AppPagination>
  </AppAccordion>
</template>

<style lang="scss">
.roadway {
  &__accordion {
    .pagination {
      &.full {
        margin: 40px 0;
      }

      &.disabled {
        pointer-events: none;

        .pagination {
          &__item {
            &.active {
              border: 1px solid $c-gray-40;
            }
          }

          &__next,
          &__prev {
            svg {
              fill: $c-gray-40;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.roadway {
  &__accordion {
    width: 100%;
    max-width: 1410px;
  }
}

@media (width <= 1912px) {
  .roadway {
    &__accordion {
      max-width: calc(100vw - 502px);
    }
  }
}

@media (width <= 1490px) {
  .roadway {
    &__accordion {
      max-width: calc(100vw - 432px);
    }
  }
}
</style>
