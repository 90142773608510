import { PaginationSettings } from '@/types/Pagination'

const MIN_PAGINATION_PAGES = 2
const MIN_PAGES_PAGINATION_SPLICE = 5
const PAGINATION_OFFSET_SPLICE = 2

class Pagination {
  left = 1
  current = 1
  max = 1
  items: Array<number | string> = []
  cached = 1
  navigation = false

  constructor(options: PaginationSettings) {
    this.left = options.left
    this.current = options.current
    this.max = options.max
    this.navigation = options.navigation

    if (!this.allowToSplice) {
      this.items = [...Array(this.max).keys()].map((el) => el + 1)

      return
    }

    this.items = this.paginationItems

    this.changeItems()
    this.setDilimiters()
  }

  get allowToSplice() {
    return (
      this.max >= MIN_PAGES_PAGINATION_SPLICE &&
      this.left > MIN_PAGINATION_PAGES &&
      this.max - this.left >= PAGINATION_OFFSET_SPLICE
    )
  }

  centerToPagination(center: Array<number>) {
    const firstCenter = center.at(0) as number
    const firstPagination = this.items.at(0) as number

    const hasDelimiter = firstCenter - firstPagination > 1

    if (hasDelimiter) {
      this.items.splice(2, 0, ...center)
    } else {
      this.items.splice(1, 0, ...center)
    }
  }

  change(value: number) {
    if (!(value <= this.max && value > 0)) return

    this.cached = this.current
    this.current = value

    if (!this.allowToSplice) return

    this.clearItems()
    this.changeItems()
    this.setDilimiters()
  }

  changeItems() {
    const center = this.getCenterItems()

    this.items.splice(1, 0, ...center)
  }

  get paginationItems() {
    return [1, this.max]
  }

  clearItems() {
    const [first, ...rest] = this.items
    const last = rest.at(-1)

    this.items = [first, last!]
  }

  getCenterItems() {
    const centerArr = Math.floor(this.left / 2)

    const start = this.current - centerArr
    const end = this.current + centerArr

    let center = [] as number[]

    const condition = this.left % 2 === 1 ? end + 1 : end

    for (let i = start; i < condition; i++) {
      center.push(i)
    }

    const shift = center.filter((el) => el <= 0)

    if (shift.length > 0) {
      center = center.map((el) => el + shift.length)
    }

    this.equalLeft(center)
    this.equalRight(center)

    return center
  }

  equalLeft(center: Array<number>) {
    const first = this.items.at(0) as number
    const firstCenter = center.at(0) as number

    const firstDiff = firstCenter - first <= 1
    const second = firstCenter - 1 === this.items.at(0)

    if (firstDiff && !second) {
      center.shift()
    }
  }

  equalRight(center: Array<number>) {
    const last = this.items.at(-1) as number
    const lastCenter = center.at(-1) as number
    const lastDiff = last - lastCenter

    if (lastDiff < 1) {
      for (let i = lastDiff; i <= 0; i++) {
        center.pop()
      }
    }

    if (this.current > this.max - this.left) {
      center.length = 0
      const start = this.max - this.left

      for (let i = start; i < this.max; i++) {
        center.push(i)
      }
    }
  }

  setDilimiters() {
    const [first, second, ...rest] = this.items as Array<number>
    const [last, prevLast] = rest.reverse()

    if (second - first > 1 && this.current > 3) {
      this.items.splice(1, 0, '...')
    }

    if (last - prevLast > 1) {
      this.items.splice(-1, 0, '...')
    }
  }

  get showNav() {
    return this.navigation && this.max >= MIN_PAGES_PAGINATION_SPLICE
  }

  get allowPrev() {
    return this.current > 1
  }

  get allowNext() {
    return this.current < this.max
  }
}

export default (options: PaginationSettings) => {
  const pagination = new Pagination(options)

  return pagination
}
