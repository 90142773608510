<script setup lang="ts">
import { TTerritory } from '@/stores/territories'
import { TUser } from '@/types/User'
import { reactive, watch } from 'vue'
import IconBase from '@/components/icons/IconBase.vue'
import IconChangeElement from '@/components/icons/IconChangeElement.vue'
import IconRemove from '@/components/icons/IconRemove.vue'

const props = defineProps<{
  territories: TTerritory[]
  users: TUser[] | null
}>()

const expanded: Array<boolean> = reactive(Array(100).fill(false))

// expand first visible territory
watch(
  () => props.territories,
  (newTerr) => {
    try {
      expanded.fill(false)
      expanded[newTerr[0].id] = true
    } catch {}
  }
)

function expand(id: number) {
  expanded[id] = !expanded[id]
}
</script>
// TODO: отображение пользователей без территории // TODO: отображение пустых территорий
<template>
  <table class="user-table">
    <thead>
      <tr>
        <th class="user-table__hid">№</th>
        <th class="user-table__hname">Наименование</th>
        <th class="user-table__hlogin">Логин</th>
        <th class="user-table__hbuttons"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="territory in territories" :key="territory.id">
        <tr>
          <td>{{ territory.id }}</td>
          <td>
            <button class="btn-expand" @click="expand(territory.id)">
              {{ expanded[territory.id] ? '-' : '+' }}
            </button>
            {{ territory.name }}
          </td>
          <td></td>
          <td></td>
        </tr>
        <template v-for="user in users" :key="user.id">
          <tr
            v-show="expanded[user.territory_id]"
            v-if="user.territory_id == territory.id"
            class="user-table__user"
          >
            <td></td>
            <td class="user-table__user__name">
              {{ user.last_name + ' ' + user.first_name + ' ' + user.middle_name }}
            </td>
            <td>{{ user.username }}</td>
            <td>
              <div class="user-table__buttons">
                <div class="user-table--icon" @click="console.log('edit')">
                  <IconBase width="25" height="25" view-box="7 7 25 25">
                    <IconChangeElement />
                  </IconBase>
                </div>
                <div class="user-table--icon" @click="console.log('delete')">
                  <IconBase>
                    <IconRemove />
                  </IconBase>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<style lang="scss">
.user-table {
  font-family: $f-family-base;
  font-weight: 600;
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;

  thead {
    height: 60px;
    color: $c-orange;
    font-size: 1.12rem;
  }

  td,
  th {
    border: 1px solid #686868;
    padding: 15px 30px;
    text-align: left;
  }

  tr {
    transition: 0.2s;
  }
  tr:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.07);
  }

  td:first-child,
  td:last-child {
    text-align: center;
  }

  &__user {
    &__name {
      padding-left: 80px !important;
    }
  }
  &--icon {
    display: grid;
    align-items: center;
    justify-content: center;
    // width: 50px;
    // height: 50px;
    transition: all 0.2s $main-animation 0.1s;
    border-radius: 100%;
    fill: $c-orange;
    // @include background-color('headerNavIconBG');

    cursor: pointer;

    svg {
      transition: all 0.2s $main-animation;
    }
  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
  }

  // 5%   50%   35%   10%
  &__hid {
    width: 5%;
  }
  &__hname {
    width: 50%;
  }
  &__hlogin {
    width: 35%;
  }
  &__hbuttons {
    width: 10%;
  }
}

.btn-expand {
  border: 1px solid $c-orange;
  background-color: rgba(255, 255, 255, 0.07);
  color: white;
  width: 20px;
  height: 20px;
  font-size: 1.1rem;
  cursor: pointer;
}
</style>
