export const colorsFilter = [
  {
    filter: [
      'invert(22%)',
      'sepia(42%)',
      'saturate(3818%)',
      'hue-rotate(345deg)',
      'brightness(97%)',
      'contrast(83%)'
    ],
    value: '#B82E2E',
    title: '',
    id: 2,
    active: false
  },
  {
    filter: [
      'invert(31%)',
      'sepia(91%)',
      'saturate(1276%)',
      'hue-rotate(202deg)',
      'brightness(89%)',
      'contrast(84%)'
    ],
    value: '#3366CC',
    title: '',
    id: 6,
    active: false
  },
  {
    filter: [
      'invert(37%)',
      'sepia(97%)',
      'saturate(2387%)',
      'hue-rotate(317deg)',
      'brightness(93%)',
      'contrast(86%)'
    ],
    value: '#DD4477',
    title: '',
    id: 1,
    active: false
  },
  {
    filter: [
      'invert(67%)',
      'sepia(70%)',
      'saturate(4510%)',
      'hue-rotate(49deg)',
      'brightness(95%)',
      'contrast(101%)'
    ],
    value: '#66AA00',
    title: '',
    id: 3,
    active: false
  },
  {
    filter: [
      'invert(38%)',
      'sepia(81%)',
      'saturate(992%)',
      'hue-rotate(157deg)',
      'brightness(100%)',
      'contrast(100%)'
    ],
    value: '#0099C6',
    title: '',
    id: 4,
    active: false
  },
  {
    filter: [
      'invert(69%)',
      'sepia(26%)',
      'saturate(7386%)',
      'hue-rotate(1deg)',
      'brightness(105%)',
      'contrast(106%)'
    ],
    value: '#FF9900',
    title: '',
    id: 5,
    active: false
  },
  {
    filter: [
      'invert(10%)',
      'sepia(96%)',
      'saturate(4265%)',
      'hue-rotate(270deg)',
      'brightness(80%)',
      'contrast(118%)'
    ],
    value: '#4B0092',
    title: '',
    id: 7,
    active: false
  }
]
