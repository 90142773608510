import { fetchWrapper } from '@/api/fetchApi'
import { AdminDictionary, Dictionary } from '@/types/AdminDictionary'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

const useAdminStore = defineStore('admin-store', () => {
  const state: AdminDictionary = reactive({
    dictionaries: {
      data: [],
      current: undefined,
      __fetching: false,
      __error: false,
      __success: false
    }
  })

  const dictionaries = computed(() => state.dictionaries.data)
  const currentDictionary = computed(
    () => dictionaries.value.find((el) => el.id === state.dictionaries.current)?.data
  )

  function setCurrentDictionary(id: number) {
    state.dictionaries.current = id
  }

  async function openDictionary(id: number) {
    try {
      state.dictionaries.__fetching = true

      const response = (await fetchWrapper.get(
        `/dicts/object-types/${id}/specs/collection`,
        {}
      )) as Dictionary[]

      state.dictionaries.data.push({ id, data: response })

      state.dictionaries.__fetching = false
      state.dictionaries.__success = true
      state.dictionaries.__error = undefined
    } catch (error) {
      state.dictionaries.__success = false
      state.dictionaries.__error = error
    }
  }

  return {
    dictionaries,
    currentDictionary,

    setCurrentDictionary,
    openDictionary
  }
})

export default useAdminStore
