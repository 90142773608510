import { ComputedRef } from 'vue'
import maplibregl, { Listener, LngLatLike, Map, MapGeoJSONFeature, Marker } from 'maplibre-gl'

import GeometryCreateSign from '@/composition/geometry/geometry-create-sign'

import { roadSemaphores } from '@/assets/data/road-semaphores'
import { signData } from '@/assets/data/road-sign'

import { GEOMETRY_CONSTANTS } from '@/utils/consts'

import { Markers, RoadGeoJSON, RoadSignStore } from '@/types/Road'
import { GostData, SignInCluster } from '@/types/RoadSign'
import { azimuth, inAzimuthRange } from '@/utils/azimuth'

const geometryCreateSign = new GeometryCreateSign()

const SING_VISION_RANGE = 90

class MapRoadSignRender {
  markers: Marker[] = []
  zoom = 0
  zoomTimeout = 0
  zooming = false
  renderedFeatures?: ComputedRef<RoadGeoJSON>
  features: RoadGeoJSON[] = []
  first = true
  markersOnScreen: Markers = {}
  roadSignStore?: RoadSignStore
  gostData?: GostData[]
  renderCb?: Listener

  render(map: Map, sourceName: string) {
    this.gostData = [...roadSemaphores, ...signData]

    this.renderCallback(map, sourceName)
  }

  removeRenderEvent(map: Map) {
    if (!this.renderCb) return

    map.off('render', this.renderCb)
  }

  renderCallback(map: Map, sourceName: string) {
    if (map.style.sourceCaches[sourceName]) {
      this.updateMarkers(map, sourceName)
    }
  }

  updateMarkers(map: Map, sourceName: string) {
    const newMarkers: Markers = {}

    this.addFeature(map, sourceName, newMarkers)

    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) this.markersOnScreen[id].remove()
      else this.setCSSProperties(map, newMarkers[id])
    }

    this.markersOnScreen = newMarkers
  }

  hideMarkers() {
    for (const id in this.markersOnScreen) {
      this.markersOnScreen[id].remove()
      this.markersOnScreen[id]._element.remove()
      delete this.markersOnScreen[id]
    }
  }

  addFeature(map: Map, sourceName: string, newMarkers: Markers) {
    const features = map.querySourceFeatures(sourceName)

    for (const feature of features) {
      if (!feature.id) continue

      const id = +feature.id

      let marker: Marker = this.markers[id]

      if (!marker) marker = this.getMarker(feature)

      this.setCSSProperties(map, marker)

      newMarkers[id] = marker

      if (!this.markersOnScreen[id]) marker.addTo(map)
    }
  }

  setCSSProperties(map: Map, marker: Marker) {
    this.defineZoomLayer(map, marker._element as HTMLDivElement)
  }

  getMarker(feature: MapGeoJSONFeature) {
    const markerWrapper = this.createFeature(feature)

    const coords = (feature.geometry as Partial<{ coordinates: LngLatLike }>).coordinates

    this.markers[+feature.id!] = new maplibregl.Marker({
      element: markerWrapper
    })

    this.markers[+feature.id!].setLngLat(coords!)

    return this.markers[+feature.id!]
  }

  createFeature(feature: MapGeoJSONFeature) {
    const props = feature.properties

    const markerWrapper = document.createElement('div')
    markerWrapper.classList.add('road-sign-wrapper')

    const semaphoreWrapper = document.createElement('div')
    semaphoreWrapper.classList.add('semaphore-wrapper')
    markerWrapper.appendChild(semaphoreWrapper)

    const signWrapper = document.createElement('div')
    signWrapper.classList.add('sign-wrapper')
    markerWrapper.appendChild(signWrapper)

    if (props.cluster) {
      this.cluster(feature, markerWrapper)
    } else {
      this.single(feature, markerWrapper)
    }

    return markerWrapper
  }

  private defineZoomLayer(map: Map, markerWrapper: HTMLDivElement) {
    const zoom = map.getZoom()

    for (
      let i = 0;
      i < GEOMETRY_CONSTANTS.MAXIMUM_ZOOM - GEOMETRY_CONSTANTS.ROAD_SIGNS_SHOW_ZOOM;
      i++
    ) {
      if (zoom >= GEOMETRY_CONSTANTS.ROAD_SIGNS_SHOW_ZOOM + i) {
        markerWrapper.classList.add(`zoom-${i + 1}`)
      } else {
        markerWrapper.classList.remove(`zoom-${i + 1}`)
      }
    }
  }

  private single(feature: MapGeoJSONFeature, markerWrapper: HTMLDivElement) {
    const current = this.gostData!.find(
      (item) => item.gost === (feature.properties.gost || feature.properties.sign_gost)
    )

    if (current) {
      geometryCreateSign.createSign(current, markerWrapper, feature)
    }
  }

  cluster(feature: MapGeoJSONFeature, markerWrapper: HTMLElement) {
    const semaphoresGosts = feature.properties.gost.split(',')

    const signs = this.removeCoincedenceSign(feature)
    const semaphores = [...new Set(semaphoresGosts)] as string[]

    const semaphoresData = this.getSemaphoresProperties(semaphores)

    const gost: SignInCluster[] = [...semaphoresData, ...signs]

    gost.forEach((el) => {
      const current = this.gostData!.find((item) => item.gost === el.gost)

      if (current) {
        geometryCreateSign.createSign(current, markerWrapper, el)
      }
    })
  }

  removeCoincedenceSign(feature: MapGeoJSONFeature) {
    const gost: string[] = feature.properties.sign.split(',')
    const order: string[] = feature.properties.height.split(',')
    const text: string[] = feature.properties.sign_text.split(',')
    const azimuth: string[] = feature.properties.sign_azimuth.split(',')

    const signGost = gost.map((gost, index) => ({
      gost,
      index,
      order: +order[index],
      azimuth: +azimuth[index],
      text: text[index]
    }))

    return signGost.sort((a, b) => b.order - a.order)
  }

  getSemaphoresProperties(arr: string[]) {
    const pedestrians = arr.filter((el) => el === 'П.1' || el === 'П.2')
    const transports = arr.filter((el) => el !== 'П.1' && el !== 'П.2')

    return [...transports, ...pedestrians].map((el: string) => ({
      gost: el
    }))
  }

  defineShadow(map: Map) {
    const data = []

    for (const marker in this.markersOnScreen) {
      const mark = this.markersOnScreen[marker]

      const roadSigns = mark._element.querySelectorAll('[data-azimuth]')

      data.push(...roadSigns)
    }

    const mapAngle = azimuth(azimuth(map.getBearing()) - 180)

    if (mapAngle) {
      for (let i = 0; i < data.length; i++) {
        const el = data[i]

        const singAngle = el.getAttribute('data-azimuth')

        if (!singAngle) continue

        const start = azimuth(+singAngle - SING_VISION_RANGE)
        const end = azimuth(+singAngle + SING_VISION_RANGE)
        const noShadow = inAzimuthRange(start, end, mapAngle)

        if (noShadow) {
          el.classList.remove('shadow')
        } else {
          el.classList.add('shadow')
        }
      }
    }
  }

  defineOpacity(map: Map) {
    const zoom = map.getZoom()

    const data = []

    for (const marker in this.markersOnScreen) {
      const mark = this.markersOnScreen[marker]

      data.push(mark._element)
    }

    const percent =
      (GEOMETRY_CONSTANTS.MAXIMUM_ZOOM - GEOMETRY_CONSTANTS.ROAD_SIGNS_SHOW_ZOOM) / 100
    const current = (zoom - GEOMETRY_CONSTANTS.ROAD_SIGNS_SHOW_ZOOM) / percent / 100

    for (const marker of data) {
      marker.style.opacity = zoom > GEOMETRY_CONSTANTS.ROAD_OBJECTS_ZOOM ? current : '0'
    }
  }
}

export default MapRoadSignRender
