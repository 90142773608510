<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { useDefectsStore, useSegmentsStore } from '@/stores'
import { Breadcrumb } from '@/utils/breadcrumbs'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import AppTabs from '@/components/ui/AppTabs.vue'
import DiagnosticFilter from '@/components/layouts/road/diagnostic/defects/DiagnosticFilter.vue'
import DiagnosticDraw from './diagnostic/defects/DiagnosticDraw.vue'

import { TBreadcrumb } from '@/types/Breadcrumb'

const emits = defineEmits(['setBr'])

const defectsStore = useDefectsStore()
const segmentsStore = useSegmentsStore()
const defectFilterStore = useDefectFilterStore()

const segmentTabs = computed(() => segmentsStore.tabs)
const currentSegment = computed(() => segmentsStore.currentSegment)
const defects = computed(() => defectsStore.defects)
const initialFilter = computed(() => defectFilterStore.initialFilter)

onBeforeMount(async () => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Дефекты', null))
})

async function changeSegment(id: number) {
  segmentsStore.changeSegment(id)

  if (!currentSegment.value) return

  defectFilterStore.resetFilter()

  await defectsStore.fetchDefects(currentSegment.value, initialFilter.value)
  defectFilterStore.setInitialPayloadFilter(defects.value)
  defectFilterStore.calcFilterValues(defects.value)
  defectFilterStore.changeRoadLines()
}
</script>

<template>
  <div class="defects">
    <div>
      <div v-if="segmentTabs.length > 1 && currentSegment" class="defects__segments">
        <h2 class="defects__title">Сегменты</h2>
        <AppTabs :tabs="segmentTabs" :active-id="currentSegment" @change-tab="changeSegment" />
      </div>
      <div v-if="defects.length" class="defects__wrapper" :class="{ mt0: segmentTabs.length <= 1 }">
        <DiagnosticFilter></DiagnosticFilter>
      </div>
    </div>

    <div v-if="defects.length" class="defects__draw">
      <DiagnosticDraw />
    </div>
    <p
      v-else
      :class="{
        mt: !defects.length && segmentTabs.length >= 1
      }"
      class="defects__placeholder no-data-text"
    >
      Нет данных
    </p>
  </div>
</template>

<style lang="scss" scoped>
.defects {
  display: grid;

  &__title {
    font-family: $f-family-caption;
    font-size: 1.125em;
    font-weight: 700;
    @include color('fontColor');
  }

  &__wrapper {
    margin-top: 70px;
    @include color('fontColor');

    h2 {
      margin-bottom: 30px;
    }

    &:nth-child(2) {
      h2 {
        margin-top: 30px;
      }
    }

    &.mt0 {
      margin-top: 0;
    }
  }

  &__navigation {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 25px;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
}

.no-data-text.mt {
  margin-top: 45px;
}
</style>
