import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { Point } from 'chart.js'
import { DefectPopupState } from '@/types/Defects'
import { Defect } from '@/types/RoadDiagnostic'

export const useRoadDefectsPopupStore = defineStore('road-defects-popup', () => {
  const state: DefectPopupState = reactive({
    popupPosition: {
      x: undefined,
      y: undefined
    },
    isOpen: false,
    defectPopupData: []
  })

  const isOpen = computed(() => state.isOpen)
  const defectPopupData = computed(() => state.defectPopupData)
  const coordinates = computed(() => state.popupPosition)

  /**
   * Открыть/закрыть попап
   */
  function changePopupIsOpen(value: boolean) {
    state.isOpen = value
  }

  /**
   * Обновить данные попапа
   */
  function updateDefectPopupData(data: Defect[]) {
    state.defectPopupData = data
  }

  /**
   * Обновить положение попапа на странице
   */
  function updateCoordinates(coors: Partial<Point>) {
    state.popupPosition = coors
  }

  return {
    isOpen,
    defectPopupData,
    coordinates,

    changePopupIsOpen,
    updateDefectPopupData,
    updateCoordinates
  }
})
