export const setCompositeElevationRange = (elevationRange: [number, number]) => {
  return [elevationRange[0], elevationRange[1] - (elevationRange[1] - elevationRange[0]) * 0.2]
}

export const DEFAULT_GRADIENT = 'RAINBOW'
export const DEFAULT_POINT_TYPE = 'FIXED'

export const gradientSelectVariables = () => {
  const keys = Object.keys(window.Potree.Gradients)
  return keys.map((key, index) => {
    return {
      id: index,
      title: key,
      value: key
    }
  })
}
