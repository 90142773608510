import { MapFeature, toggleEffectOnFeature } from '@/utils/map'
import { Map, MapMouseEvent } from 'maplibre-gl'

import { MapFeatureRendered } from '@/types/Map'
import { ClickParams } from '@/types/MapClick'
import { ref } from 'vue'

function changeFeatureState(params: ClickParams, isActive: boolean) {
  const { activeFeature, map } = params

  if (!activeFeature.value) return

  toggleEffectOnFeature(map, activeFeature.value, {
    active: isActive
  })
}

/**
 * Функция клика по фиче
 */
function click(e: MapMouseEvent, params: ClickParams) {
  const { map, activeFeature, layers } = params

  if (activeFeature.value) changeFeatureState(params, false)

  const [feature] = map.queryRenderedFeatures(e.point)
  if (!feature) return

  const isActiveFeature = layers.includes(feature.layer.id)
  if (!isActiveFeature) return

  const active = new (MapFeature as unknown as MapFeatureRendered)(feature)

  if (!map.getFeatureState(active).active) {
    activeFeature.value = active
    changeFeatureState(params, true)
  }
}

export default function useMapClick(layers: string[]) {
  const activeFeature = ref()

  const clickOnFeature = (e: MapMouseEvent, map: Map) => {
    const params = {
      map,
      layers,
      activeFeature
    }

    click(e, params)
  }

  return {
    activeFeature,
    clickOnFeature
  }
}
