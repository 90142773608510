import { MapRenderParams } from '@/types/Map'
import { Map } from 'maplibre-gl'

function addOrtoLayer(map: Map, layer: MapRenderParams) {
  if (map.getLayer(layer.params.name)) return

  const { name, showed } = layer.params

  map.addLayer(
    {
      id: name,
      type: 'raster',
      source: name,
      layout: {
        visibility: showed ? 'visible' : 'none'
      }
    },
    'first-z-index'
  )
}

export default function () {
  return {
    addOrtoLayer: (map: Map, layer: MapRenderParams) => addOrtoLayer(map, layer)
  }
}
