class LocalStorageManager {
  get(key: string) {
    const item = window.localStorage.getItem(key)

    if (item) return JSON.parse(item)
  }

  set(key: string, value: unknown) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  remove(key: string) {
    window.localStorage.removeItem(key)
  }
}

export default LocalStorageManager
