import { useDefectsStore, useRoadDefectsMapStore } from '@/stores'
import { MapFeatureRendered } from '@/types/Map'
import { MapFeature, toggleEffectOnFeature } from '@/utils/map'
import { ROAD_DEFECTS_SOURCE_NAME } from '@/utils/consts'
import { Map, MapGeoJSONFeature, MapLibreEvent, MapMouseEvent } from 'maplibre-gl'
import { computed } from 'vue'

/**
 * Ищем фичу на карте
 */
function currentFeatureOnMap(map: Map) {
  const features = map.querySourceFeatures(ROAD_DEFECTS_SOURCE_NAME)
  const defectStore = useDefectsStore()

  const currentDefect = defectStore.currentDefect
  if (!currentDefect.length) return

  const current = features.find((el: MapGeoJSONFeature) => {
    const properties = propertiesToObject(el.properties)
    return properties.find((item) => item.mask_file_uuid === currentDefect.at(0)?.mask_file_uuid)
  })

  return current
}

/**
 * Переключение активного состояния точки на карте
 */
function toggleActiveMapFeature(params?: { current: MapGeoJSONFeature }) {
  const roadDefectsMapStore = useRoadDefectsMapStore()
  const map = roadDefectsMapStore.getMapInstance()

  const selectedFeature = computed(() => roadDefectsMapStore.selectedFeature)

  if (!map) return

  if (selectedFeature.value && map) {
    toggleEffectOnFeature(map, selectedFeature.value, {
      active: false
    })
  }

  if (!params) return

  const currentFeature = new (MapFeature as unknown as MapFeatureRendered)({
    source: ROAD_DEFECTS_SOURCE_NAME,
    id: params.current.id as string | number
  })

  roadDefectsMapStore.updateSelectedFeature(currentFeature)

  toggleEffectOnFeature(map, selectedFeature.value, {
    active: true
  })
}

/**
 * Посдветка фичи на карте (после полета)
 */
function highlight() {
  const roadDefectsMapStore = useRoadDefectsMapStore()
  const map = roadDefectsMapStore.getMapInstance()

  if (!map) throw new Error('Не установлена карта')

  const current = currentFeatureOnMap(map)
  if (!current) return

  toggleActiveMapFeature({
    current
  })
}

/**
 * Функция подсветки фичи на карте (при клике)
 */
function mapDefectClick(e: MapMouseEvent, map: Map) {
  const [first] = map.queryRenderedFeatures(e.point)

  if (!first?.properties) throw new Error('Недостаточно данных')

  toggleActiveMapFeature({
    current: first
  })
}

function highlightMapFeature(e?: MapLibreEvent) {
  const isFlyEvent = !e?.originalEvent
  if (isFlyEvent) highlight()
}

function propertiesToObject(properties: { [index: number]: string }) {
  return Object.values(properties).map((prop) => JSON.parse(prop))
}

export default function () {
  return {
    propertiesToObject,
    currentFeatureOnMap,
    toggleActiveMapFeature,

    mapDefectClick,
    highlightMapFeature
  }
}
