<script lang="ts" setup>
import { computed } from 'vue'

import useRoadDetailStore from '@/stores/road/road-detail'
import isEmpty from '@/utils/is-empty.ts'

import { RoadDetailNames } from '@/utils/lists'

import { RoadDetail } from '@/types/Road'

const roadDetailStore = useRoadDetailStore()

const PLACEHOLDER = 'Нет данных'

const info = computed(() => Object.entries(roadDetailStore.getDetail))
</script>

<template>
  <div class="road-more">
    <ul
      v-for="(item, index) in info"
      :key="`road-more-${index}`"
      class="road-more__group"
      :class="{
        'class-item':
          (item.at(0) === 'class' && (item.at(1)?.at(0) as RoadDetail)?.name) ===
          RoadDetailNames.CLASS
      }"
    >
      <li v-for="(elem, count) in item[1]" :key="`road-counter-${count}`">
        <p class="road-main__title">{{ elem.name }}</p>
        <p :class="{ empty: isEmpty(elem.value, true) }" class="road-main__value">
          {{ (isEmpty(elem.value, true) ? PLACEHOLDER : elem.value) ?? PLACEHOLDER }}
        </p>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.road-more {
  display: grid;
  grid-row-gap: 30px;
  align-self: start;
  padding: 1.875rem;
  transition: background-color 0.3s $main-animation;

  @include background-color('backgroundGeomteryLayer');

  &__group {
    display: grid;
    grid-gap: 1.875rem 2.75rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);

    &.class-item {
      grid-row-gap: 0;
      grid-template-columns: 1fr;
    }
  }
}
</style>
