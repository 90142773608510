<script lang="ts" setup="">
import { computed, onMounted, ref } from 'vue'

import { useRoadTableGenerationStore } from '@/stores/road/road-tableGeneration'
import useAdminStore from '@/stores/admin'

import AppAccordion from '@/components/ui/AppAccordion.vue'
import ControlColumn from '../../table/ControlTableGeneration/ControlColumn.vue'
import { DictionaryItem } from '@/types/AdminDictionary'

const objectTypeStore = useRoadTableGenerationStore()
const adminStore = useAdminStore()

const objectType = computed(() => objectTypeStore.roadObjectTypes)
const dictionaries = computed(() => adminStore.dictionaries)

const accordion = ref([])

onMounted(() => {
  objectTypeStore.resObjectTypes()
})

async function openAccordion(id: number) {
  await adminStore.openDictionary(id)
}

function getCurrentDictionary(id: number) {
  const currentDictionary = dictionaries.value.find(
    (dictionary: DictionaryItem) => dictionary.id === id
  )

  if (currentDictionary) return currentDictionary.data
}
</script>

<template>
  <div class="references">
    <h3 class="references__title">Справочники/редактирование данных таблицы</h3>
    <div v-for="(item, index) in objectType" :key="index + '-ref-btn'">
      <AppAccordion
        ref="accordion"
        class="global-accordion"
        :title="item.name"
        :async-cb="() => openAccordion(item.id)"
      >
        <ControlColumn
          :column-data="getCurrentDictionary(item.id)"
          :type-id="item.id"
        ></ControlColumn>
      </AppAccordion>
    </div>
  </div>
</template>

<style lang="scss">
.references {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 20px;
    @include color('fontColor');
  }
}
</style>
