import { IToasts } from '@/types/Toast'

const LOCAL_STORAGE_KEY_LESSON = import.meta.env.VITE_LOCAL_STORAGE_KEY_MAP_TOOL_LESSON

class MapToolLesson {
  passed = false
  step = 0
  toast = {} as IToasts
  text: string[] = []
  started = false

  constructor(toast: IToasts, text: string[], passed: boolean) {
    this.passed = passed

    this.toast = toast
    this.text = text
  }

  get title() {
    return `${this.step + 1} шаг из ${this.text.length}`
  }

  get isEnd() {
    return this.step >= this.text.length
  }

  public start(e: Event) {
    const btn = e.target as HTMLElement

    const needLesson =
      btn.classList.contains('mapbox-gl-draw_line') ||
      btn.classList.contains('mapbox-gl-draw_polygon')

    if (this.passed || !needLesson) return

    if (this.started) {
      this.stop()

      return
    }

    this.started = true

    if (this.toast) {
      this.toast.info(this.title, this.text[this.step], -1)

      this.step++
    }
  }

  public next() {
    if (!this.started || this.passed || this.step === 3) return

    if (this.isEnd) {
      this.stop()

      return
    }

    this.removeHint()

    this.toast.info(this.title, this.text[this.step], -1)

    this.step++
  }

  public stop() {
    this.passed = true
    this.started = false

    localStorage.setItem(LOCAL_STORAGE_KEY_LESSON, JSON.stringify(true))
  }

  private removeHint() {
    const hints = document.querySelectorAll('.toast')

    hints.forEach((el) => el?.remove())
  }

  public end(e: KeyboardEvent) {
    if (e.key !== 'Enter' || this.step !== 3 || !this.started || this.passed) return

    if (this.isEnd) {
      this.stop()

      return
    }

    this.toast.info(this.title, this.text[this.step], -1)

    this.step++
  }
}

export default MapToolLesson
