<template>
  <div class="windowBorder">
    <div class="windowBorder__container">
      <div class="windowBorder__top"></div>
      <div class="windowBorder__inner">
        <slot />
      </div>
      <div class="windowBorder__bottom"></div>
    </div>
  </div>
</template>

<style lang="scss">
.windowBorder {
  $padding-border: 3em;

  position: relative;
  width: 40rem;
  padding: 70px 80px 70px 55px;
  background: rgba($c-gray-10, 0.88);

  &__top,
  &__bottom {
    position: absolute;
    right: $padding-border;
    left: $padding-border;
    height: 1px;
    background-color: $c-orange-light;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
    }

    &::before {
      left: calc(-1.5px - $padding-border);
      border-left: 3px solid $c-orange-light;
    }

    &::after {
      right: calc(-1.5px - $padding-border);
      border-right: 3px solid $c-orange-light;
    }
  }

  &__top {
    top: 0;

    &::before,
    &::after {
      top: -1.5px;
      border-top: 3px solid $c-orange-light;
    }
  }

  &__bottom {
    bottom: 0;

    &::before,
    &::after {
      bottom: -1.5px;
      border-bottom: 3px solid $c-orange-light;
    }
  }
}
</style>
