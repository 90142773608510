<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import RenderDiagramValues from '@/composition/diagram/renderDiagramValues'

import { usePreloaderStore } from '@/stores'

import BackgroundContextMenu from '../layouts/background/BackgroundContextMenu.vue'
import AppPreloaderInteractive from './AppPreloaderInteractive.vue'

const renderDiagramValues = new RenderDiagramValues()
const preloaderStore = usePreloaderStore()

const MEASURE_COUNTER = 24
const MEASURE_WIDTH = 11
const MEASURE_DIAMETR = 140
const INITIAL_ANGLE = 90

const progress = computed(() => preloaderStore.progress)

const diagramValue = ref()
const preloaderMeasure = ref()

const diametr = 210
const angle = 360 / MEASURE_COUNTER
const inscribeMeasureDiametr = MEASURE_DIAMETR - MEASURE_WIDTH
const measureRadius = inscribeMeasureDiametr / 2

const diagramL = Math.PI * diametr

onBeforeMount(() => {
  window.addEventListener('loading', (e: CustomEventInit) => {
    preloaderStore.setProgress(e.detail.progress)
  })
})

onMounted(() => {
  setProgressBarStyle()
  setStyleToMeasureItem()
})

function setProgressBarStyle() {
  const diagramDashArray = renderDiagramValues.getDashArray(diagramL, progress.value)
  const offset = renderDiagramValues.getDashArray(diagramL, 100 - progress.value)

  if (!diagramValue.value) return

  diagramValue.value.style.strokeDasharray = `${diagramDashArray} ${diagramL}`
  diagramValue.value.style.strokeDashoffset = `${-offset}`
}

function setStyleToMeasureItem() {
  if (!preloaderMeasure.value) return

  preloaderMeasure.value.forEach((measure: HTMLElement, index: number) => {
    const deg = INITIAL_ANGLE + angle * (index + 1)
    const rad = (deg * Math.PI) / 180

    const left = measureRadius + measureRadius * Math.sin(rad)
    const top = measureRadius + measureRadius * Math.cos(rad)

    measure.style.transform = `rotate(-${INITIAL_ANGLE + deg}deg)`
    measure.style.left = `${left}px`
    measure.style.top = `${top}px`
  })
}

function setMeasureStyle() {
  const onePercent = 24 / 100

  const index = Math.trunc(onePercent * progress.value)

  if (!preloaderMeasure.value) return

  preloaderMeasure.value.forEach(
    (measure: HTMLElement) => (measure.style.backgroundColor = '#686868')
  )

  const measures = preloaderMeasure.value.filter(
    (...rest: Array<HTMLElement | number | HTMLElement[]>) => (rest.at(1) as number) <= index - 1
  )

  measures.forEach((item: HTMLElement) => {
    item.style.backgroundColor = '#D68166'
  })
}

watch(
  progress,
  () => {
    setProgressBarStyle()
    setMeasureStyle()
  },
  {
    deep: true
  }
)
</script>

<template>
  <div class="preloader">
    <BackgroundContextMenu :opacity="false" />
    <div class="preloader__item">
      <AppPreloaderInteractive />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.preloader {
  display: grid;
  position: fixed;
  z-index: 100;
  inset: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;

  &__item {
    display: grid;
    position: relative;
    z-index: 101;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    // border: 2px solid $c-gray-40;
    border-radius: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    transform: translate(-50%, -50%);
    border-radius: 100%;

    circle {
      transition: all 0.05s linear;
    }
  }

  &__measure {
    position: relative;
    width: 140px;
    height: 140px;
  }

  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $f-family-caption;
    font-size: 24px;

    @include color('fontColor');
  }
}

.preloader-scale {
  &__value {
    position: absolute;
    z-index: 102;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    transform: translate(-50%, -50%);
    border: 8px solid $c-orange;
    border-radius: 100%;
  }
}

.preloader-measure {
  &__item {
    display: grid;
    position: absolute;
    width: 11px;
    height: 5px;
    transform-origin: bottom center;
    transition: all 0.1s linear;
    border-radius: 5px;
    background-color: #686868;
  }
}
</style>
