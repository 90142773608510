export default [
  {
    path: '/login',
    name: 'Auth',
    component: () => import('@/pages/AuthPage.vue'),
    meta: {
      isAuthPage: true
    }
  },
  {
    path: '/',
    name: 'Main',
    component: () => import('@/pages/MainPage.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('@/pages/MapPage.vue')
  },
  {
    path: '/road/:id',
    name: 'Road',
    component: () => import('@/pages/RoadPage.vue')
  },
  {
    path: '/outer',
    name: 'Outer',
    component: () => import('@/pages/OuterPage.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/pages/ProfilePage.vue')
  },
  {
    path: '/geometry',
    name: 'Geometry',
    component: () => import('@/pages/GeometryPage.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/pages/AdminPage.vue')
  },
  {
    path: '/model',
    name: 'Model',
    component: () => import('@/pages/ModelPage.vue')
  }
]
