import { computed, reactive } from 'vue'
import { ECharts } from 'echarts'
import { defineStore } from 'pinia'

import type { SelectedChartItem, ChartStoreState } from '@/types/Chart'

export const useChartStore = defineStore('chart-store', () => {
  let chart: ECharts | undefined = undefined

  const state: ChartStoreState = reactive({
    selected: []
  })

  const selectedPoints = computed(() => state.selected)

  function setHighlightedPoints(points: SelectedChartItem[]) {
    state.selected = points
  }

  function setInstance(instance: ECharts) {
    chart = instance
  }

  function getChartInstance() {
    return chart
  }

  return {
    selectedPoints,
    chart,

    getChartInstance,

    setHighlightedPoints,
    setInstance
  }
})
