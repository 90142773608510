<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, onUnmounted, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import { useThemeStore } from '@/stores'
import { useChartStore } from '@/stores/chart'

import useChartOptions from '@/composition/useChartOptions'
import useChartHighlight from '@/composition/defects/useChartHighlight'

import type { ChartProps } from '@/types/Chart'
import type { ECElementEvent } from 'echarts'

const { highlightPoint, downplayPoint } = useChartHighlight()

const props = withDefaults(defineProps<ChartProps>(), {
  title: 'График',
  id: 'chart',
  type: 'bar',
  label: 'yes',
  width: 400,
  height: 300,
  padding: 40,
  legends: false
})

const emits = defineEmits(['mounted', 'chart-clicked', 'chart-zoomed', 'chart-data-updated'])

const themeStore = useThemeStore()
const chartStore = useChartStore()

const debounce = useDebounceFn(() => emits('chart-zoomed'), 400)

const chartData = computed(() => props.data)
const colors = computed(() => props.colors)
const theme = computed(() => themeStore.theme)
const legendItems = computed(() => props.legendItems)
const selected = computed(() => props.selected)
const selectedPoints = computed(() => chartStore.selectedPoints)

onBeforeUnmount(() => {
  removeChartListeners()
})

onMounted(async () => {
  const options = getChartOptions()
  const chart = useChartOptions(options)

  const chartInstance = chart.draw()
  if (!chartInstance) return

  chartStore.setInstance(chartInstance)

  emits('mounted', { instance: chartInstance, wrapper: chart })

  window.addEventListener('resize', resizeChart)

  resizeChart()
  addChartListeners()
})

function deb() {
  debounce()
}

function addChartListeners() {
  const chart = chartStore.getChartInstance()
  if (!chart) return

  chart.on('dataZoom', deb)
  chart.on('click', click)
}

function removeChartListeners() {
  const chart = chartStore.getChartInstance()
  if (!chart) return

  chart.off('dataZoom', deb)
  chart.off('click', click)
}

function click(e: Partial<ECElementEvent>) {
  emits('chart-clicked', e)
}

/**
 * Обновляем данные в графике
 */
function updateChart() {
  const chart = chartStore.getChartInstance()
  if (!chart) return

  removeChartListeners()
  chart?.dispose()

  const options = getChartOptions()
  const chartOptions = useChartOptions(options)
  if (!chartOptions) return

  const instance = chartOptions.draw()
  if (!instance) return

  chartStore.setInstance(instance)
  addChartListeners()

  emits('chart-data-updated')
}

watch([chartData, colors, theme], updateChart, {
  deep: true
})

function getChartOptions() {
  return {
    id: props.id,
    type: props.type,
    label: props.label,
    labels: props.labels,
    width: props.width,
    height: props.height,
    title: props.title,
    data: props.data,
    padding: props.padding,
    colors: props.colors,
    sign: props.sign,
    metric: props.metric,
    left: props.left,
    tooltip: props.tooltip,
    series: props.series,
    dataZoom: props.dataZoom,
    calculable: true,
    graphic: props.graphic,
    highlightColor: props.highlightColor
  }
}

function resizeChart() {
  const chart = chartStore.getChartInstance()
  if (!chart) return

  chart.resize()
}

onUnmounted(() => {
  const chart = chartStore.getChartInstance()
  if (!chart) return

  chart?.dispose()

  window.removeEventListener('resize', resizeChart)
})

watch(
  selected,
  (val) => {
    const chart = chartStore.getChartInstance()
    if (!val || !chart) return

    if (selectedPoints.value.length) downplayPoint(chart, selectedPoints.value)

    highlightPoint(chart, val)
    chartStore.setHighlightedPoints(val)
  },
  {
    deep: true
  }
)
</script>

<template>
  <div class="chart">
    <div v-if="legendItems" class="chart-legend">
      <div v-for="(item, index) in legendItems" :key="index + '-defect-name'" class="defect-name">
        <span
          class="defect-name__icon"
          :style="{
            backgroundColor: item.color || 'white'
          }"
        ></span>
        {{ item.name }}
      </div>
    </div>
    <div class="chart__inner" :class="$props.id"></div>
  </div>
</template>

<style scoped lang="scss">
.chart {
  position: relative;

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__inner {
    width: 100%;
    height: 100%;
  }
}

.chart-name {
  &__y {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__x {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.chart-legend {
  display: grid;
  position: absolute;
  left: 50%;
  grid-auto-flow: column;
  grid-gap: 15px 20px;
  align-items: center;
  align-self: center;
  transform: translateX(-50%);
  font-size: 14px;
}

.defect-name {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 7px;
  align-items: center;
  width: max-content;

  @include color('fontColor');

  &__icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }
}

@media (width <= 1842px) {
  .chart {
    &__inner {
      width: 100%;
    }
  }
}

@media (width <= 1650px) {
  .chart-legend {
    position: relative;
    grid-auto-flow: dense;
    grid-gap: 15px 45px;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    font-size: 14px;
  }
}
</style>
