import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { useThemeStore } from './theme'
import { APP_THEMES, MapLayers } from '@/utils/lists'

import { LayerSpecification, SourceSpecification } from 'maplibre-gl'

import ShemeIcon from '@/assets/images/scheme.png'
import SatelliteIcon from '@/assets/images/satellite.png'
import HybridIcon from '@/assets/images/hybrid.png'

const LOCAL_STORAGE_KEY_LESSON = import.meta.env.VITE_LOCAL_STORAGE_KEY_MAP_TOOL_LESSON

interface IMapStoreStyle {
  tiles: { [key: string]: SourceSpecification }
  layers: LayerSpecification[]
  current: string | Partial<IMapStoreStyle>
}

interface IMapStore {
  style: IMapStoreStyle
  nav: {
    items: Array<{
      name: string
      type: string
      icon: string
    }>
  }
  measure: {
    passed: boolean
    text: string[]
  }
}

const { VITE_MAPBOX_STYLE_SCHEME_DARK, VITE_MAPBOX_STYLE_SCHEME_LIGHT } = import.meta.env

export const useMapStore = defineStore('mapStore', () => {
  const themeStore = useThemeStore()
  const theme = computed(() => themeStore.theme)

  const state: IMapStore = reactive({
    style: {
      tiles: {
        'hybrid-custom': {
          type: 'raster',
          attribution: '',
          tiles: ['https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'],
          tileSize: 256
        },
        'satellite-custom': {
          type: 'raster',
          attribution: '',
          tiles: ['https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'],
          tileSize: 256
        }
      },
      layers: [
        {
          id: MapLayers.SATELLITE,
          type: 'raster',
          source: MapLayers.SATELLITE,
          layout: {
            visibility: 'none'
          },
          name: 'Спутник'
        },
        {
          id: MapLayers.HYBRID,
          type: 'raster',
          source: MapLayers.HYBRID,
          layout: {
            visibility: 'none'
          },
          name: 'Гибрид'
        }
      ],
      current:
        theme.value === APP_THEMES.DARK
          ? VITE_MAPBOX_STYLE_SCHEME_DARK
          : VITE_MAPBOX_STYLE_SCHEME_LIGHT
    },
    nav: {
      items: [
        {
          name: 'Схема',
          icon: ShemeIcon,
          type: MapLayers.SCHEME
        },
        {
          name: 'Спутник',
          icon: SatelliteIcon,
          type: MapLayers.SATELLITE
        },
        {
          name: 'Гибрид',
          icon: HybridIcon,
          type: MapLayers.HYBRID
        }
      ]
    },
    measure: {
      passed: !!JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LESSON) || 'false'),
      text: [
        'Начните измерение, нажав на карте в нужном месте',
        'Продолжите, выбрав на карте нужные точки',
        'По окончанию нажмите Enter',
        'Можно отредактировать получившийся объект, нажав на вершину контура.',
        'Чтобы удалить контур, нужно щелкнуть вне конутра (он должен стать синим), а затем снова выделить его и нажать кнопку удаления'
      ]
    }
  })

  const getStyle = computed(() => state.style)
  const getNav = computed(() => state.nav)
  const measureLessonPassed = computed(() => state.measure.passed)
  const measureLessonText = computed(() => state.measure.text)
  const currentStyle = computed(() => state.style.current)
  const currentActiveLayer = computed(() => {
    let result

    if (typeof currentStyle.value === 'string') {
      result = MapLayers.SCHEME
    } else if (typeof currentStyle.value === 'object' && currentStyle.value !== null) {
      result = currentStyle.value.layers?.find((layer) => layer.layout?.visibility === 'visible')
        ?.id
    }

    return result
  })

  function setCurrentStyle(type: string | IMapStoreStyle) {
    if (typeof type === 'string') {
      state.style.current =
        theme.value === APP_THEMES.DARK
          ? VITE_MAPBOX_STYLE_SCHEME_DARK
          : VITE_MAPBOX_STYLE_SCHEME_LIGHT

      return
    }

    state.style.current = type
  }

  return {
    getStyle,
    getNav,
    measureLessonPassed,
    measureLessonText,
    currentStyle,
    currentActiveLayer,

    setCurrentStyle
  }
})
