<script setup lang="ts">
import { ProcessedRoad, Road } from './importPage'

const props = defineProps<{
  roads: Road[]
  staged: Road[]
  processing: ProcessedRoad[]
}>()

defineEmits(['toggleRoadStaged'])

function actionText(road: Road) {
  const proad = props.processing.find((pr) => pr.id == road.id)

  if (proad) {
    const status = proad.queue_data.status;
    return status == 'COMPLETED' ? 'Завершено'
            : status == 'IN_PROGRESS' ? 'Обрабатывается'
            : status == 'PENDING' ? 'Ожидает очереди'
            : status
  } else {
    return road.id ? 'Обновление' : 'Импорт'
  }
}

function isSelected(id: number): boolean {
  return !!props.staged.filter((r) => {
    return r.id_indoroad == id
  }).length
}
</script>

<template>
  <table class="roads">
    <thead>
      <tr>
        <th class="roads__hid">№</th>
        <th class="roads__hname">Наименование</th>
        <th class="roads__hident">Идентификационный номер</th>
        <th class="roads__haccount">Учётный номер</th>
        <th class="roads__hsroad">Действие</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="road in roads" :key="road.id_indoroad">
        <tr
          class="selectable-row"
          :class="{ selected: isSelected(road.id_indoroad) }"
          @click="$emit('toggleRoadStaged', road.id_indoroad)"
        >
          <td>{{ road.id_indoroad }}</td>
          <td>
            <a v-if="road.id" v-bind:href="`/road/${road.id}`" target="_blank" @click.stop>{{ road.name }}</a>
            <p v-else>{{ road.name }}</p>
          </td>
          <td>{{ road.identification_number }}</td>
          <td>{{ road.account_number }}</td>
          <td>{{ actionText(road) }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style lang="scss">
.selectable-row {
  cursor: pointer;
}

.selected {
  background-color: rgba(255, 255, 255, 0.07);

  > td {
    border: 1px solid $c-orange !important;
  }
}

.roads {
  @include background-color('widgetBG');
  font-family: $f-family-base;
  font-weight: 600;
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;

  thead {
    height: 60px;
    color: $c-orange;
    font-size: 1.12rem;
  }

  td,
  th {
    border: 1px solid #686868;
    padding: 15px 30px;
    text-align: left;
  }

  tr {
    transition: 0.2s;
  }
  tr:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.07);
  }

  td:first-child,
  td:last-child {
    text-align: center;
  }

  &__hid {
    width: 5%;
    text-align: center !important;
  }
  &__hname {
    width: 50%;
    text-align: left;
  }
  &__hident {
    width: 15%;
  }
  &__haccount {
    width: 15%;
  }
  &__hsroad {
    width: 15%;
    text-align: center !important;
  }
}
</style>
