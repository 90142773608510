<script setup lang="ts">
import { ComputedRef, computed, onBeforeMount, ref } from 'vue'

import { useRoadDiagnosticStore } from '@/stores'
import { Breadcrumb } from '@/utils/breadcrumbs'
import iriPopup from '@/utils/templates/iri-popup'

import AppChart from '@/components/ui/AppChart.vue'
import AppTabs from '@/components/ui/AppTabs.vue'

import { TBreadcrumb } from '@/types/Breadcrumb'
import { RoadIRITable } from '@/types/Road'
import { TooltipParams } from '@/types/RoadDiagnostic'
import { LIMIT_IRI_VALUE } from '@/utils/consts'

const PLACEHOLDER = '—'

const emits = defineEmits(['setBr'])

const roadDiagnosticStore = useRoadDiagnosticStore()

const chartData = computed(() => roadDiagnosticStore.getDiagnosticChartData)
const currentSegmentTab = computed(() => roadDiagnosticStore.getDiagnosticTab)
const tableData: ComputedRef<RoadIRITable[]> = computed(
  () => roadDiagnosticStore.getDiagnosticTableData
)
const iriCellStyle = computed(() => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${tableData.value?.at(0)?.data?.length}, 1fr)`,
  height: '100%',
  alignItems: 'center'
}))
const correspondence = computed(() => roadDiagnosticStore.getCorrespondenceData)
const segmentsTab = computed(() => roadDiagnosticStore.getSegmentTabs)

const legendItems = computed(() => {
  return chartData.value.lines.map((line, index) => ({
    name: line.at(0)?.line === LIMIT_IRI_VALUE ? LIMIT_IRI_VALUE : `Полоса ${index + 1}`,
    id: index + 1,
    color: chartData.value.colors?.[index]
  }))
})

const showChart = ref(true)

onBeforeMount(() => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('IRI', null))
})

function changeTab(index: number) {
  showChart.value = false

  roadDiagnosticStore.changeSegment(index, 'iri')

  setTimeout(() => (showChart.value = true), 100)
}

function iriTooltip(params: TooltipParams): string | undefined {
  return iriPopup(params)
}
</script>

<template>
  <div class="diagnostic">
    <div v-if="tableData?.[0]?.data">
      <h2 class="diagnostic__title">Диагностика</h2>

      <div v-if="segmentsTab.length > 1" class="diagnostic__tabs">
        <h2 class="diagnostic__title">Сегменты</h2>
        <AppTabs
          :tabs="segmentsTab"
          :active-id="currentSegmentTab"
          @change-tab="changeTab"
        ></AppTabs>
      </div>

      <h3 class="diagnostic__subtitle">
        Графические результаты измерений продольной ровности (IRI)
      </h3>

      <Transition name="fade">
        <AppChart
          id="chart__wrapper"
          :legends="true"
          :legend-items="legendItems"
          :title="'Графические результаты измерений продольной ровности (IRI)'"
          :data="chartData"
          :colors="chartData.colors"
          :type="'line'"
          class="diagnostic__chart"
          :width="1475"
          :height="800"
          :padding="55"
          :tooltip="{
            triggerOn: 'mousemove',
            trigger: 'item',
            formatter: iriTooltip,
            enterable: true,
            padding: 0
          }"
          :metric="{ x: 'км', y: 'IRI' }"
          :left="55"
          :data-zoom="{
            filterMode: 'none'
          }"
        />
      </Transition>
      <h2 class="diagnostic__title">Показатели продольной ровности</h2>

      <div class="diagnostic__tables">
        <div class="diagnostic__table">
          <div class="diagnostic-table__row diagnostic-table__row--header">
            <div class="diagnostic-table__column diagnostic-table__column--head">
              Расстояние, км
            </div>
            <div class="diagnostic-table__column diagnostic-table__column--head iri">
              <p>IRI</p>
              <div class="diagnostic-table__iri-wrapper" :style="iriCellStyle">
                <div
                  v-for="(item, index) in tableData?.[0]?.data?.map((el) => el.line)"
                  :key="(item || index) + '-iri-value'"
                  class="diagnostic-table__iri-value"
                >
                  {{ index + 1 || PLACEHOLDER }}
                </div>
              </div>
            </div>
            <div class="diagnostic-table__column diagnostic-table__column--head">Норматив</div>
          </div>

          <div
            v-for="(item, index) in tableData"
            :key="'row-' + index"
            class="diagnostic-table__row"
          >
            <div class="diagnostic-table__column">
              {{ typeof item.position === 'number' ? item.position : PLACEHOLDER }}
            </div>
            <div :style="iriCellStyle">
              <div
                v-for="(elem, counter) in item.data"
                :key="'iri-' + counter"
                class="diagnostic-table__iri-value"
              >
                {{ elem.value || PLACEHOLDER }}
              </div>
            </div>
            <div class="diagnostic-table__column">{{ item.standart || PLACEHOLDER }}</div>
          </div>
        </div>

        <div class="diagnostic__table">
          <div
            class="diagnostic-table__row diagnostic-table__row--distance diagnostic-table__row--header"
          >
            <div class="diagnostic-table__column diagnostic-table__column--head">Наименование</div>
            <div class="diagnostic-table__column diagnostic-table__column--head">Расстояние, м</div>
            <div class="diagnostic-table__column diagnostic-table__column--head">%</div>
          </div>
          <div
            v-for="(item, index) in correspondence"
            :key="index + 'correspondence'"
            class="diagnostic-table__row diagnostic-table__row--distance"
          >
            <div class="diagnostic-table__column">{{ item.name }}</div>
            <div class="diagnostic-table__column">{{ item.distance }}</div>
            <div class="diagnostic-table__column">
              {{ item.percent === 'Нет данных' ? item.percent : `${item.percent}%` }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="no-data-text">Нет данных</p>
  </div>
</template>

<style lang="scss">
.diagnostic {
  .chart {
    left: 0;
  }

  &__chart {
    .chart {
      left: -35px;

      &__legend {
        font-size: 0.9em;
      }
    }
  }
}

#segment-map {
  height: 230px;
  margin-top: 60px;

  .maplibregl-canvas-container {
    height: 230px;
  }
}

.iri-tooltip {
  padding: 10px 15px;

  @include color('fontColor');
  @include background('headerNavIconBG');

  &__value {
    margin-top: 5px;
    margin-left: 20px;
  }
}

@media (width <= 1810px) {
  .diagnostic {
    &__chart {
      .chart__head {
        grid-gap: 2rem 0;
        grid-template-rows: repeat(2, max-content);
      }

      #chart {
        margin-top: 1.5rem;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.diagnostic {
  &__title {
    font-family: $f-family-caption;
    font-size: 1.125em;
    font-weight: 700;
    @include color('fontColor');

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  &__subtitle {
    margin-top: 60px;
    color: $c-white;
    @include color('fontColor');
  }

  &__chart {
    margin-top: 3.75rem;
  }

  &__tables {
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: 760px 508px;
    align-items: start;
    margin-top: 40px;
    margin-bottom: 80px;
  }

  &__table {
    border: 1px solid $c-gray-40;
    @include background-color('widgetBG');
  }

  &__tabs {
    margin-top: 60px;
  }
}

.diagnostic-table {
  &__row {
    display: grid;
    grid-template-columns: 180px 1fr 200px;
    color: $c-white;

    &--header {
      color: $c-orange;
    }

    &:not(:first-child) {
      border-top: 1px solid $c-gray-40;
    }

    &--distance {
      grid-template-columns: 180px 1fr 150px;
    }
  }

  &__iri-value {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;

    @include color('fontColor');

    &:not(:last-child) {
      border-right: 1px solid $c-gray-40;
    }
  }

  &__column {
    display: grid;
    align-items: center;
    justify-content: start;
    padding: 15px 20px;
    font-family: $f-family-base;
    font-size: 1em;
    font-weight: 700;

    &:first-child {
      border-right: 1px solid $c-gray-40;
    }

    &:last-child {
      border-left: 1px solid $c-gray-40;
    }

    &:not(.diagnostic-table__column--head) {
      @include color('fontColor');
    }

    &__iri-value {
      display: grid;
      justify-content: center;
    }

    &--head {
      padding: 20px;
      color: $c-orange;

      &.iri {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        align-self: center;
        height: 100%;
        padding: 0;

        p {
          display: grid;
          justify-content: center;
        }

        &:nth-child(2) {
          border-right: 0;
        }

        .diagnostic-table__iri-value {
          &:not(:last-child) {
            border-right: 1px solid $c-gray-40;
          }
        }
      }
    }

    &.iri-table {
      padding: 0;

      &:not(.diagnostic-table__column--head) {
        display: flex;
      }
    }
  }

  &__iri {
    width: 100%;
    height: 100%;
  }

  &__iri-wrapper {
    border-top: 1px solid $c-gray-40;
  }
}

@media (width <= 1890px) {
  .diagnostic {
    width: 75vw;
  }
}

@media (width <= 1840px) {
  .diagnostic {
    width: 65vw;

    &__tables {
      grid-gap: 1.25rem 0;
      grid-template-columns: minmax(max-content, 65vw);
    }

    &__table {
      width: max-content;
    }
  }

  .diagnostic-table {
    &__row {
      grid-template-columns: 180px 1fr 200px;

      &--distance {
        grid-template-columns: repeat(4, 150px);
      }
    }

    &__iri-value {
      width: max-content;
      min-width: 100px;
    }
  }
}

@media (width <= 1250px) {
  .diagnostic-table {
    &__row {
      font-size: 0.8em;
    }

    &__iri-value {
      min-width: 80px;
    }
  }
}
</style>
