import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { useDefectsStore } from './road-defects'
import useDefectsFilter from '@/composition/defects/useDefectsFilter'
import useDefects from '@/composition/defects/useDefects'

import type { SelectOption } from '@/types/Select'
import type { DefectFilterPayload, DefectFilterPayloadData } from '@/types/Defects'
import type {
  DefectFilter,
  DefectType,
  DefectTypeMaskStyle,
  FilterRoadLine,
  OptionsMask,
  RoadNeiroDefect
} from '@/types/RoadDiagnostic'

import { colorsFilter } from '@/assets/data/defect-filter'

const { definePayload, createLinesOptions } = useDefectsFilter()
const { getDefectNames } = useDefects()

export const useDefectFilterStore = defineStore('road-defects-filter', () => {
  const defectStore = useDefectsStore()

  const defects = computed(() => defectStore.defects)

  const state = reactive({
    filter: {
      initialFilter: {},
      lines: [
        {
          id: 0,
          value: '',
          title: 'Все полосы',
          active: true
        }
      ] as FilterRoadLine[],
      payload: {} as DefectFilter,
      defectTypes: [] as DefectTypeMaskStyle[],
      style: [] as OptionsMask[],
      sizes: [
        {
          name: 'Площадь',
          unit: 'см²',
          keys: ['min_square', 'max_square']
        },
        {
          name: 'Длина',
          unit: 'см',
          keys: ['min_length', 'max_length']
        },
        {
          name: 'Ширина',
          unit: 'см',
          keys: ['min_width', 'max_width']
        }
      ]
    }
  })

  const initialFilter = computed(() => state.filter.initialFilter)
  const filter = computed(() => state.filter.payload)
  const roadLines = computed(() => state.filter.lines)
  const activeRoadLine = computed(() => roadLines.value.find((line) => line.active))
  const activeRoadLineId = computed(() => activeRoadLine.value?.value)
  const defectTypes = computed(() => state.filter.defectTypes)
  const style = computed(() => state.filter.style)
  const sizes = computed(() => state.filter.sizes)
  const visibleDefects = computed(() => defectTypes.value)
  const defectsColor = computed(() =>
    style.value.map((style) => ({ id: style.id, value: style.color }))
  )
  const defectNames = computed(() => defectTypes.value.map((defect) => defect.name))

  function setInitialPayloadFilter(defects: RoadNeiroDefect[], updateColors = true) {
    const obj: { [key: string]: object } = {}

    const names = getDefectNames(defects)

    names.forEach((item) => {
      obj[item.eng] = {}
    })

    state.filter.initialFilter = structuredClone(obj)
    state.filter.payload = obj

    state.filter.defectTypes = names.map((el, index) => ({
      name: el.eng,
      label: el.rus,
      show: true,
      id: index + 1
    }))

    if (updateColors) {
      state.filter.style = names.map((el, index) => ({
        id: index + 1,
        color: colorsFilter[index]?.value || 'black',
        opacity: 50,
        filter: colorsFilter[index]?.filter || [],
        name: el.eng
      }))
    }
  }

  /**
   * Cброс фильтра
   * */
  function resetFilter() {
    const initialRoadLine = roadLines.value.find((line) => line.id === 0)

    if (!initialRoadLine) throw new Error('Недостаточно данных')

    changeActiveRoadLine(initialRoadLine.value)

    defectTypes.value.forEach((defect) => {
      changeDefectVisibility(defect.id, true)
      changeFilterPayload(defect.name, {})
    })

    style.value.forEach((style) => (style.opacity = 50))
  }

  /**
   * Смена полосы в фильтре
   */
  function changeActiveRoadLine(value: unknown) {
    state.filter.lines = roadLines.value.map((line) => {
      line.active = false
      if (line.value === value) line.active = true

      return line
    })
  }

  /**
   * Смена видимости дефекта в фильтре
   * */
  function changeDefectVisibility(id: number, visibility?: boolean) {
    const current = defectTypes.value.find((defect) => defect.id === id)

    if (!current) throw new Error('Нет дефекта с таким id')

    current.show = visibility ?? !current.show
  }

  /**
   * Изменение payload фильтра
   * */
  function changeFilterPayload(name: string, payload: Partial<DefectType>) {
    if (!filter.value[name]) throw new Error('Нет дефекта с таким именем')

    filter.value[name] = Object.assign({}, filter.value[name], payload)
  }

  /**
   * Обновление списка полос в фильтре дефектов
   * */
  function changeRoadLines() {
    const lines = createLinesOptions(defects.value)

    const initial = roadLines.value.find((line) => line.id === 0)
    if (!initial) throw new Error('Нет данных')

    state.filter.lines = [initial, ...lines]
  }

  /**
   * Смена цвета дефекта в фильтре
   */
  function changeDefectColor(option: SelectOption, id: number) {
    state.filter.style = style.value.map((type) => {
      if (type.id === id) {
        type.color = option.value as string
        type.filter = colorsFilter.find((el) => el.value === option.value)?.filter as string[]
      }

      return type
    })
  }

  /**
   * Посчитать значения фильтра
   */
  function calcFilterValues(defects: RoadNeiroDefect[]) {
    const payload: DefectFilterPayload = {}

    defectTypes.value.forEach((defect) => {
      payload[defect.name] = {} as DefectFilterPayloadData

      const defectPayload = definePayload({
        defects,
        filter: filter.value,
        name: defect.name,
        payload: payload[defect.name]
      })

      changeFilterPayload(defect.name, defectPayload)
    })
  }

  return {
    activeRoadLineId,
    filter,
    initialFilter,
    roadLines,
    activeRoadLine,
    defectTypes,
    visibleDefects,
    defectsColor,
    sizes,
    defectNames,
    style,

    setInitialPayloadFilter,
    changeFilterPayload,
    changeActiveRoadLine,
    changeDefectVisibility,
    calcFilterValues,
    changeRoadLines,
    changeDefectColor,
    resetFilter
  }
})
