function smallerTen(val: number) {
  return val < 10 ? `0${val}` : val
}

export function dateFormat(date: string | undefined) {
  if (!date) return

  const timestump = Date.parse(date)

  const dateData = new Date(timestump)

  const day = smallerTen(dateData.getDate())
  const month = smallerTen(dateData.getMonth() + 1)
  const year = dateData.getFullYear()

  return `${day}.${month}.${year}`
}
