import { MapFeatureRendered } from '@/types/Map.d'
import { Map, MapGeoJSONFeature } from 'maplibre-gl'

import { ChartProps } from '@/types/Chart'

export interface IFeature {
  source: string
  id: string | number
  sourceLayer?: string
}

interface LayoutSettings {
  properties: [
    {
      name: string
      property: string
      value: string
    }
  ]
}

export function getLegends(options: Partial<ChartProps>) {
  const legends = [options.data]
    .map((segment) => segment?.lines?.map((line) => line.at(0)?.line))
    .flat()
    .filter((lineName) => lineName)

  return legends.map((legend) => `${options.sign ? `${options.sign} ${legend}` : legend}`)
}

export function toggleEffectOnFeature(
  map: Partial<Map>,
  feature: MapFeatureRendered,
  settings: {
    [key: string]: boolean
  }
) {
  if (feature?.id == undefined) return

  map.setFeatureState?.(feature, settings)
}

export function MapFeature(this: MapFeatureRendered, feature: MapGeoJSONFeature | IFeature) {
  this.id = feature.id
  this.source = feature.source

  if (feature.sourceLayer) {
    this.sourceLayer = feature.sourceLayer
  }
}

export function changeLayoutProperty(map: Map, settings: LayoutSettings) {
  const { properties } = settings

  properties.forEach((layer) => {
    const { name, property, value } = layer

    if (map.getLayer(name)) {
      map.setLayoutProperty(name, property, value)
    }
  })
}
