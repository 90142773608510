import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'
import { RoadObjectsTypeId } from '@/utils/lists'

import { RoadRealty } from '@/types/Road'

interface RoadRealtyState {
  id?: number
  data: RoadRealty[]
}

export const useRoadService = defineStore('road-service', () => {
  const state: RoadRealtyState = reactive({
    data: [],
    id: undefined
  })

  const roadServices = computed(() => state.data)

  async function fetchRoadServices(roadId: number) {
    if (state.data.length && roadId === state.id) return

    state.id = roadId

    const response = (await fetchWrapper.get(
      `/roads/${roadId}/objects/${RoadObjectsTypeId.ROAD_REALTY}?page_number=1&elements_on_page=50`,
      {}
    )) as { data: RoadRealty[] }

    state.data = response.data
  }

  return {
    roadServices,
    fetchRoadServices
  }
})
