export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  patch: request('PATCH'),
  delete: request('DELETE'),
  download: request('DOWNLOAD')
}

interface DownloadRequestBody {
  method?: string
}

function request(method: string) {
  return async (
    url: string,
    body?: XMLHttpRequestBodyInit | object,
    rootUrl?: string,
    isFile = false
  ) => {
    const { default: router } = await import('../router/index')

    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest()
      const requestMethod = getMethod(method, body)
      const root = rootUrl ?? import.meta.env.VITE_API_URL

      xhr.open(requestMethod, `${root}${url}`, true)

      setHeaders(xhr, method, false, body, isFile)

      const xhrBody = isFile ? body : JSON.stringify(body)

      xhr.send(xhrBody as XMLHttpRequestBodyInit)
      xhr.onprogress = (evt) => xhrProgress(evt)
      xhr.onload = (ev) => {
        const response = getResponse(ev, method)
        res(response as typeof response)
      }
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400 && xhr.status <= 499) {
          if (xhr.status === 401 || xhr.status === 403) {
            router.push('/login')
            return
          }

          rej((e.target as XMLHttpRequest).response)
        }
      }
      xhr.onerror = (error) => rej(error)
    })
  }
}

function getMethod(method: string, body?: XMLHttpRequestBodyInit | object) {
  const requestMethod = (
    method === 'DOWNLOAD' ? (body as DownloadRequestBody).method : method
  ) as string

  if (method === 'DOWNLOAD') {
    delete (body as DownloadRequestBody).method
  }

  return requestMethod
}

function setHeaders(
  xhr: XMLHttpRequest,
  method: string,
  isLogin: boolean,
  body?: XMLHttpRequestBodyInit | object,
  isFile = false
) {
  const authorizationHeader = authHeader()

  if (authorizationHeader) {
    const [name, value] = authorizationHeader

    xhr.setRequestHeader(name, value)
  }

  if (!(body && method !== 'GET')) return

  if (!isFile) {
    const contentType = isLogin ? 'application/x-www-form-urlencoded' : 'application/json'
    xhr.setRequestHeader('Content-Type', contentType)
  }

  xhr.setRequestHeader('Accept', 'application/json')
}

function getResponse(ev: Event, method: string) {
  const response = (ev.target as XMLHttpRequest).response

  if (method === 'DOWNLOAD') {
    return downloadResponse(ev.target as XMLHttpRequest)
  }

  if (!response) return

  return JSON.parse(response) as XMLHttpRequestResponseType
}

function xhrProgress(evt: ProgressEvent) {
  const event = new CustomEvent('loading', {
    detail: {
      progress: Math.round((evt.loaded / evt.total) * 100)
    }
  })

  window.dispatchEvent(event)
}

function authHeader(): string[] | undefined {
  const token = window.localStorage.getItem('tokenData')

  let lsToken

  if (token) {
    lsToken = JSON.parse(token)
  }

  return lsToken?.access_token ? ['Authorization', `Bearer ${lsToken.access_token}`] : undefined
}

async function downloadResponse(xhr: XMLHttpRequest) {
  const disposition = xhr.getResponseHeader('Content-Disposition')

  if (!disposition) return

  const file = disposition?.split('filename=').at(-1)?.replaceAll('"', '')

  if (!file) return

  return [xhr.response, file]
}
