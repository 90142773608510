<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 1.39012L1.36289 0L5.5 4.21976L9.63711 0L11 1.39012L5.5 7L0 1.39012Z"
    fill="white"
  />
</template>
