<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.26944 3.97897C7.5768 3.63722 8.07512 3.63722 8.38248 3.97897L11.9997 8.00099L8.38248 12.023C8.07512 12.3648 7.5768 12.3648 7.26944 12.023C6.96208 11.6812 6.96208 11.1272 7.26944 10.7854L8.98659 8.8761H3.9345C3.49983 8.8761 3.14746 8.4843 3.14746 8.00099C3.14746 7.51767 3.49983 7.12587 3.9345 7.12587H8.98659L7.26944 5.21657C6.96208 4.87481 6.96208 4.32072 7.26944 3.97897Z"
    fill="#D68166"
  />
  <path
    d="M0 2.62534C0 1.1754 1.05711 0 2.36111 0H3.06008C3.54339 0 3.93519 0.391802 3.93519 0.875113C3.93519 1.35842 3.54339 1.75023 3.06007 1.75023H2.36111C1.92644 1.75023 1.57408 2.14203 1.57408 2.62534V13.3765C1.57408 13.8598 1.92644 14.2516 2.36111 14.2516H3.06007C3.54339 14.2516 3.93519 14.6434 3.93519 15.1267C3.93519 15.61 3.54339 16.0018 3.06007 16.0018H2.36111C1.05711 16.0018 0 14.8264 0 13.3765V2.62534Z"
    fill="#D68166"
  />
</template>
