export const filterCheckbox = [
  {
    id: 1,
    name: 'Наименование',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 2,
    name: 'Значение',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 3,
    name: 'Идентификационный номер',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 4,
    name: 'Протяженность',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 5,
    name: 'Номер СКДФ',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 6,
    name: 'Техническая категория',
    checked: true,
    disabled: false,
    hidden: false
  },
  {
    id: 7,
    name: 'Нормативное состояние',
    checked: true,
    disabled: false,
    hidden: false
  }
]

export const mainTableHead = [
  {
    id: 1,
    name: 'Наименование',
    class: 'name',
    hidden: false
  },
  {
    id: 2,
    name: 'Значение',
    class: 'value',
    hidden: false
  },
  {
    id: 3,
    name: 'Идентификационный номер',
    class: 'id',
    hidden: false
  },
  {
    id: 4,
    name: 'Протяженность, км',
    class: 'distance',
    hidden: false
  },
  {
    id: 5,
    name: 'Номер СКДФ',
    class: 'skdf_number',
    hidden: false
  },
  {
    id: 6,
    name: 'Техническая категория',
    class: 'technical_category',
    hidden: false
  },
  {
    id: 7,
    name: 'Нормативное состояние, %',
    class: 'state',
    hidden: false
  }
]
