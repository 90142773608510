<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.32656e-05 11.5556L11.5556 0.000237423L13 1.44466L1.44453 13L9.32656e-05 11.5556Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.44443 0L12.9999 11.5553L11.5555 12.9998L0 1.44442L1.44443 0Z"
  />
</template>
