import { RoadSegment } from '@/types/Road'

function sortSegmentsById(segments: RoadSegment[]) {
  const copy = segments.slice()

  return copy.sort((a, b) => a.id - b.id)
}

function segmentsToTabs(segments: RoadSegment[]) {
  const segmentTabs = segments.map((el) => ({
    id: el.id,
    name: el.name
  }))

  return segmentTabs
}

export default function () {
  return {
    sortSegmentsById,
    segmentsToTabs
  }
}
