export function azimuth(value: number) {
  if (value < 0) return 360 + value
  if (value > 360) return value - 360

  return value
}

export function inAzimuthRange(start: number, end: number, bearing: number) {
  const diffEndStart = end - start
  const diffBearingStart = bearing - start

  const endValue = diffEndStart < 0 ? diffEndStart + 360 : diffEndStart
  const mid = diffBearingStart < 0 ? diffBearingStart + 360 : diffBearingStart

  return mid < endValue
}
