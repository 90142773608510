<script setup lang="ts">
import { computed } from 'vue'

import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import AppRoadDefectsMaskFilter from '@/components/layouts/road/diagnostic/defects/AppRoadDefectsMaskFilter/AppRoadDefectsMaskFilter.vue'
import FilterFormDefectsSize from '@/components/forms/FilterFormDefectsSize/FilterFormDefectsSize.vue'

const defectFilterStore = useDefectFilterStore()

const defectTypes = computed(() => defectFilterStore.defectTypes)
const visibleTypes = computed(() => defectTypes.value.filter((el) => el.show))
const filter = computed(() => defectFilterStore.filter)
</script>

<template>
  <AppRoadDefectsMaskFilter>
    <FilterFormDefectsSize
      v-for="(defect, index) in visibleTypes"
      :key="index + '-defect-filter-row'"
      :defect="defect"
      :params="filter[defect.name]"
    />
  </AppRoadDefectsMaskFilter>
</template>

<style lang="scss"></style>
