/* eslint-disable no-undef */
import copyMaterial from './copyMaterial'
import LasExporter from './LasExport'
import CsvExporter from './CsvExport'
import ProfileFakeOctree from './ProfileFakeOctree'

export default class ProfileWindow extends Potree.EventDispatcher {
  constructor(viewer, pointPropertiesHandler, profileNumPointsHandler) {
    super()

    this.viewer = viewer
    this.elRoot = $('#js-potree-profile-window')
    this.renderArea = this.elRoot.find('#js-potree-profile-canvas')
    this.svg = d3.select('svg#js-potree-profile-svg')
    this.mouseIsDown = false

    this.projectedBox = new THREE.Box3()
    this.pointclouds = new Map()
    this.lastAddPointsTimestamp = undefined

    this.mouse = new THREE.Vector2(0, 0)
    this.scale = new THREE.Vector3(1, 1, 1)

    this.autoFitEnabled = true // completely disable/enable
    this.autoFit = false // internal

    this.initTHREE()
    this.initSVG()
    this.initListeners()

    this.elRoot.draggable({
      handle: $('#js-potree-profile-titlebar'),
      containment: $(window.document.body)
    })

    this.elRoot.resizable({
      containment: $(window.document.body),
      handles: 'n, e, s, w'
    })

    this.pRenderer = new Potree.Renderer(this.renderer)

    // методы которые передаются снаружи для взаимодействия с vue
    this.pointPropertiesHandler = pointPropertiesHandler
    this.profileNumPointsHandler = profileNumPointsHandler
  }

  initListeners() {
    $(window).resize(() => {
      if (this.enabled) {
        this.render()
      }
    })

    this.renderArea.mousedown(() => {
      this.mouseIsDown = true
    })

    this.renderArea.mouseup(() => {
      this.mouseIsDown = false
    })

    const viewerPickSphereSizeHandler = () => {
      const camera = this.viewer.scene.getActiveCamera()
      const domElement = this.viewer.renderer.domElement
      const distance = this.viewerPickSphere.position.distanceTo(camera.position)
      const pr = Potree.Utils.projectedRadius(
        1,
        camera,
        distance,
        domElement.clientWidth,
        domElement.clientHeight
      )
      const scale = 10 / pr
      this.viewerPickSphere.scale.set(scale, scale, scale)
    }

    this.renderArea.mousemove((e) => {
      if (this.pointclouds.size === 0) {
        return
      }

      const rect = this.renderArea[0].getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      const newMouse = new THREE.Vector2(x, y)

      if (this.mouseIsDown) {
        // DRAG
        this.autoFit = false
        this.lastDrag = new Date().getTime()

        const cPos = [this.scaleX.invert(this.mouse.x), this.scaleY.invert(this.mouse.y)]
        const ncPos = [this.scaleX.invert(newMouse.x), this.scaleY.invert(newMouse.y)]

        this.camera.position.x -= ncPos[0] - cPos[0]
        this.camera.position.z -= ncPos[1] - cPos[1]

        this.render()
      } else if (this.pointclouds.size > 0) {
        // FIND HOVERED POINT
        const radius = Math.abs(this.scaleX.invert(0) - this.scaleX.invert(40))
        const mileage = this.scaleX.invert(newMouse.x)
        const elevation = this.scaleY.invert(newMouse.y)

        const closest = this.selectPoint(mileage, elevation, radius)

        if (closest) {
          const point = closest.point

          const position = new Float64Array([
            point.position[0] + closest.pointcloud.position.x,
            point.position[1] + closest.pointcloud.position.y,
            point.position[2] + closest.pointcloud.position.z
          ])

          this.pickSphere.visible = true
          this.pickSphere.scale.set(0.5 * radius, 0.5 * radius, 0.5 * radius)
          this.pickSphere.position.set(point.mileage, 0, position[2])

          this.viewerPickSphere.position.set(...position)

          if (!this.viewer.scene.scene.children.includes(this.viewerPickSphere)) {
            this.viewer.scene.scene.add(this.viewerPickSphere)
            if (!this.viewer.hasEventListener('update', viewerPickSphereSizeHandler)) {
              this.viewer.addEventListener('update', viewerPickSphereSizeHandler)
            }
          }

          /**
           * Подготавливаем данные для свойст точки и отдаем их наверх
           */
          const propertiesData = {
            position: {
              x: Potree.Utils.addCommas(position[0].toFixed(3)),
              y: Potree.Utils.addCommas(position[1].toFixed(3)),
              z: Potree.Utils.addCommas(position[2].toFixed(3))
            },
            rgba: point.rgba.join(', '),
            mileage: point.mileage.toFixed(3)
          }
          this.pointPropertiesHandler(propertiesData)

          this.selectedPoint = point
        } else {
          this.viewer.scene.scene.add(this.viewerPickSphere)

          const index = this.viewer.scene.scene.children.indexOf(this.viewerPickSphere)
          if (index >= 0) {
            this.viewer.scene.scene.children.splice(index, 1)
          }
          this.viewer.removeEventListener('update', viewerPickSphereSizeHandler)
        }
        this.render()
      }

      this.mouse.copy(newMouse)
    })

    const onWheel = (e) => {
      this.autoFit = false

      let delta = 0
      if (e.wheelDelta !== undefined) {
        // WebKit / Opera / Explorer 9
        delta = e.wheelDelta
      } else if (e.detail !== undefined) {
        // Firefox
        delta = -e.detail
      }

      const ndelta = Math.sign(delta)
      const cPos = [this.scaleX.invert(this.mouse.x), this.scaleY.invert(this.mouse.y)]

      if (ndelta > 0) {
        // + 10%
        this.scale.multiplyScalar(1.1)
      } else {
        // - 10%
        this.scale.multiplyScalar(100 / 110)
      }

      this.updateScales()
      const ncPos = [this.scaleX.invert(this.mouse.x), this.scaleY.invert(this.mouse.y)]

      this.camera.position.x -= ncPos[0] - cPos[0]
      this.camera.position.z -= ncPos[1] - cPos[1]

      this.render()
      this.updateScales()
    }
    $(this.renderArea)[0].addEventListener('mousewheel', onWheel, false)
    $(this.renderArea)[0].addEventListener('DOMMouseScroll', onWheel, false) // Firefox

    $('#js-potree-download-profile-ortho-link').click(() => {
      const points = this.getPoints()

      const string = CsvExporter.toString(points)

      const blob = new Blob([string], { type: 'text/string' })
      $('#js-potree-download-profile-ortho-link').attr('href', URL.createObjectURL(blob))
    })

    $('#js-potree-download-profile-link').click(() => {
      const points = this.getPoints()

      const buffer = LasExporter.toLAS(points)

      const blob = new Blob([buffer], { type: 'application/octet-binary' })
      $('#js-potree-download-profile-link').attr('href', URL.createObjectURL(blob))
    })
  }

  selectPoint(mileage, elevation, radius) {
    let closest = {
      distance: Infinity,
      pointcloud: null,
      points: null,
      index: null
    }

    const pointBox = new THREE.Box2(
      new THREE.Vector2(mileage - radius, elevation - radius),
      new THREE.Vector2(mileage + radius, elevation + radius)
    )

    for (const [pointcloud, entry] of this.pointclouds) {
      for (const points of entry.points) {
        const collisionBox = new THREE.Box2(
          new THREE.Vector2(points.projectedBox.min.x, points.projectedBox.min.z),
          new THREE.Vector2(points.projectedBox.max.x, points.projectedBox.max.z)
        )

        if (!collisionBox.intersectsBox(pointBox)) {
          continue
        }

        for (let i = 0; i < points.numPoints; i++) {
          const m = points.data.mileage[i] - mileage
          const e = points.data.position[3 * i + 2] - elevation + pointcloud.position.z
          const r = Math.sqrt(m * m + e * e)

          const withinDistance = r < radius && r < closest.distance
          let unfilteredClass = true

          if (points.data.classification) {
            const classification = pointcloud.material.classification

            const pointClassID = points.data.classification[i]
            const pointClassValue = classification[pointClassID]

            if (pointClassValue && (!pointClassValue.visible || pointClassValue.color.w === 0)) {
              unfilteredClass = false
            }
          }

          if (withinDistance && unfilteredClass) {
            closest = {
              distance: r,
              pointcloud,
              points,
              index: i
            }
          }
        }
      }
    }

    if (closest.distance < Infinity) {
      const points = closest.points

      const point = {}

      const attributes = Object.keys(points.data)
      for (const attribute of attributes) {
        const attributeData = points.data[attribute]
        const itemSize = attributeData.length / points.numPoints
        const value = attributeData.subarray(
          itemSize * closest.index,
          itemSize * closest.index + itemSize
        )

        if (value.length === 1) {
          point[attribute] = value[0]
        } else {
          point[attribute] = value
        }
      }

      closest.point = point

      return closest
    } else {
      return null
    }
  }

  getPoints() {
    const points = new Potree.Points()

    for (const [pointcloud, entry] of this.pointclouds) {
      for (const pointSet of entry.points) {
        const originPos = pointSet.data.position
        const trueElevationPosition = new Float32Array(originPos)
        for (let i = 0; i < pointSet.numPoints; i++) {
          trueElevationPosition[3 * i + 2] += pointcloud.position.z
        }

        pointSet.data.position = trueElevationPosition
        points.add(pointSet)
        pointSet.data.position = originPos
      }
    }

    return points
  }

  initTHREE() {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      premultipliedAlpha: false
    })
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.setSize(10, 10)
    this.renderer.autoClear = false
    this.renderArea.append($(this.renderer.domElement))
    this.renderer.domElement.tabIndex = '2222'
    $(this.renderer.domElement).css('width', '100%')
    $(this.renderer.domElement).css('height', '100%')

    {
      const gl = this.renderer.getContext()

      if (gl.createVertexArray == null) {
        const extVAO = gl.getExtension('OES_vertex_array_object')

        if (!extVAO) {
          throw new Error('OES_vertex_array_object extension not supported')
        }

        gl.createVertexArray = extVAO.createVertexArrayOES.bind(extVAO)
        gl.bindVertexArray = extVAO.bindVertexArrayOES.bind(extVAO)
      }
    }

    this.camera = new THREE.OrthographicCamera(-1000, 1000, 1000, -1000, -1000, 1000)
    this.camera.up.set(0, 0, 1)
    this.camera.rotation.order = 'ZXY'
    this.camera.rotation.x = Math.PI / 2.0

    this.scene = new THREE.Scene()
    this.profileScene = new THREE.Scene()

    const sg = new THREE.SphereGeometry(1, 16, 16)
    const sm = new THREE.MeshNormalMaterial()
    this.pickSphere = new THREE.Mesh(sg, sm)
    this.scene.add(this.pickSphere)

    {
      const sg = new THREE.SphereGeometry(2)
      const sm = new THREE.MeshNormalMaterial()
      const s = new THREE.Mesh(sg, sm)

      s.position.set(589530.45, 231398.86, 769.735)

      this.scene.add(s)
    }

    this.viewerPickSphere = new THREE.Mesh(sg, sm)
  }

  initSVG() {
    const width = this.renderArea[0].clientWidth
    const height = this.renderArea[0].clientHeight
    const marginLeft = this.renderArea[0].offsetLeft

    this.svg.selectAll('*').remove()

    this.scaleX = d3.scale
      .linear()
      .domain([
        this.camera.left + this.camera.position.x,
        this.camera.right + this.camera.position.x
      ])
      .range([0, width])
    this.scaleY = d3.scale
      .linear()
      .domain([
        this.camera.bottom + this.camera.position.z,
        this.camera.top + this.camera.position.z
      ])
      .range([height, 0])

    this.xAxis = d3.svg
      .axis()
      .scale(this.scaleX)
      .orient('bottom')
      .innerTickSize(-height)
      .outerTickSize(1)
      .tickPadding(10)
      .ticks(width / 50)

    this.yAxis = d3.svg
      .axis()
      .scale(this.scaleY)
      .orient('left')
      .innerTickSize(-width)
      .outerTickSize(1)
      .tickPadding(10)
      .ticks(height / 20)

    this.elXAxis = this.svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${marginLeft}, ${height})`)
      .call(this.xAxis)

    this.elYAxis = this.svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${marginLeft}, 0)`)
      .call(this.yAxis)
  }

  addPoints(pointcloud, points) {
    if (!points || points.numPoints === 0) {
      return
    }

    let entry = this.pointclouds.get(pointcloud)
    if (!entry) {
      entry = new ProfileFakeOctree(pointcloud)
      this.pointclouds.set(pointcloud, entry)
      this.profileScene.add(entry)

      const materialChanged = () => {
        this.render()
      }

      materialChanged()

      pointcloud.material.addEventListener('material_property_changed', materialChanged)
      this.addEventListener('on_reset_once', () => {
        pointcloud.material.removeEventListener('material_property_changed', materialChanged)
      })
    }

    entry.addPoints(points)
    this.projectedBox.union(entry.projectedBox)

    if (this.autoFit && this.autoFitEnabled) {
      const width = this.renderArea[0].clientWidth
      const height = this.renderArea[0].clientHeight

      const size = this.projectedBox.getSize(new THREE.Vector3())

      const sx = width / size.x
      const sy = height / size.z
      const scale = Math.min(sx, sy)

      const center = this.projectedBox.getCenter(new THREE.Vector3())
      this.scale.set(scale, scale, 1)
      this.camera.position.copy(center)
    }

    this.render()

    /**
     * Подготавливаем данные о количестве точек и отдаем их наверх
     */
    let numPoints = 0
    for (const [, value] of this.pointclouds.entries()) {
      numPoints += value.points.reduce((a, i) => a + i.numPoints, 0)
    }
    this.profileNumPointsHandler(Potree.Utils.addCommas(numPoints))
  }

  reset() {
    this.lastReset = new Date().getTime()

    this.dispatchEvent({ type: 'on_reset_once' })
    this.removeEventListeners('on_reset_once')

    this.autoFit = true
    this.projectedBox = new THREE.Box3()

    for (const [, entry] of this.pointclouds) {
      entry.dispose()
    }

    this.pointclouds.clear()
    this.mouseIsDown = false
    this.mouse.set(0, 0)

    if (this.autoFitEnabled) {
      this.scale.set(1, 1, 1)
    }
    this.pickSphere.visible = false

    this.elRoot.find('#js-potree-profile-properties').hide()

    this.render()
  }

  show() {
    this.elRoot.fadeIn()
    this.enabled = true
  }

  hide() {
    this.elRoot.fadeOut()
    this.enabled = false
  }

  updateScales() {
    const width = this.renderArea[0].clientWidth
    const height = this.renderArea[0].clientHeight

    const left = -width / 2 / this.scale.x
    const right = +width / 2 / this.scale.x
    const top = +height / 2 / this.scale.y
    const bottom = -height / 2 / this.scale.y

    this.camera.left = left
    this.camera.right = right
    this.camera.top = top
    this.camera.bottom = bottom
    this.camera.updateProjectionMatrix()

    this.scaleX
      .domain([
        this.camera.left + this.camera.position.x,
        this.camera.right + this.camera.position.x
      ])
      .range([0, width])
    this.scaleY
      .domain([
        this.camera.bottom + this.camera.position.z,
        this.camera.top + this.camera.position.z
      ])
      .range([height, 0])

    const marginLeft = this.renderArea[0].offsetLeft

    this.xAxis
      .scale(this.scaleX)
      .orient('bottom')
      .innerTickSize(-height)
      .outerTickSize(1)
      .tickPadding(10)
      .ticks(width / 50)
    this.yAxis
      .scale(this.scaleY)
      .orient('left')
      .innerTickSize(-width)
      .outerTickSize(1)
      .tickPadding(10)
      .ticks(height / 20)

    this.elXAxis.attr('transform', `translate(${marginLeft}, ${height})`).call(this.xAxis)
    this.elYAxis.attr('transform', `translate(${marginLeft}, 0)`).call(this.yAxis)
  }

  requestScaleUpdate() {
    const threshold = 100
    const allowUpdate =
      this.lastReset === undefined ||
      this.lastScaleUpdate === undefined ||
      (new Date().getTime() - this.lastReset > threshold &&
        new Date().getTime() - this.lastScaleUpdate > threshold)

    if (allowUpdate) {
      this.updateScales()
      this.lastScaleUpdate = new Date().getTime()
      this.scaleUpdatePending = false
    } else if (!this.scaleUpdatePending) {
      setTimeout(this.requestScaleUpdate.bind(this), 100)
      this.scaleUpdatePending = true
    }
  }

  render() {
    const width = this.renderArea[0].clientWidth
    const height = this.renderArea[0].clientHeight

    const { renderer, pRenderer, camera, profileScene, scene } = this
    const { scaleX, pickSphere } = this

    renderer.setSize(width, height)

    renderer.setClearColor(0x000000, 0)
    renderer.clear(true, true, false)

    for (const pointcloud of this.pointclouds.keys()) {
      const source = pointcloud.material
      const target = this.pointclouds.get(pointcloud).material

      copyMaterial(source, target)
      target.size = 2
    }

    pRenderer.render(profileScene, camera, null)

    const radius = Math.abs(scaleX.invert(0) - scaleX.invert(5))

    if (radius === 0) {
      pickSphere.visible = false
    } else {
      pickSphere.scale.set(radius, radius, radius)
      pickSphere.visible = true
    }

    this.renderer.render(scene, camera)

    this.requestScaleUpdate()
  }
}
