import type { RoadGeoJSON } from '@/types/Road'
import type { FeatureState, Map } from 'maplibre-gl'
import { GeometryMapLayers } from '../lists'
import { MapRenderParams } from '@/types/Map'

import renderLine from './geometries/line'
import renderCluster from './geometries/cluster'
import renderCircle from './geometries/circle'
import renderSymbol from './geometries/symbol'
import renderPolygon from './geometries/polygon'
import renderEgrn from './geometries/egrn'
import renderOrto from './geometries/orto'

const { addEgrnLayers } = renderEgrn()
const { addOrtoLayer } = renderOrto()

function matchGeometry(data: RoadGeoJSON, type: string) {
  if (!data) return

  const geometry = data.features?.some((el) =>
    el.geometry.type !== 'GeometryCollection'
      ? el.geometry.type === type || el.geometry.type === `Multi${type}`
      : el.geometry.geometries.some(
          (item: FeatureState) => item.type === type || item.type === `Multi${type}`
        )
  )

  return !!geometry
}

function renderPoint(map: Map, layer: MapRenderParams) {
  const type = layer.params.name === GeometryMapLayers.ROAD_SIGN ? 'symbol' : 'circle'

  if (type === 'symbol') {
    renderCluster(map, layer.params)
    renderSymbol(map, layer.params)
  } else if (type === 'circle') renderCircle(map, layer.params)
}

function defineGeometryType(layer: MapRenderParams) {
  const { data, params } = layer

  const egrn = params.name.startsWith(GeometryMapLayers.EGRN_CUSTOM)
  const orto = params.name.startsWith(GeometryMapLayers.ORTO)

  const notForeign = !egrn && !orto

  const line = matchGeometry(data as RoadGeoJSON, 'LineString') && notForeign
  const point = matchGeometry(data as RoadGeoJSON, 'Point') && notForeign
  const polygon = matchGeometry(data as RoadGeoJSON, 'Polygon') && notForeign

  const addLine = line ? renderLine : undefined
  const addPoint = point ? renderPoint : undefined
  const addPolygon = polygon ? renderPolygon : undefined
  const addOrto = orto ? addOrtoLayer : undefined
  const addEgrn = egrn ? addEgrnLayers : undefined

  return [
    {
      name: 'point',
      is: point,
      render: (map: Map, layer: MapRenderParams) => addPoint?.(map, layer)
    },
    {
      name: 'line',
      is: line,
      render: (map: Map, layer: MapRenderParams) => addLine?.(map, layer.params)
    },
    {
      name: 'polygon',
      is: polygon,
      render: (map: Map, layer: MapRenderParams) => addPolygon?.(map, layer.params)
    },
    {
      name: 'orto',
      is: orto,
      render: (map: Map, layer: MapRenderParams) => addOrto?.(map, layer)
    },
    {
      name: 'egrn',
      is: egrn,
      render: (map: Map, layer: MapRenderParams) => addEgrn?.(map, layer)
    }
  ]
}

export default function () {
  return {
    defineGeometryType
  }
}
