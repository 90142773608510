import { RegulatoryDoc } from '@/types/Road'
import { fetchWrapper } from '@/api/fetchApi'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { usePreloaderStore } from '../preloader'

interface RoadRegulatoryState {
  data: RegulatoryDoc[]
  id: number | null
}

export const useRoadRegulatory = defineStore('road-regulatory', () => {
  const preloaderStore = usePreloaderStore()

  const state: RoadRegulatoryState = reactive({
    data: [],
    id: null
  })

  const getRegulatory = computed(() => state.data)

  async function getRegulatoryDocs(id: number) {
    if (state.data.length && state.id === id) return

    state.id = id

    const response = (await fetchWrapper.get(
      `/roads/roads/${id}/documents/`,
      {}
    )) as RegulatoryDoc[]

    state.data = response
  }

  async function removeDocument(roadId: number, documentId: number) {
    try {
      preloaderStore.togglePreloader()
      await fetchWrapper.delete(`/roads/roads/${roadId}/documents/${documentId}/`, {})

      state.data = state.data.filter((el) => el.id !== documentId)
    } catch (e) {
      throw new Error(e.message)
    } finally {
      preloaderStore.togglePreloader()
    }
  }

  async function uploadDocument(roadId: number, file: File) {
    try {
      const body = new FormData()

      body.append('file', file)

      preloaderStore.togglePreloader()

      const response = (await fetchWrapper.post(
        `/roads/roads/${roadId}/documents/?name=${file.name}`,
        body,
        undefined,
        true
      )) as RegulatoryDoc

      state.data.push(response)
    } catch (e) {
      throw new Error(e.message)
    } finally {
      preloaderStore.togglePreloader()
    }
  }

  return {
    getRegulatory,
    getRegulatoryDocs,

    uploadDocument,
    removeDocument
  }
})
