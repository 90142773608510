import { fetchWrapper } from '@/api/fetchApi'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

interface RoadSegment {
  connection_address: null
  created_date: string
  geom: string
  id: number
  length: number
  name: string
  parent: null
  road_id: number
  updated_date: string
}

interface RoadCategoriesState {
  header: string[]
  data: {
    name: string
    items: { length: number }[]
  }[]
  id?: number
}

export const useRoadCategoriesStore = defineStore('road-categories', () => {
  const state: RoadCategoriesState = reactive({
    header: [
      'Начало участка, км+м',
      'Конец участка, км+м',
      'Протяженность, км',
      'Площадь покрытия проезжей части, м2',
      'Техническая категория',
      'Рельеф местности'
    ],
    data: [],
    id: undefined
  })

  const getCategories = computed(() => state)

  async function fetchSegments(roadId: number) {
    if (state.id === roadId && state.data.length) return

    state.id = roadId

    const response = (await fetchWrapper.get(`/roads/${roadId}/segments/`, {})) as RoadSegment[]

    state.data = response.map((segment) => ({
      name: segment.name,
      items: [
        {
          length: segment.length
        }
      ]
    }))
  }

  return {
    fetchSegments,
    getCategories
  }
})
