import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import type { ProfilePointProperies } from '@/types/module/profileWindow'
import ProfileWindow from '@/components/layouts/model/helpers/ProfileWindow'
import ProfileWindowController from '@/components/layouts/model/helpers/ProfileWindowController'
import { setCompositeElevationRange, DEFAULT_GRADIENT, DEFAULT_POINT_TYPE } from '@/utils/model'

const VITE_API_URL = import.meta.env.VITE_API_URL

export const useModelStore = defineStore('model', () => {
  const viewer = ref<any>(null)

  const exporter = reactive({
    value: null as any | null,
    id: null as number | null
  })

  const profilePointProperies = ref<ProfilePointProperies | null>(null)
  const profileNumPoints = ref<string>('')

  /**
   * Создает объект потри
   */
  const creatViewerHandler = () => {
    const container = document.getElementById('potree_render_area')
    if (!container) return

    window.viewer = new window.Potree.Viewer(container)

    window.viewer.setEDLEnabled(true)
    window.viewer.setFOV(60)
    window.viewer.useHQ = false
    window.viewer.setPointBudget(10_000_000)
    window.viewer.setMinNodeSize(0)
    window.viewer.setBackground(null)
    window.viewer.loadSettingsFromURL()

    /**
     * Получает и записывает свойства точки в профиле высот
     */
    const pointPropertiesHandler = (pointProperies: ProfilePointProperies) => {
      profilePointProperies.value = pointProperies
    }

    /**
     * Получает и записывает свойства точки в профиле высот
     */
    const profileNumPointsHandler = (numPoints: string) => {
      profileNumPoints.value = numPoints
    }

    window.viewer.profileWindow = new ProfileWindow(
      window.viewer,
      pointPropertiesHandler,
      profileNumPointsHandler
    )
    window.viewer.profileWindowController = new ProfileWindowController(window.viewer)

    viewer.value = window.viewer
  }

  const loadCloud = (name: string) => {
    const url = `${VITE_API_URL}/static/pc/${name}/cloud.js`

    window.Potree.loadPointCloud(url, name, (e: any) => {
      const scene = window.viewer.scene
      const pointcloud = e.pointcloud
      scene.addPointCloud(pointcloud)

      const material = pointcloud.material
      material.size = 0.75
      material.pointSizeType = window.Potree.PointSizeType[DEFAULT_POINT_TYPE]
      material.shape = window.Potree.PointShape.CIRCLE
      material.gradient = window.Potree.Gradients[DEFAULT_GRADIENT]
      material.activeAttributeName = 'composite'
      material.weightIntensity = 1
      material.weightElevation = 1
      material.weightRGB = 0
      material.intensityBrightness = 0.02
      material.intensityContrast = 0.04
      material.intensityGamma = 2.03
      material.intensityRange = [255, 12000]
      material.elevationRangeDefault = [material.elevationRange[0], material.elevationRange[1]]
      material.elevationRange = setCompositeElevationRange(material.elevationRange)

      viewer.value.fitToScreen()
    })
  }

  /**
   * Устанавливает угол камеры по отношению к облаку
   */
  const onSetView = (value: 'F' | 'B' | 'L' | 'R' | 'U' | 'D') => {
    viewer.value.setView(value)
  }

  /**
   * Переключает тип отображения камеры
   */
  const onSetCameraMode = (modeId: 0 | 1) => {
    viewer.value.setCameraMode(modeId)
  }

  /**
   * Удаляет все инструменты измерения
   */
  const deleteAllMeasurements = () => {
    if (!viewer.value?.scene) return

    for (const measure of viewer.value.scene.measurements) {
      measure.visible = false
    }
    for (const profile of viewer.value.scene.profiles) {
      profile.visible = false
    }
    for (const volume of viewer.value.scene.volumes) {
      volume.visible = false
    }

    viewer.value.scene.removeAllMeasurements()
    viewer.value.measuringTool.update()
    clearExporter()
  }

  /**
   * Удаляет инструмент измерения
   */
  const removeMeasurement = (measurement: any) => {
    if (!measurement) return

    /**
     * Меняет свойство visible на false по id
     */
    const hideVolume = (volumeList: any[]) => {
      for (const volume of volumeList) {
        if (measurement.id === volume.id) {
          volume.visible = false
        }
      }
    }

    if (measurement.constructor.name === 'Profile') {
      hideVolume(window.viewer.scene.profiles)
      viewer.value.scene.removeProfile(measurement)
      return
    }

    if (measurement.constructor.name === 'BoxVolume') {
      hideVolume(viewer.value.scene.volumes)
      viewer.value.scene.removeVolume(measurement)
      return
    }

    if (measurement.constructor.name === 'PolygonClipVolume') {
      hideVolume(viewer.value.scene.measurements)
      viewer.value.scene.removePolygonClipVolume(measurement)
      return
    }

    if (exporter.id === measurement.id) {
      clearExporter()
    }

    hideVolume(viewer.value.scene.measurements)
    viewer.value.scene.removeMeasurement(measurement)
  }

  /**
   * Устанавливает профиль высот
   */
  const showProfileHandler = (id: number) => {
    const profile = window.viewer.scene.profiles.find((profile: any) => profile.id === id)
    if (!profile) return

    window.viewer.profileWindowController.setProfile(profile)
    window.viewer.profileWindow.show()
  }

  /**
   * Очищает PolygonExporter
   */
  const clearExporter = () => {
    if (!exporter.value) return

    exporter.value.removeVolume()
    exporter.value = null
    exporter.id = null
  }

  const closeProfile = () => {
    window.viewer.profileWindow.hide()
  }

  return {
    viewer,
    exporter,
    profilePointProperies,
    profileNumPoints,
    onSetCameraMode,
    onSetView,
    creatViewerHandler,
    loadCloud,
    deleteAllMeasurements,
    removeMeasurement,
    showProfileHandler,
    closeProfile
  }
})
