<script setup lang="ts"></script>

<template>
  <circle cx="176.5" cy="4.5" r="4.5" fill="#D68166" />
  <circle cx="4.5" cy="4.5" r="4.5" fill="#D68166" />
  <circle cx="176.5" cy="31.5" r="4.5" fill="#D68166" />
  <circle cx="4.5" cy="31.5" r="4.5" fill="#D68166" />
  <rect x="4" y="4" width="173" height="28" fill="url(#paint0_linear_2453_8778)" />
  <defs>
    <linearGradient
      id="paint0_linear_2453_8778"
      x1="90.9999"
      y1="3.99966"
      x2="90.9999"
      y2="32"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#D68166" stop-opacity="0.6" />
      <stop offset="1" stop-color="#D68166" stop-opacity="0.05" />
    </linearGradient>
  </defs>
</template>
