<script setup lang="ts">
interface Props {
  show: boolean
  wrapperClass?: string | string[]
  shadow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  shadow: true,
  wrapperClass: undefined
})

/**
 * Раскрывает элемент
 */
const enter = (el: Element) => {
  const htmlEl = el as HTMLElement
  const width = `${htmlEl.offsetWidth}px`

  htmlEl.style.width = width
  htmlEl.style.visibility = 'hidden'
  htmlEl.style.height = 'auto'

  const height = `${htmlEl.offsetHeight}px`

  htmlEl.style.width = ''
  htmlEl.style.visibility = ''
  htmlEl.style.height = '0'

  requestAnimationFrame(() => {
    htmlEl.style.height = height
  })
}

/**
 * Устанавливает авто высоту элемента после завершения анимации
 */
const afterEnter = (el: Element) => {
  const htmlEl = el as HTMLElement
  htmlEl.style.height = ''
}

/**
 * Скрывает элемент
 */
const leave = (el: Element) => {
  const htmlEl = el as HTMLElement
  const height = `${htmlEl.offsetHeight}px`
  htmlEl.style.height = height
  requestAnimationFrame(() => {
    htmlEl.style.height = '0'
  })
}
</script>

<template>
  <Transition name="transitionHeight" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <div v-if="props.show" :class="wrapperClass || ''">
      <slot />
    </div>
  </Transition>
</template>

<style src="./transitionHeightStyle.scss" lang="scss" scoped></style>
