/* eslint-disable no-undef */
import copyMaterial from './copyMaterial'

class Batch {
  constructor(geometry, material) {
    this.geometry = geometry
    this.material = material

    this.sceneNode = new THREE.Points(geometry, material)

    this.geometryNode = {
      estimatedSpacing: 1.0,
      geometry
    }
  }

  getLevel() {
    return 0
  }
}

export default class ProfileFakeOctree extends Potree.PointCloudTree {
  constructor(octree) {
    super()

    this.trueOctree = octree
    this.pcoGeometry = octree.pcoGeometry
    this.points = []
    this.visibleNodes = []

    this.material = new Potree.PointCloudMaterial()
    copyMaterial(this.trueOctree.material, this.material)
    this.material.pointSizeType = Potree.PointSizeType.FIXED

    this.batchSize = 100 * 1000
    this.currentBatch = null
  }

  getAttribute(name) {
    return this.trueOctree.getAttribute(name)
  }

  dispose() {
    for (const node of this.visibleNodes) {
      node.geometry.dispose()
    }

    this.visibleNodes = []
    this.currentBatch = null
    this.points = []
  }

  addPoints(data) {
    // since each call to addPoints can deliver very very few points,
    // we're going to batch them into larger buffers for efficiency.
    if (this.currentBatch === null) {
      this.currentBatch = this.createNewBatch(data)
    }

    if (!this.currentBatch) return

    this.points.push(data)

    let updateRange = {
      start: this.currentBatch.geometry.drawRange.count,
      count: 0
    }

    const projectedBox = new THREE.Box3()
    const truePos = new THREE.Vector3()

    for (let i = 0; i < data.numPoints; i++) {
      if (updateRange.start + updateRange.count >= this.batchSize) {
        // current batch full, start new batch

        for (const key of Object.keys(this.currentBatch.geometry.attributes)) {
          const attribute = this.currentBatch.geometry.attributes[key]
          attribute.updateRange.offset = updateRange.start
          attribute.updateRange.count = updateRange.count
          attribute.needsUpdate = true
        }

        this.currentBatch.geometry.computeBoundingBox()
        this.currentBatch.geometry.computeBoundingSphere()

        this.currentBatch = this.createNewBatch(data)
        updateRange = {
          start: 0,
          count: 0
        }
      }

      if (!this.currentBatch) continue

      truePos.set(
        data.data.position[3 * i + 0] + this.trueOctree.position.x,
        data.data.position[3 * i + 1] + this.trueOctree.position.y,
        data.data.position[3 * i + 2] + this.trueOctree.position.z
      )

      const x = data.data.mileage[i]
      const y = 0
      const z = truePos.z

      projectedBox.expandByPoint(new THREE.Vector3(x, y, z))

      const index = updateRange.start + updateRange.count
      const geometry = this.currentBatch.geometry

      for (const attributeName of Object.keys(data.data)) {
        const source = data.data[attributeName]
        const target = geometry.attributes[attributeName]
        const numElements = target.itemSize

        for (let item = 0; item < numElements; item++) {
          target.array[numElements * index + item] = source[numElements * i + item]
        }
      }

      {
        const position = geometry.attributes.position

        position.array[3 * index + 0] = x
        position.array[3 * index + 1] = y
        position.array[3 * index + 2] = z
      }

      updateRange.count++
      this.currentBatch.geometry.drawRange.count++
    }

    for (const key of Object.keys(this.currentBatch.geometry.attributes)) {
      const attribute = this.currentBatch.geometry.attributes[key]
      attribute.updateRange.offset = updateRange.start
      attribute.updateRange.count = updateRange.count
      attribute.needsUpdate = true
    }

    data.projectedBox = projectedBox

    this.projectedBox = this.points.reduce((a, i) => a.union(i.projectedBox), new THREE.Box3())
  }

  createNewBatch(data) {
    if (!data) return

    const geometry = new THREE.BufferGeometry()

    // create new batches with batch_size elements of the same type as the attribute
    for (const attributeName of Object.keys(data.data)) {
      const buffer = data.data[attributeName]
      const numElements = buffer.length / data.numPoints // 3 for pos, 4 for col, 1 for scalars
      const constructor = buffer.constructor
      let normalized = false

      if (this.trueOctree.root.sceneNode) {
        if (this.trueOctree.root.sceneNode.geometry.attributes[attributeName]) {
          normalized = this.trueOctree.root.sceneNode.geometry.attributes[attributeName].normalized
        }
      }

      const batchBuffer = new constructor(numElements * this.batchSize)

      const bufferAttribute = new THREE.BufferAttribute(batchBuffer, numElements, normalized)
      bufferAttribute.potree = {
        range: [0, 1]
      }

      geometry.setAttribute(attributeName, bufferAttribute)
    }

    geometry.drawRange.start = 0
    geometry.drawRange.count = 0

    const batch = new Batch(geometry, this.material)

    this.visibleNodes.push(batch)

    return batch
  }

  computeVisibilityTextureData() {
    const data = new Uint8Array(this.visibleNodes.length * 4)
    const offsets = new Map()

    for (let i = 0; i < this.visibleNodes.length; i++) {
      const node = this.visibleNodes[i]

      offsets[node] = i
    }

    return {
      data,
      offsets
    }
  }
}
