import { fetchWrapper } from '@/api/fetchApi'

export type Road = {
  id_indoroad: number
  id: number | null
  name: string
  account_number: number | null
  identification_number: number | null
}

export type QueueId = number

export type QueueImportTask = {
  id: QueueId
  task_object_id: number
  status: string
  info: object
}

export type ProcessedRoad = Road & {
  queue_data: QueueImportTask
}

export interface IRoadSearchFilter {
  name?: string
  account_number?: string
  identification_number?: string
}

export async function fetchRoads(filter: IRoadSearchFilter): Promise<Road[]> {
  const data = await fetchWrapper.post('/external/search/', filter)
  return data as Road[]
}

async function multiImportRoad(roadIds: number[]): Promise<QueueId[]> {
  if (!roadIds.length) {
    return []
  }

  const queueIds = (await fetchWrapper.post('/external/indorroads/multi_import_road', roadIds)) as {
    qid: QueueId
  }[]

  return queueIds.map((obj) => obj.qid)
}

async function multiUpdateRoad(roadIds: number[]): Promise<QueueId[]> {
  if (!roadIds.length) {
    return []
  }

  const queueIds = (await fetchWrapper.patch('/external/indorroads/multi_update', roadIds)) as {
    qid: QueueId
  }[]

  return queueIds.map((obj) => obj.qid)
}

export async function multiSubmit(roads: Road[]): Promise<ProcessedRoad[]> {
  const toImport = roads.filter((r) => !r.id) // нет в системе
  const toUpdate = roads.filter((r) => !!r.id) // есть в системе

  const importIds: QueueId[] = await multiImportRoad(toImport.map((r) => r.id_indoroad))
  const updateIds: QueueId[] = await multiUpdateRoad(toUpdate.map((r) => r.id) as number[])

  const processing: ProcessedRoad[] = []
  processing.push(
    ...toImport.map((r, idx) => {
      return { ...r, queue_data: { id: importIds[idx] } } as ProcessedRoad
    })
  )
  processing.push(
    ...toUpdate.map((r, idx) => {
      return { ...r, queue_data: { id: updateIds[idx] } } as ProcessedRoad
    })
  )

  return processing
}

export async function updateQueue(processedRoads: ProcessedRoad[]): Promise<ProcessedRoad[]> {
  const updatedRoads: ProcessedRoad[] = []

  for (const road of processedRoads) {
    if (road.queue_data.id !== null && road.queue_data.status != 'COMPLETED') {
      const queueData = (await fetchWrapper.get(`/queue/${road.queue_data.id}`)) as QueueImportTask
      updatedRoads.push({ ...road, queue_data: queueData })
    } else {
      updatedRoads.push(road)
    }
  }

  return updatedRoads
}
