import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

import { EgrnLayers } from '@/utils/lists'

import { MapRenderParams, RoadLayersState } from '@/types/Map'
import { fetchWrapper } from '@/api/fetchApi'
import { OrtoInfo } from '@/types/Road'

export const useRoadLayersStore = defineStore('road-layers', () => {
  const state: RoadLayersState = reactive({
    layers: [
      {
        id: EgrnLayers.LANDS,
        data: [],
        params: {
          showed: false,
          name: EgrnLayers.LANDS,
          label: 'Земельные участки'
        }
      },
      {
        id: EgrnLayers.BUILDS,
        data: [],
        params: {
          showed: false,
          name: EgrnLayers.BUILDS,
          label: 'Здания'
        }
      },
      {
        id: EgrnLayers.CONSTRUCTIONS,
        data: [],
        params: {
          showed: false,
          name: EgrnLayers.CONSTRUCTIONS,
          label: 'Сооружения'
        }
      }
    ]
  })

  const layers = computed(() => state.layers)
  const egrn = computed(() => state.layers.filter((el) => el.params.name.startsWith('egrn-custom')))
  const orto = computed(() => state.layers.filter((el) => el.params.name.startsWith('orto-')))
  const activeLayersCounter = computed(() => state.layers.filter((el) => el.params.showed).length)

  function toggleLayers(item: MapRenderParams, checked: boolean) {
    state.layers = state.layers.map((el) => {
      if (el.id === item.id) {
        return {
          ...el,
          params: Object.assign(el.params, { showed: checked })
        }
      }

      return el
    })
  }

  async function fetchOrto(roadId: number) {
    try {
      const data = await fetchWrapper.get(`/roads/roads/${roadId}/tails/`)

      state.layers.push(
        ...(data as OrtoInfo[]).map((el) => ({
          id: `orto-${el.name}`,
          data: [],
          params: {
            showed: false,
            name: `orto-${el.name}`,
            bbox_geojson: el.bbox_geojson,
            label: el.name,
            roadId
          }
        }))
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  function resetRoadLayer() {
    state.layers = state.layers.filter((el) => !el.params.name.startsWith('orto-'))

    state.layers.forEach((item) => {
      toggleLayers(item, false)
    })
  }

  return {
    layers,
    egrn,
    orto,
    activeLayersCounter,

    toggleLayers,
    fetchOrto,
    resetRoadLayer
  }
})
