const suffixes = ['-fill', '-symbol', '-circle', '-line']

function layerName(layers: string[]) {
  return layers
    .map((layer) => {
      return suffixes.map((suffix) => layer + suffix)
    })
    .flat()
}

export default function () {
  return {
    layerName
  }
}
