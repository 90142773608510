export enum RoadNavigation {
  MAIN = 'main',
  DIAGNOSTIC = 'diagnostic',
  DOCS = 'docs',
  GEOMETRY = 'geometry',
  INFO = 'info',
  REGULATORY = 'regulatory-docs',
  ADDITIONAL = 'additional',
  LOCATION = 'location',
  PARAMS = 'params',
  ROADWAY = 'roadway',
  CONTIGUITY = 'contiguity',
  ARRANGEMENT = 'arrangement',
  SERVICE = 'service',
  STATE = 'state',
  THREED = '3d'
}
