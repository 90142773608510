import { reactive, computed, shallowRef, defineComponent, h } from 'vue'
import { defineStore } from 'pinia'

import { IRoadWidgets, RoadResponse } from '@/types/Road'
import { useRoadStore } from '@/stores'
import { useRoadWayStore } from '@/stores/road/road-way'

import IconBase from '@/components/icons/IconBase.vue'
import RoadSquare from '@/components/icons/RoadSquare.vue'
import FromToLine from '@/components/icons/FromToLine.vue'

enum RoadWidgetsNames {
  LENGTH = 'Протяженность, км',
  SQUARE = 'Площадь покрытия проезжей части, м2',
  ROAD = 'Число полос движения, шт.',
  WIDTH = 'Ширина проезжей части, м',
  ABILITY = 'Макс. пропускная способность, авт/сут',
  SPEED = 'Максимальная разрешенная скорость, км/ч'
}

const useRoadWidgetsStore = defineStore('road-widgets-store', () => {
  const roadStore = useRoadStore()
  const roadWay = useRoadWayStore()

  const state: IRoadWidgets = reactive({
    road: computed(() => roadStore.getRoad),
    widgets: {
      length: [
        {
          name: RoadWidgetsNames.LENGTH,
          value: computed(() => (state.road?.length ? state.road?.length / 1000 : null)),
          icon: shallowRef(FromToLine)
        }
      ],
      square: [
        {
          name: RoadWidgetsNames.SQUARE,
          value: computed(() => roadWay.square),
          icon: shallowRef(
            defineComponent({
              render() {
                return h(
                  IconBase,
                  { width: 181, height: 36, viewBox: '0 0 181 36' },
                  () => RoadSquare.render?.()
                )
              }
            })
          )
        }
      ],
      road: [
        {
          name: RoadWidgetsNames.ROAD,
          value: computed(() => roadWay.lanes),
          icon: undefined
        }
      ],
      width: [
        {
          name: RoadWidgetsNames.WIDTH,
          value: computed(() => roadWay.widthMinMax),
          icon: undefined
        }
      ],
      ability: [
        {
          name: RoadWidgetsNames.ABILITY,
          value: computed(() => state.road?.throughput),
          icon: undefined
        }
      ],
      speed: [
        {
          name: RoadWidgetsNames.SPEED,
          value: computed(() => state.road?.top_speed),
          icon: undefined
        }
      ]
    }
  })

  const getWidgets = computed(() => state.widgets)

  function setRoad(road: RoadResponse) {
    state.road = road
  }

  return {
    getWidgets,
    setRoad
  }
})

export default useRoadWidgetsStore
