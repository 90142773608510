<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.0091 31.9191C17.5121 26.9413 21.4258 24.6154 24.8055 24.6154C28.1853 24.6154 32.099 26.9413 32.602 31.9191C32.7139 33.0269 31.8013 33.8538 30.8055 33.8538H18.8055C17.8098 33.8538 16.8972 33.0269 17.0091 31.9191ZM24.8055 26.3154C22.1557 26.3154 19.1021 28.1156 18.7005 32.09C18.6996 32.0982 18.7011 32.1022 18.7011 32.1022C18.7019 32.1044 18.7047 32.1105 18.7125 32.1186C18.7294 32.136 18.7614 32.1538 18.8055 32.1538H30.8055C30.8497 32.1538 30.8817 32.136 30.8985 32.1186C30.9064 32.1105 30.9091 32.1045 30.9099 32.1023C30.9099 32.1023 30.9114 32.0985 30.9106 32.09C30.509 28.1156 27.4554 26.3154 24.8055 26.3154Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24.8055 17.7C23.7322 17.7 22.694 18.7155 22.694 20.1885C22.694 21.6614 23.7322 22.6769 24.8055 22.6769C25.8788 22.6769 26.9171 21.6614 26.9171 20.1885C26.9171 18.7155 25.8788 17.7 24.8055 17.7ZM20.994 20.1885C20.994 17.9738 22.6076 16 24.8055 16C27.0034 16 28.6171 17.9738 28.6171 20.1885C28.6171 22.4031 27.0034 24.3769 24.8055 24.3769C22.6076 24.3769 20.994 22.4031 20.994 20.1885Z"
    fill="currentFill"
  />
</template>
