import type { LayerParams } from '@/types/Map'
import type { Map } from 'maplibre-gl'

export default function (map: Map, params: LayerParams) {
  if (map.getLayer(`${params.name}-fill`)) return

  map.addLayer({
    id: `${params.name}-fill`,
    source: params.name,
    type: 'fill',
    layout: {
      visibility: params.showed ? 'visible' : 'none'
    },
    paint: {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        params.activeColor || params.color || 'red',
        ['boolean', ['feature-state', 'hover'], false],
        params.hoveredColor || params.color || 'red',
        params.color || 'red'
      ],
      'fill-opacity': params.opacity || 1
    }
  })
}
