<script setup lang="ts">
import { computed } from 'vue'

import { useDefectChartStore } from '@/stores/road/defects/road-defects-chart'
import { useDefectsStore } from '@/stores/road/road-defects'
import { useChartStore } from '@/stores/chart'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter.ts'
import { useRoadDefectsMapStore } from '@/stores/road/defects/road-defects-map'
import { useRoadStore } from '@/stores/road/road'

import useChartDefectsHighlight from '@/composition/defects/useChartDefectsHighlight'
import useChartDefects from '@/composition/defects/useChartDefects'
import useMapDefects from '@/composition/defects/useMapDefects'

import AppChart from '@/components/ui/AppChart.vue'

import type { ECElementEvent, SeriesOption } from 'echarts'
import { ChartMounted } from '@/types/Chart'

const emits = defineEmits(['chart-clicked', 'mounted'])

const defectFilterStore = useDefectFilterStore()
const chartDefectsStore = useDefectChartStore()
const defectsStore = useDefectsStore()
const chartStore = useChartStore()
const roadDefectsMapStore = useRoadDefectsMapStore()
const roadStore = useRoadStore()

const { toggleSelectChartLegend, defectLegends, fromChartPointToSeriesItem, defectToChartData } =
  useChartDefects()
const { chartDefectClick } = useChartDefectsHighlight()
const { toggleVisibleDefectMap } = useMapDefects()

const chartData = computed(() => chartDefectsStore.chartData)
const defectsColor = computed(() => defectFilterStore.defectsColor.map((color) => color.value))
const series = computed(() => chartDefectsStore.series)
const defectTypes = computed(() => defectFilterStore.defectTypes)
const style = computed(() => defectFilterStore.style)
const currentDefect = computed(() => defectsStore.currentDefect)
const legendItems = computed(() => defectLegends(defectTypes, style))
const selected = computed(() => chartDefectsStore.selectedItems)
const defects = computed(() => defectsStore.defects)
const renderData = computed(() => roadStore.renderData)

function zoom() {
  chartDefectsStore.setSelected([...chartDefectsStore.selectedItems])
}

function updateData() {
  const chart = chartStore.getChartInstance()
  if (!currentDefect.value || !chart) return

  const chartDataPoints = currentDefect.value.map((el) => defectToChartData(el))
  const { series } = chart.getOption()

  const selectedItems = chartDataPoints.map((el) =>
    fromChartPointToSeriesItem(el, series as SeriesOption[])
  )

  chartDefectsStore.setSelected(selectedItems)

  const map = roadDefectsMapStore.getMapInstance()
  if (!chart || !map) return

  toggleSelectChartLegend(defectTypes.value, chart)

  const params = {
    visible: defectTypes.value,
    defects: defects.value,
    map,
    renderData: renderData.value
  }

  toggleVisibleDefectMap(params)
}

function click(e: Partial<ECElementEvent>) {
  const [selectedItems, defect] = chartDefectClick(e)

  chartDefectsStore.setSelected(selectedItems)
  defectsStore.setCurrentDefect(defect)

  emits('chart-clicked', e)
}

function mounted(e: ChartMounted) {
  emits('mounted', e)
}
</script>

<template>
  <div>
    <AppChart
      id="chart__wrapper-photo"
      :legends="true"
      class="defects__chart"
      :selected="selected"
      :data="chartData"
      type="scatter"
      :width="'auto'"
      :height="620"
      :padding="45"
      :metric="{ x: 'м', y: 'м²' }"
      :left="55"
      :bottom="0"
      :colors="defectsColor"
      :series="series"
      :data-zoom="{
        start: 0,
        end: 100,
        height: 35
      }"
      :legend-items="legendItems"
      :chart-style="style"
      :graphic="{
        type: 'circle',
        'elements-rect': {
          progressive: true
        }
      }"
      highlight-color="#03ffd1"
      @mounted="mounted"
      @chart-clicked="click"
      @chart-zoomed="zoom"
      @chart-data-updated="updateData"
    />
  </div>
</template>

<style lang="scss" scoped></style>
