import { defineStore } from 'pinia'
import { ref } from 'vue'

import { IToasts } from '@/types/Toast'

interface TToast {
  id: number
  type: 'success' | 'error' | 'info' | 'warning'
  title: string
  text: string
  duration?: number
}

export const useToastStore = defineStore('toasts', () => {
  // state
  const toasts = ref<TToast[]>([])

  // getters
  const _getIndexToastById = (id: number): number => {
    return toasts.value.findIndex((item) => item.id === id)
  }

  //actions
  const _create = (type: string) => {
    return (title: string, text: string, duration?: number) => {
      toasts.value.push({
        id: new Date().getTime(),
        type,
        title,
        text,
        duration
      } as TToast)
    }
  }

  const toast: IToasts = {
    success: _create('success'),
    info: _create('info'),
    warning: _create('warning'),
    error: _create('error')
  }

  const deleteToast = (id: number): void => {
    const idx = _getIndexToastById(id)
    if (idx === -1) return
    toasts.value.splice(idx, 1)
  }

  return {
    // state
    toasts,
    toast,

    // action
    deleteToast
  }
})
