import { ceil } from "lodash"
import { Road } from "./importPage"

export class ImportPaginator {
  elementsOnPage: number

  pages: number = 1
  currPage: number = 1
  
  _data: Road[]

  constructor(data: Road[] = [], elementsOnPage: number = 20) {
    this.elementsOnPage = elementsOnPage
    this._data = data

    this.pages = ceil(this._data.length / this.elementsOnPage)
  }

  changePage(n: number) {
    this.currPage = n
  }

  setData(data: Road[]) {
    this._data = data
    this.pages = ceil(this._data.length / this.elementsOnPage)
    this.currPage = 1
  }

  get pagination() {
    return this._data.slice(
      (this.currPage - 1) * this.elementsOnPage,
      this.currPage * this.elementsOnPage
    )
  }
}