<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import * as echarts from 'echarts'

import { useDefectsStore, useRoadDiagnosticStore, useRoadStore } from '@/stores'
import useDefects from '@/composition/defects/useDefects'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import type {
  ILegendStyle,
  IlabelStyle,
  IChartOption,
  IChartPieData,
  IChartCandlestickData,
  XYdata,
  ISeries
} from '@/types/RoadRating'

import { Breadcrumb } from '@/utils/breadcrumbs'
import { TBreadcrumb } from '@/types/Breadcrumb'
import { uniqFieldsArray } from '@/utils/uniq-fields-array'
import useUnits from '@/composition/useUnits'

const emits = defineEmits(['setBr'])

const route = useRoute()

const defectFilterStore = useDefectFilterStore()
const defectStore = useDefectsStore()
const roadDiagnosticStore = useRoadDiagnosticStore()
const roadStore = useRoadStore()

const { defectsToInfo, updateDefectInfo } = useDefects()
const { fromSmToSqM } = useUnits()

const road = computed(() => roadStore.getRoad)
const defects = computed(() => defectStore.defects)
const defectTypes = computed(() => defectFilterStore.defectTypes)
const rating = computed(() => roadStore.rating)
const roadDefectRating = computed(() => roadDiagnosticStore.getAvgIri)
const iriChart = computed(() => roadDiagnosticStore.getAvgIriChart)
const limitPosition = computed(() => `${iriChart.value?.at(0)}%`)
const avgPsoition = computed(() => `${iriChart.value?.at(1)}%`)
const limitPositionEnd = computed(() => `${(iriChart.value?.at(0) as number) + 8}%`)

const maskGradientWidth = computed(() => {
  // отнимаем от 100% ширину, если качество дороги 0, то отнимаем 99 процентов для того что градиент не был пустой
  return `${100 - rating.value || 99}%`
})

const colorStyle = ['#3366CC', '#B82E2E', '#4B0092', '#FF9900', '#0099C6', '#66AA00', '#DD4477']

const legendStyle: ILegendStyle = {
  orient: 'horizontal',
  left: 'center',
  textStyle: {
    color: '#686868',
    fontFamily: 'Bender',
    fontSize: 18,
    fontWeight: 'bold'
  }
}

const labelStyle: IlabelStyle = {
  show: true,
  color: '#686868',
  fontFamily: 'Bender',
  fontSize: 18,
  fontWeight: 'bold'
}

/** Pie Simple */
const optionPie: IChartOption<IChartPieData[]> = {
  tooltip: {
    trigger: 'item'
  },
  color: colorStyle,
  legend: legendStyle,
  dataset: [{ source: [] }],
  series: [
    {
      type: 'pie',
      radius: '70%',
      label: labelStyle
    },
    {
      name: 'Количество',
      type: 'pie',
      radius: '70%',
      label: { ...labelStyle, position: 'inside', formatter: '{d}%', color: 'white' },
      emphasis: {
        label: { show: true },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}

/** Stack Horizontal Bar */
const optionStack: IChartOption<number[]> = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  color: colorStyle,
  legend: legendStyle,
  grid: {
    left: '6%',
    right: '3%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value'
  },
  yAxis: {
    type: 'category',
    // тут полосы движения
    data: [] as XYdata[]
  },
  series: [] // тут типы дефекта и количество [выбоины, трещины]
}

/** Basic Candlestick */
const optionCandlestick: IChartOption<IChartCandlestickData[]> = {
  tooltip: {
    trigger: 'item'
  },
  color: colorStyle,
  xAxis: {
    // тут полосы движения
    data: [] as XYdata[]
  },
  yAxis: {
    name: 'м²'
  },
  series: [
    {
      type: 'candlestick',
      // тут типы дефекта и количество [выбоины, трещины]
      data: []
    }
  ]
}

const chartPieRef = ref(null)
const chartStackRef = ref(null)
const chartCandlestickRef = ref(null)

const setChartPie = () => {
  if (!defects.value?.length) return

  const chartPie = echarts.init(chartPieRef.value, null, {
    renderer: 'canvas',
    useDirtyRect: false
  })

  const counters: { [x: string]: number } = {}

  const infos = defectsToInfo(defects.value)

  defectTypes.value.forEach((item) => {
    if (typeof item.label === 'string') {
      if (!counters[item.label]) counters[item.label] = 0

      counters[item.label] = infos.filter((el) => el.label === item.label).length
    }
  })

  defectTypes.value.forEach((type) => {
    if (typeof type.label === 'string') {
      const newItem = {
        value: counters[type.label] ?? 0,
        name: type.label
      }

      optionPie.dataset![0].source.push(newItem)
    }
  })

  chartPie.setOption(optionPie as unknown as echarts.EChartsCoreOption)

  window.addEventListener('resize', () => chartPie.resize)
}

const setChartStack = () => {
  if (!defects.value?.length) return

  const chartStack = echarts.init(chartStackRef.value, null, {
    renderer: 'canvas',
    useDirtyRect: false
  })

  const infos = defectsToInfo(updateDefectInfo(defects.value, +route.params.id))
  const lines = uniqFieldsArray(defects.value, 'line_number')

  lines.forEach((line, index) => {
    const newYAxisData = {
      value: `Полоса ${index + 1}`,
      textStyle: labelStyle
    }

    optionStack.yAxis!.data!.push(newYAxisData)

    defectTypes.value.forEach((el) => {
      const { label } = el

      const dataItem = optionStack.series.find((item) => item.name === label)

      if (!dataItem) {
        const newItem = {
          name: label,
          type: 'bar',
          stack: 'total',
          label: { ...labelStyle, color: 'white' },
          emphasis: {
            focus: 'series'
          },
          data: [infos.filter((el) => el.label === label && line === el.line_number).length]
        } as unknown as ISeries<number[]>

        optionStack.series.push(newItem)

        return
      }

      dataItem.data!.push(
        infos.filter((el) => el.label === label && line === el.line_number).length
      )
    })
  })

  optionStack.yAxis!.data!.reverse()
  chartStack.setOption(optionStack as unknown as echarts.EChartsCoreOption)

  window.addEventListener('resize', () => chartStack.resize)
}

const setChartCandlestick = () => {
  if (!defects.value?.length) return

  const chartCandlestick = echarts.init(chartCandlestickRef.value, null, {
    renderer: 'canvas',
    useDirtyRect: false
  })

  const infos = defectsToInfo(updateDefectInfo(defects.value, +route.params.id))
  const lines = uniqFieldsArray(defects.value, 'line_number')

  lines.forEach((line, index) => {
    defectTypes.value.forEach((el, counter) => {
      const { label } = el

      if (!optionStack.series || !Array.isArray(optionStack.series)) return

      const newXAxisData = {
        value: `П-${index + 1} ${typeof label === 'string' ? label?.slice(0, 4) : label}`,
        textStyle: {
          ...labelStyle,
          fontSize: 12
        } as unknown as IlabelStyle
      }

      optionCandlestick.xAxis!.data!.push(newXAxisData)

      const sumSquareList = infos
        .filter((el) => el.line_number === line && el.label === label)
        .map((item) => fromSmToSqM(item.sum_square))
        .sort((a, b) => a - b)
      const middle = sumSquareList.length / 2
      const minMedian = Math.round(middle / 2)
      const maxMedian = Math.round((sumSquareList.length - middle) / 2 + middle)

      const tooltipElement = (label: string, value: number | undefined) => {
        return `<div style="display:flex">
                <span>${label}:</span>
                <span style="margin-left:auto">&nbsp;${
                  value ? value.toFixed(2) : 'Нет данных'
                }</span>
              </div>`
      }

      const newItem = {
        value: [
          sumSquareList.at(minMedian),
          sumSquareList.at(maxMedian),
          sumSquareList.at(0),
          sumSquareList.at(-1)
        ],
        itemStyle: {
          color: colorStyle[counter],
          borderColor: colorStyle[counter]
        },
        tooltip: {
          formatter: [
            `<div style="display:flex;margin-bottom:3px">
                  <span>${index + 1}&nbsp;${label}</span>
                  <span style="margin:-6px -4px 0 auto;">см<sup>2</sup></span>
                </div>`,
            tooltipElement('Наименьшая площадь', sumSquareList.at(0)),
            tooltipElement('Наибольшая площадь', sumSquareList.at(-1)),
            tooltipElement('Медиана по наименьшей', sumSquareList.at(minMedian)),
            tooltipElement('Медиана по наибольшей', sumSquareList.at(maxMedian))
          ].join('')
        }
      } as IChartCandlestickData

      optionCandlestick.series[0].data!.push(newItem)
    })
  })

  chartCandlestick.setOption(optionCandlestick as unknown as echarts.EChartsCoreOption)
  window.addEventListener('resize', () => chartCandlestick.resize)
}

onBeforeMount(() => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Общая оценка', null))
})

onMounted(() => {
  setChartPie()
  setChartStack()
  setChartCandlestick()
})
</script>

<template>
  <div class="app-road-rating">
    <div v-if="defects?.length">
      <h2>Общая оценка</h2>
      <div class="app-road-rating__wrapper">
        <div class="analytics-item">
          <h3 class="analytics-item__title">Итоговая оценка качества дороги</h3>
          <div class="analytics-item__line">
            <div class="analytics-item__line-value" />
          </div>
          <span class="analytics-item__value">{{ rating }}%</span>
        </div>
        <div class="analytics-item">
          <h3 class="analytics-item__title">Среднее значение IRI</h3>
          <div class="analytics-item__line-iri">
            <div v-if="road?.has_iri" class="analytics-item__line-iri-limit">
              <div class="analytics-item__line-iri-value"></div>
            </div>
          </div>
          <span class="analytics-item__value" :class="{ empty: !road?.has_iri }">{{
            road?.has_iri ? roadDefectRating : 'Нет данных'
          }}</span>
        </div>

        <div class="chart-item">
          <h3>Общее количество и структура дефектов</h3>
          <div ref="chartPieRef" class="chart-item__chart" />
        </div>
        <div class="chart-item">
          <h3>Структура дефектов полос дорожного движения</h3>
          <div ref="chartStackRef" class="chart-item__chart" />
        </div>
        <div class="chart-item">
          <h3>Средняя площадь и вариативность дефектов</h3>
          <div ref="chartCandlestickRef" class="chart-item__chart" />
        </div>
      </div>
    </div>
    <h3 v-else class="no-data-text">Нет данных</h3>
  </div>
</template>

<style lang="scss" scoped>
.app-road-rating {
  * {
    transition: 0.3s ease;
    transition-property: color, background;

    @include color('fontColor');
  }

  margin-bottom: 40px;

  & > h2 {
    margin-bottom: 40px;
  }

  & h3 {
    margin-bottom: 30px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 60px;

    & > div {
      padding: 30px;

      @include background-color('widgetBG');
    }

    @media (min-width: calc(1840px + 1px)) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.analytics-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $f-family-base;
  }

  &__line {
    width: max(400px);
    height: 36px;
    margin: 34px 0 44px;
    padding: 4px;
    border: 1px solid $c-gray-40;

    &-value {
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, red, yellow, green);
      mask: linear-gradient(to left, transparent v-bind(maskGradientWidth), black 0);
    }
  }

  &__line-iri {
    position: relative;
    width: max(400px);
    height: 36px;
    margin: 34px 0 44px;
    border: 1px solid $c-gray-40;

    &-limit {
      position: absolute;
      inset: 4px;
      overflow: hidden;
      background: linear-gradient(
        to right,

        #7ed900 v-bind(limitPosition),
        #ff3200 v-bind(limitPositionEnd)
      );
    }

    &-value {
      position: absolute;
      top: 0;
      left: v-bind(avgPsoition);
      width: 5px;
      height: 30px;
      background-color: $c-white;
    }
  }

  &__icon {
    margin: 16px 0 30px;
  }

  &__value {
    font-size: 1.2rem;
    font-weight: bold;

    &.empty {
      color: $c-gray-65;
    }
  }
}

.chart-item {
  display: flex;
  flex-direction: column;
  width: max(100%, 705px);
  height: 680px;

  &__chart {
    flex: 1 1 auto;
  }
}
</style>
