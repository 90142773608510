export enum SignGost {
  RAILWAY_CROSSING_WITH_BARRIER = '1.1',
  RAILWAY_CROSSING_WITHOUT_BARRIER = '1.2',
  ONE_WAY_RAILWAY = '1.3.1',
  MANY_WAY_RAILWAY = '1.3.2',
  THREE_X_DISTANCE_RAILWAY = '1.4.1',
  TWO_X_DISTANCE_RAILWAY = '1.4.2',
  DISTANCE_RAILWAY = '1.4.3',
  THREE_X_DISTANCE_RAILWAY_2 = '1.4.4',
  TWO_X_DISTANCE_RAILWAY_2 = '1.4.5',
  DISTANCE_RAILWAY_2 = '1.4.6',
  INTERSECTION_WITH_TRAM_FABRIC = '1.5',
  INTERSECTION_OF_EQUIVALENT_ROADS = '1.6',
  ROUNDABOUT_INTERSECTION = '1.7',
  TRAFFIC_LIGHT_REGULATION = '1.8',
  DRAWBRIDGE = '1.9',
  DEPARTURE_TO_THE_EMBANKMENT = '1.10',
  DANGEROUS_TURN_RIGHT = '1.11.1',
  DANGEROUS_TURN_LEFT = '1.11.2',
  DANGEROUS_TURNS_RIGHT = '1.12.1',
  DANGEROUS_TURNS_LEFT = '1.12.2',
  STEEP_DESCENT = '1.13',
  THE_STEEP_CLIMB = '1.14',
  SLIPPERY_ROAD = '1.15',
  ROUGH_ROAD = '1.16',
  ARTIFICIAL_UNEVENNESS = '1.17',
  GRAVEL_RELEASE = '1.18',
  DANGEROUS_ROADSIDE = '1.19',
  ROAD_NARROWS_BOTH = '1.20.1',
  ROAD_NARROWS_RIGHT = '1.20.2',
  ROAD_NARROWS_LEFT = '1.20.3',
  TWO_WAY_TRAFFIC = '1.21',
  PRE_CROSSWALK = '1.22',
  CHILDRENS = '1.23',
  INTERSECTION_WITH_BYCICLE = '1.24',
  ROAD_WORK = '1.25',
  CATTLE_DRIVE = '1.26',
  WILD_ANIMALS = '1.27',
  FALLING_STONES = '1.28',
  SIDE_WIND = '1.29',
  LOW_FLYING_AIRCRAFT = '1.30',
  TUNNEL = '1.31',
  CONGESTION = '1.32',
  OTHER_HAZARDS = '1.33',
  TURNING_DIRECTION_RIGHT = '1.34.1',
  TURNING_DIRECTION_LEFT = '1.34.2',
  TURNING_DIRECTION_T = '1.34.3',
  CROSSROADS_SECTION = '1.35',
  MAIN_ROAD = '2.1',
  MAIN_ROAD_END = '2.2',
  INTERSECTION_WITH_A_SECONDARY_ROAD = '2.3.1',
  JUNCTION_WITH_A_SECONDARY_ROAD_RIGHT = '2.3.2',
  JUNCTION_WITH_A_SECONDARY_ROAD_LEFT = '2.3.3',
  JUNCTION_WITH_A_SECONDARY_ROAD_TOP_RIGHT = '2.3.4',
  JUNCTION_WITH_A_SECONDARY_ROAD_TOP_LEFT = '2.3.5',
  JUNCTION_WITH_A_SECONDARY_ROAD_BOTTOM_RIGHT = '2.3.6',
  JUNCTION_WITH_A_SECONDARY_ROAD_BOTTOM_LEFT = '2.3.7',
  GIVE_WAY = '2.4',
  DRIVING_WITHOUT_STOPPING_IS_PROHIBITED = '2.5',
  THE_ADVANTAGE_OF_ONCOMIMG_TRAFFIC = '2.6',
  ADVANTAGE_OVER_ONCOMING_TRAFFIC = '2.7',
  NO_ENTRY = '3.1',
  MOVEMENT_PROHIBITION = '3.2',
  MOVEMENT_OF_MOTOR_VIHICLES_IS_PROHIBITED = '3.3',
  TRUCK_TRAFFIC_IS_PROHIBITED = '3.4',
  MOTORCYCLES_ARE_PROHIBITED = '3.5',
  TRACTOR_TRAFFIC_IS_PROHIBITED = '3.6',
  DRIVING_WITH_A_TRAILER_IS_PROHIBITED = '3.7',
  HORSE_DRAWN_CARRIAGES_ARE_PROHIBITED = '3.8',
  BICYCLES_ARE_PROHIBITED = '3.9',
  NO_PEDESTRIANS = '3.10',
  WEIGHT_LIMITATION = '3.11',
  LIMITATION_OF_MASS_PER_AXLE_OF_VEHICLES = '3.12',
  HEIGHT_LIMITATION = '3.13',
  WIDTH_LIMITATION = '3.14',
  LENGTH_LIMITATION = '3.15',
  MINIMUM_DISTANCE_LIMITATION = '3.16',
  CUSTOM = '3.17.1',
  DANGEROUS = '3.17.2',
  CONTROL = '3.17.3',
  TURNING_TO_THE_RIGHT_IS_PROHIBITED = '3.18.1',
  TURNING_TO_THE_LEFT_IS_PROHIBITED = '3.18.2',
  NO_TURNING = '3.19',
  OVERTAKING_IS_FORBIDDEN = '3.20',
  END_OF_THE_NO_OVERTAKING_ZONE = '3.21',
  OVERTAKING_BY_A_TRUCK_IS_PROHIBITED = '3.22',
  END_OF_THE_NO_OVERTAKING_ZONE_FOR_TRUCKS = '3.23',
  MAXIMUM_SPEED_LIMIT = '3.24',
  END_OF_MAXIMUM_SPEED_LIMIT_ZONE = '3.25',
  SOUND_SIGNAL_IS_PROHIBITED = '3.26',
  STOPPING_IS_PROHIBITED = '3.27',
  NO_PARKING = '3.28',
  PARKING_IS_PROHIBITED_ON_ODD_DAYS_OF_THE_MONTH = '3.29',
  PARKING_IS_PROHIBITED_ON_EVEN_DAYS_OF_THE_MONTH = '3.30',
  THE_END_OF_THE_ZONE_OF_ALL_RESTRICTIONS = '3.31',
  THE_MOVEMENT_OF_VEHICLES_WITH_DANGEROUS_GOODS_IS_PROHIBITED = '3.32',
  THE_MOVEMENT_OF_VEHICLES_WITH_EXPLOSIVE_AND_FLAMMABLE_CARGO_IS_PROHIBITED = '3.33',
  BUS_TRAFFIC_IS_PROHIBITTED = '3.34',
  DRIVING_ON_PERSONAL_MOBILITY_DEVICES_IS_PROHIBITED = '3.35',
  GO_STRAIGHT = '4.1.1',
  MOVEMENT_TO_THE_RIGHT = '4.1.2',
  MOVEMENT_TO_THE_LEFT = '4.1.3',
  DRIVING_STRAIGHT_OR_RIGHT = '4.1.4',
  DRIVING_STRAIGHT_OR_LEFT = '4.1.5',
  MOVING_RIGHT_OR_LEFT = '4.1.6',
  AVODING_OBSTACLES_ON_THE_RIGHT = '4.2.1',
  AVODING_OBSTACLES_ON_THE_LEFT = '4.2.2',
  AVODING_OBSTACLES_ON_THE_RIGHT_OR_LEFT = '4.2.3',
  ROUNDABOUT_CIRCULATION = '4.3',
  BIKE_LAKE = '4.4.1',
  END_OF_THE_BIKE_PATH = '4.4.2',
  FOOTPATH = '4.5.1',
  PEDESTRIAN_AND_BICYCLE_COMBINED = '4.5.2',
  END_PEDESTRIAN_AND_BICYCLE_COMBINED = '4.5.3',
  PEDESTRIAN_AND_BICYCLE_SEPARATION_LEFT = '4.5.4',
  PEDESTRIAN_AND_BICYCLE_SEPARATION_RIGHT = '4.5.5',
  END_PEDESTRIAN_AND_BICYCLE_SEPARATION_LEFT = '4.5.6',
  END_PEDESTRIAN_AND_BICYCLE_SEPARATION_RIGHT = '4.5.7',
  MINIMUM_SPEED_LIMIT = '4.6',
  END_MINIMUM_SPEED_LIMIT = '4.7',
  DIRECTION_STRAIGHT_WHITH_DANGEROUS_GOODS = '4.8.1',
  DIRECTION_RIGHT_WHITH_DANGEROUS_GOODS = '4.8.2',
  DIRECTION_LEFT_WHITH_DANGEROUS_GOODS = '4.8.3',
  MOTORWAY = '5.1',
  END_MOTORWAY = '5.2',
  ROAD_FOR_CARS = '5.3',
  END_ROAD_FOR_CARS = '5.4',
  ONE_WAY_ROAD = '5.5',
  END_ONE_WAY_ROAD = '5.6',
  ENTERING_A_ONE_WAY_ROAD_RIGHT = '5.7.1',
  ENTERING_A_ONE_WAY_ROAD_LEFT = '5.7.2',
  REVERSE_MOVEMENT = '5.8',
  END_REVERSE_MOVEMENT = '5.9',
  ENTERING_WITH_REVERSE_TRAFFIC = '5.10',
  ROAD_WITH_LINE_ROUTE = '5.11.1',
  ROAD_WITH_LINE_BICYCLE = '5.11.2',
  END_ROAD_WITH_LINE_ROUTE = '5.12.1',
  END_ROAD_WITH_LINE_BICYCLE = '5.12.2',
  ENTERING_ROAD_WITH_LINE_ROUTE_LEFT = '5.13.1',
  ENTERING_ROAD_WITH_LINE_ROUTE_RIGHT = '5.13.2',
  ENTERING_ROAD_WITH_LANE_CYCLISTS_LEFT = '5.13.3',
  ENTERING_ROAD_WITH_LANE_CYCLISTS_RIGHT = '5.13.4',
  LINE_FOR_ROUTE_VIHICLES = '5.14.1',
  CYCLIST_LANE = '5.14.2',
  END_LINE_FOR_ROUTE_VIHICLES = '5.14.3',
  END_CYCLIST_LANE = '5.14.4',
  LANE_DIRECTIONS = '5.15.1',
  LANE_DIRECTIONS_2 = '5.15.2',
  START_OF_THE_STRIP_RIGHT = '5.15.3',
  START_OF_THE_STRIP_LEFT = '5.15.4',
  END_OF_THE_STRIP_RIGHT = '5.15.5',
  END_OF_THE_STRIP_LEFT = '5.15.6',
  LANE_DIRECTIONS_3 = '5.15.7',
  NUMBER_OF_LANES = '5.15.8',
  BUS_STOP_LOCATION = '5.16',
  TRUM_STOP_LOCATION = '5.17',
  TAXI_PARKING_AREA = '5.18',
  CROSSWALK_LEFT = '5.19.1',
  CROSSWALK_RIGHT = '5.19.2',
  ARTIFICIAL_HUMP = '5.20',
  LIVING_SECTOR = '5.21',
  END_LIVING_SECTOR = '5.22',
  START_LOCALITY = '5.23.1',
  START_LOCALITY_UNKNOWN = '5.23.2',
  END_LOCALITY = '5.24.1',
  END_LOCALITY_UNKNOWN = '5.24.2',
  BEGINNING_SETTLEMENT = '5.25',
  END_SETTLEMENT = '5.26',
  RESTRICTED_PARKING_ZONE = '5.27',
  END_RESTRICTED_PARKING_ZONE = '5.28',
  REGULATED_PARKING_AREA = '5.29',
  END_REGULATED_PARKING_AREA = '5.30',
  MAXIMUM_SPEED_ZONE = '5.31',
  END_MAXIMUM_SPEED_ZONE = '5.32',
  PEDESTRIAN_ZONE = '5.33',
  END_PEDESTRIAN_ZONE = '5.34',
  ZONE_RESTRICTIONS_ENVIRONMENTAL_MOTOR = '5.35',
  END_ZONE_RESTRICTIONS_ENVIRONMENTAL_MOTOR = '5.36',
  ZONE_LIMITED_ENVIRONMENTAL_BY_TYPE = '5.37',
  END_ZONE_LIMITED_ENVIRONMENTAL_BY_TYPE = '5.38',
  BICYCLE_ZONE = '5.39',
  END_BICYCLE_ZONE = '5.40',
  GENERAL_MAXIMUM_SPEED_LIMITS = '6.1',
  RECOMMENDED_SPEED = '6.2',
  TURNING_SPACE = '6.3.1',
  TURNING_AREA = '6.3.2',
  PARKING = '6.4',
  EMERGENCY_STOP_STRIP = '6.5',
  UNDEGROUND_PEDESTRIAN_CROSSING = '6.6',
  OVERHEAD_PEDESTRIAN_CROSSING = '6.7',
  DEAD_END_RIGHT = '6.8.1',
  DEAD_END_ON_THE_RIGHT = '6.8.2',
  DEAD_END_ON_THE_LEFT = '6.8.3',
  ADVANCE_DIRECTION_INDICATOR = '6.9.1',
  ADVANCE_DIRECTION_INDICATOR_2 = '6.9.2',
  TRAFFIC_PATTERN = '6.9.3',
  DIRECTIONS_INDICATOR = '6.10.1',
  DIRECTIONS_INDICATOR_2 = '6.10.2',
  OBJECT_NAME = '6.11',
  DISTANCE_INDICATOR = '6.12',
  KILOMETER_SIGN = '6.13',
  ROUTE_NUMBER = '6.14.1',
  ROUTE_NUMBER_2 = '6.14.2',
  DRIVING_DIRECTION_FOR_TRUCKS_STRAIGHT = '6.15.1',
  DRIVING_DIRECTION_FOR_TRUCKS_RIGHT = '6.15.2',
  DRIVING_DIRECTION_FOR_TRUCKS_LEFT = '6.15.3',
  STOP_LINE = '6.16',
  DETOUR_SCHEME = '6.17',
  DETOUR_DIRECTION_STRAIGHT = '6.18.1',
  DETOUR_DIRECTION_RIGHT = '6.18.2',
  DETOUR_DIRECTION_LEFT = '6.18.3',
  PRELIMINARY_INDICTOR_FOR_CHANGING_LINES = '6.19.1',
  PRELIMINARY_INDICTOR_FOR_CHANGING_LINES_2 = '6.19.2',
  EMERGENCY_EXIT_LEFT = '6.20.1',
  EMERGENCY_EXIT_RIGHT = '6.20.2',
  DIRECTION_OF_MOVEMENT_TO_THE_EMERGENCY_EXIT = '6.21',
  PHOTO_AND_VIDEO_RECORDING = '6.22',
  MEDICAL_AID_STATION = '7.1',
  HOSPITAL = '7.2',
  GAS_STATION = '7.3',
  CAR_MAINTENANCE = '7.4',
  CAR_WASH = '7.5',
  PHONE = '7.6',
  FOOD_POINT = '7.7',
  DRINKING_WATER = '7.8',
  HOTEL = '7.9',
  CAMPING = '7.10',
  REST_PLACE = '7.11',
  DPS = '7.12',
  POLICE = '7.13',
  CUSTOMS_CONTROL_POINT = '7.14.1',
  TRANSPORT_CONTROL_POINT = '7.14.2',
  RECEPTION_AREA_OF_RADIO = '7.15',
  RADIO_COMMUNICATION_AREA_WITH_EMERGENCY_SERVICES = '7.16',
  POOL_OR_BEACH = '7.17',
  WC = '7.18',
  EMERGENCY_PHONE_NUMBER = '7.19',
  FIRE_EXTINGUISHER = '7.20',
  GAS_STATION_WITH_ELECTRIC = '7.21',
  DISTANCE_TO_OBJECT = '8.1.1',
  DISTANCE_TO_OBJECT_2 = '8.1.2',
  DISTANCE_TO_OBJECT_RIGHT = '8.1.3',
  DISTANCE_TO_OBJECT_LEFT = '8.1.4',
  COVERAGE_AREA = '8.2.1',
  COVERAGE_AREA_2 = '8.2.2',
  COVERAGE_AREA_END = '8.2.3',
  COVERAGE_AREA_CONTINUE = '8.2.4',
  COVERAGE_AREA_RIGHT = '8.2.5',
  COVERAGE_AREA_LEFT = '8.2.6',
  DIRECTION_OF_ACTION_RIGHT = '8.3.1',
  DIRECTION_OF_ACTION_LEFT = '8.3.2',
  DIRECTION_OF_ACTION_CONTINUE = '8.3.3',
  TYPE_VEHICLE_TRUCK = '8.4.1',
  TYPE_VEHICLE_TRAILER = '8.4.2',
  TYPE_VEHICLE_AUTO = '8.4.3',
  TYPE_VEHICLE_ELECTRO = '8.4.3.1',
  TYPE_VEHICLE_BUS = '8.4.4',
  TYPE_VEHICLE_TRACTOR = '8.4.5',
  TYPE_VEHICLE_MOTO = '8.4.6',
  TYPE_VEHICLE_BICYCLE = '8.4.7',
  TYPE_VEHICLE_DANGER = '8.4.8',
  TYPE_VEHICLE_SCOOTER = '8.4.7.2',
  EXCEPT_TYPE_VEHICLE_TRUCK = '8.4.9',
  EXCEPT_TYPE_VEHICLE_AUTO = '8.4.10',
  EXCEPT_TYPE_VEHICLE_BUS = '8.4.11',
  EXCEPT_TYPE_VEHICLE_MOTO = '8.4.12',
  EXCEPT_TYPE_VEHICLE_BICYCLE = '8.4.13',
  EXCEPT_TYPE_VEHICLE_TAXI = '8.4.14',
  EXCEPT_TYPE_VEHICLE_ELECTRO = '8.4.15',
  EXCEPT_TYPE_VEHICLE_SCOOTER = '8.4.16',
  WEEKEND = '8.5.1',
  WORK_DAYS = '8.5.2',
  DAYS_OF_THE_WEEK = '8.5.3',
  TIME_OF_ACTION = '8.5.4',
  WEEKEND_TIME = '8.5.5',
  WORK_DAYS_TIME = '8.5.6',
  DAYS_OF_THE_WEEK_TIME = '8.5.7',
  METHOD_OF_PARKING_A_VEHICLE = '8.6.1',
  METHOD_OF_PARKING_A_VEHICLE_2 = '8.6.2',
  METHOD_OF_PARKING_A_VEHICLE_3 = '8.6.3',
  METHOD_OF_PARKING_A_VEHICLE_4 = '8.6.4',
  METHOD_OF_PARKING_A_VEHICLE_5 = '8.6.5',
  METHOD_OF_PARKING_A_VEHICLE_6 = '8.6.6',
  METHOD_OF_PARKING_A_VEHICLE_7 = '8.6.7',
  METHOD_OF_PARKING_A_VEHICLE_8 = '8.6.8',
  METHOD_OF_PARKING_A_VEHICLE_9 = '8.6.9',
  PARKING_WITH_THE_ENGINE_NOT_RUNNING = '8.7',
  PAID_SERVICES = '8.8',
  PARKING_DURATION_LIMITATION = '8.9.1',
  PARKING_FOR_PERMIT_HOLDERS_ONLY = '8.9.2',
  PARKING_FOR_DIPLOMATIC_VEHICLES_ONLY = '8.9.3',
  VEHICLE_INSPECTION_AREA = '8.10',
  MAXIMUM_WEIGHT_LIMIT = '8.11',
  DANGEROUS_ROADSIDE_ADDITIONAL = '8.12',
  MAIN_ROAD_DIRECTION = '8.13',
  LANE = '8.14',
  BLIND_PEDESTRIANS = '8.15',
  WET_COATING = '8.16',
  DISABLED_PEOPLE = '8.17',
  EXCEPT_DISABLED_PEOPLE = '8.18',
  DANGEROUS_GOODS_CLASS = '8.19',
  VEHICLE_BOGIE_TYPE = '8.20.1',
  VEHICLE_BOGIE_TYPE_2 = '8.20.2',
  TYPE_OF_ROUTE_VEHICLE = '8.21.1',
  TYPE_OF_ROUTE_VEHICLE_2 = '8.21.2',
  TYPE_OF_ROUTE_VEHICLE_3 = '8.21.3',
  LET = '8.22.1',
  LET_2 = '8.22.2',
  LET_3 = '8.22.3',
  A_TOW_TRUCK_IS_WORKING = '8.24',
  ECOLOGICAL_CLASS_OF_THE_VEHICLE = '8.25',
  CHARGING_ELECTRIC_VEHICLES = '8.26'
}
