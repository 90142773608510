<script setup></script>

<template>
  <path
    d="M2.0002 0C0.892099 0 0 0.853347 0 1.91278V38.0872C0 39.1467 0.892099 40 2.0002 40H32.9998C34.1079 40 35 39.1467 35 38.0872V8.11707L26.5174 0.0022332L2.0002 0ZM25.0961 1.36032L33.5786 9.47515H27.0941C25.986 9.47515 25.0939 8.6218 25.0939 7.56237L25.0961 1.36032ZM6.50234 15.2165H28.5016V17.1292H6.50234V15.2165ZM6.50234 22.8683H28.5016V24.7811H6.50234V22.8683ZM6.50234 30.5202H28.5016V32.433H6.50234V30.5202Z"
    fill="#686868"
    fill-opacity="0.7"
  />
</template>

<style></style>
