import { TooltipParams } from '@/types/RoadDiagnostic'

import { ROUNDING_IRI_VALUE } from '@/utils/consts'

export default function iriPopup(params: TooltipParams) {
  const { marker, seriesName, data } = params

  return `
    <div class="iri-tooltip">
        <div class="iri-tooltip__title">
            ${marker}
            ${seriesName}
        </div>
        <p class="iri-tooltip__value">
        ${(data.at(1) as number).toFixed(ROUNDING_IRI_VALUE)}
        </p>
    </div>
  `
}
