<script setup lang="ts"></script>

<template>
  <div class="outer">
    <h2 class="outer__title">IndorRoad</h2>

    <ul class="outer__list">
      <li class="outer__item">
        <h3 class="outer__title">
          М-4 "Дон" Москва - Воронеж - Ростов-на-Дону - Краснодар - Новороссийск
        </h3>
        <ul class="outer__info">
          <li class="outer-info__item">
            <h4 class="outer-info__title">Идентификационный номер</h4>
            <p class="outer-info__value">00 ОП ФЗ М-4</p>
          </li>
          <li class="outer-info__item">
            <h4 class="outer-info__title">Учетный номер</h4>
            <p class="outer-info__value">М-4</p>
          </li>
          <li class="outer-info__item">
            <h4 class="outer-info__title">Номер СКДФ</h4>
            <p class="outer-info__value">89771</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.outer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 3.75rem;

  &__title {
    font-family: $f-family-caption;
    font-size: 1.125em;
    font-weight: 700;
    @include color('fontColor');
  }

  &__list {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 3.125rem;
  }

  &__item {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 3.125rem;
  }

  &__info {
    display: grid;
    grid-gap: 1.875rem 8.88rem;
    grid-template-columns: repeat(2, 13.44rem);
    grid-template-rows: repeat(2, 1fr);
    justify-content: start;
    width: 44.06rem;
    padding: 1.875rem;
    background: rgb(255 255 255 / 7%);
  }
}

.outer-info {
  &__item {
    display: grid;
    grid-row-gap: 0.625rem;
  }

  &__title {
    color: $c-gray-65;
    font-family: $f-family-base;
    font-size: 1em;
    font-weight: 700;
  }

  &__value {
    // color: $c-white;
    font-family: $f-family-base;
    font-size: 1.125em;
    font-weight: 700;
    @include color('fontColor');
  }
}
</style>
