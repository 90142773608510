import { SelectedChartItem } from '@/types/Chart'
import { ECHARTS_ACTION } from '@/utils/lists'
import { ECharts } from 'echarts'

function highlightPoint(chart: ECharts, points: SelectedChartItem[]) {
  points.forEach((point) => {
    const { dataIndex, seriesName } = point

    chart.dispatchAction({
      dataIndex,
      seriesName,
      type: ECHARTS_ACTION.HIGHLIGHT
    })
  })
}

function downplayPoint(chart: ECharts, points: SelectedChartItem[]) {
  points.forEach((point) => {
    const { dataIndex, seriesName } = point

    chart.dispatchAction({
      dataIndex,
      seriesName,
      type: ECHARTS_ACTION.DOWNPLAY
    })
  })
}

export default function useChartHighlight() {
  return {
    downplayPoint,
    highlightPoint
  }
}
