import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { fetchWrapper } from '@/api/fetchApi'
import { SelectOption } from '@/types/Select'

export type TTerritory = {
  id: number
  name: string
  description: string
  created_date: string
  updated_date: string
}

export const useTerritoriesStore = defineStore('territories', () => {
  // state
  const territories: TTerritory[] = reactive(new Array<TTerritory>())

  // getters
  const asOptions = computed(() => {
    const options: SelectOption[] = territories.map((t) => {
      return {
        id: t.id,
        title: t.name
      }
    }) as unknown as SelectOption[]

    return options
  })

  //actions
  const fetchAll = async (): Promise<void> => {
    const data = (await fetchWrapper.get('/dicts/territories/', {})) as TTerritory[]
    territories.length = 0
    territories.push(...data)
  }

  return {
    // state
    territories,

    // getters
    asOptions,

    // action
    fetchAll
  }
})
