export default function copyMaterial(source, target) {
  for (const name of Object.keys(target.uniforms)) {
    target.uniforms[name].value = source.uniforms[name].value
  }

  target.gradientTexture = source.gradientTexture
  target.visibleNodesTexture = source.visibleNodesTexture
  target.classificationTexture = source.classificationTexture
  target.matcapTexture = source.matcapTexture

  target.activeAttributeName = source.activeAttributeName
  target.ranges = source.ranges
}
