import { computed } from 'vue'
import { useDefectsStore, useRoadDefectsMapStore, useRoadStore } from '@/stores'

import { GeometryMapLayers } from '@/utils/lists'
import { MapFeature, toggleEffectOnFeature } from '@/utils/map'
import renderFeature from '@/utils/map/renderFeature'

import { ROAD_DEFECTS_SOURCE_NAME } from '@/utils/consts'

import type { Map, MapMouseEvent } from 'maplibre-gl'
import type { Defect, RoadNeiroDefect } from '@/types/RoadDiagnostic'
import type {
  DataRenderingParams,
  MapFeatureRendered,
  MapRenderParams,
  VisibleInterface
} from '@/types/Map'

/**
 * Преобразование информации о дефектах в GeoJSON
 * */
function defectInfoToGeoJSON(info: Defect[]) {
  const first = info.at(0)

  const position = first?.position_geojson ? JSON.parse(first.position_geojson) : {}

  return {
    geometry: {
      type: position.type,
      coordinates: position.coordinates
    },
    properties: info,
    type: 'Feature'
  }
}

/**
 * Фильтр дефектов, которые не имеют геометрии
 */
function withGeometry(defects: RoadNeiroDefect[]) {
  return defects.filter((defect) => defect.position_gejson)
}

/**
 * Преобразование дефектов в данные для карты(geojson)
 */
function defectDataToGeoJSON(defects: RoadNeiroDefect[]) {
  const copy = defects.slice()

  const defectsWithGeoJSON = withGeometry(copy)

  const geometry = defectsWithGeoJSON.map((info) => {
    return defectInfoToGeoJSON(info.defect_list)
  })

  return geometry
}

/**
 * Преобразование данных в геометрию на карте дефектов
 * */
function getDataForRendering(params: DataRenderingParams) {
  const { map } = params

  if (map === undefined) return []

  return getRenderParams(params)
}

/**
 * Получение настроек отрисовки
 */
function getRenderParams(params: DataRenderingParams) {
  const { renderData, defects } = params

  const roadStore = useRoadStore()

  const renderParams: MapRenderParams[] = []

  const geometryRoad = roadStore.findRenderData(GeometryMapLayers.ROAD_WAY, renderData)
  const defectsGeoJSON = defectDataToGeoJSON(defects)

  const defectRenderParams: MapRenderParams = {
    data: {
      type: 'FeatureCollection',
      features: defectsGeoJSON
    },
    params: {
      showed: true,
      color: '#eb9c52',
      activeColor: '#d16b0a',
      hoveredColor: '#f59131',
      name: ROAD_DEFECTS_SOURCE_NAME
    }
  }

  if (geometryRoad) renderParams.push(geometryRoad)
  renderParams.push(defectRenderParams)

  return renderParams
}

/**
 * Перелет на карте
 */
function flyToMap(map: Map) {
  const roadDefectsMapStore = useRoadDefectsMapStore()
  roadDefectsMapStore.updateInstance(map)

  const defectStore = useDefectsStore()
  const [first] = defectStore.currentDefect

  if (!first?.position_geojson) return

  const defectCoordinates = JSON.parse(first.position_geojson)

  map.flyTo({
    center: defectCoordinates.coordinates,
    essential: true,
    zoom: 17
  })
}

/**
 * Отрендерить фичи на карте
 */
function renderMapFeatures(params: DataRenderingParams) {
  const { map, defects, renderData } = params

  const renderItems = getDataForRendering({
    map,
    defects,
    renderData
  })

  const { renderFeatures } = renderFeature()

  renderItems.forEach((item) => {
    if (item) renderFeatures(item, map)
  })
}

function clearRoadNeiroDefect(map: Map) {
  const data = map.getStyle()

  const layers = data.layers.filter((layer) => layer.id.startsWith(ROAD_DEFECTS_SOURCE_NAME))

  for (const layer in layers) {
    map.removeLayer(layers[layer].id)
  }

  if (map.getSource(ROAD_DEFECTS_SOURCE_NAME)) {
    map.removeSource(ROAD_DEFECTS_SOURCE_NAME)
  }
}

/**
 * Очистить карту и заново отрендерить
 */
function rerenderMapFeatures(params: DataRenderingParams) {
  const { map } = params

  clearRoadNeiroDefect(map)

  renderMapFeatures(params)
}

/**
 * Фильтр дефетов, после переключения их видимости
 */
function toggleVisibleDefectMap(params: VisibleInterface) {
  const { visible, defects } = params

  const showedDefects = visible.filter((el) => el.show)
  const showedDefectsNames = showedDefects.map((el) => el.name)

  const defectsOnMap = defects.filter((el) => {
    const list = el.defect_list as Defect[]

    return list.find((item) => showedDefectsNames.includes(item.class_name))
  })

  const renderParams = Object.assign({} as DataRenderingParams, params, {
    defects: defectsOnMap
  })

  rerenderMapFeatures(renderParams)
}

/**
 * Ховер эффект на дефектах
 */
function hoverOnDefect(e: MapMouseEvent) {
  const map = e.target
  map.getCanvasContainer().style.cursor = 'pointer'

  const mapStore = useRoadDefectsMapStore()
  const hovered = computed(() => mapStore.hoveredFeature)

  if (hovered.value) {
    toggleEffectOnFeature(map, hovered.value, {
      hover: false
    })
  }

  const [feature] = map.queryRenderedFeatures(e.point)

  if (!feature?.id) return

  const rendered = new (MapFeature as unknown as MapFeatureRendered)({
    source: ROAD_DEFECTS_SOURCE_NAME,
    id: +feature.id
  })

  mapStore.updateHoveredFeature(rendered)

  toggleEffectOnFeature(map, rendered, {
    hover: true
  })
}

/**
 * Покидаем дефект
 */
function leaveMouseFromDefect(e: MapMouseEvent) {
  e.target.getCanvasContainer().style.cursor = 'default'

  const mapStore = useRoadDefectsMapStore()
  const hovered = computed(() => mapStore.hoveredFeature)

  if (!hovered.value) return

  toggleEffectOnFeature(e.target, hovered.value, {
    hover: false
  })

  mapStore.updateHoveredFeature()
}

export default function () {
  return {
    flyToMap,
    defectDataToGeoJSON,
    getDataForRendering,
    renderMapFeatures,
    rerenderMapFeatures,
    toggleVisibleDefectMap,
    hoverOnDefect,
    leaveMouseFromDefect
  }
}
