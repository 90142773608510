<script lang="ts" setup>
import { computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import { useSegmentsStore, useDefectsStore } from '@/stores'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'
import { useDefectChartStore } from '@/stores/road/defects/road-defects-chart'

import MaskFilter from '@/components/layouts/road/diagnostic/defects/AppRoadDefectsMaskFilter/MaskFilter.vue'
import InputField from '@/components/ui/InputField.vue'

import { DefectType, DefectTypeMaskStyle } from '@/types/RoadDiagnostic'

interface Props {
  defect: DefectTypeMaskStyle
  params: Partial<DefectType>
}

const props = defineProps<Props>()

const defectChartStore = useDefectChartStore()
const segmentsStore = useSegmentsStore()
const defectsStore = useDefectsStore()
const defectFilterStore = useDefectFilterStore()

const defect = computed(() => props.defect)
const params = computed(() => props.params)
const segmentId = computed(() => segmentsStore.currentSegment)
const sizes = computed(() => defectFilterStore.sizes)
const styles = computed(() => defectFilterStore.style)
const style = computed(() => styles.value.find((el) => el.id === defect.value.id))

async function getDefects(key: string, value: string) {
  defectFilterStore.changeFilterPayload(defect.value.name, {
    [key]: value
  })

  await defectsStore.fetchDefects(segmentId.value)
  defectChartStore.setChartData()
}

const debInput = useDebounceFn((key, value) => getDefects(key, value), 250)
</script>

<template>
  <div class="filterDefects">
    <h3 class="filterDefects__title">{{ defect.label }}</h3>
    <div
      v-for="(item, index) in sizes"
      :key="index + '-size-' + defect.name"
      class="filterDefects__filter"
    >
      <h3 class="filterDefects__title">{{ item.name }}, {{ item.unit }}</h3>
      <InputField
        v-for="(elem, counter) in item.keys"
        :key="counter + '-size-input-' + defect.name"
        :model-value="params[elem]"
        :type="'number'"
        class="filterDefects__filterField"
        @update:model-value="(value) => debInput(elem, value)"
      ></InputField>
    </div>
    <MaskFilter v-if="style" class="filterDefects__filter" :style="{ val: style }" />
  </div>
</template>

<style lang="scss" scoped>
.filterDefects {
  display: grid;
  grid-gap: 0 50px;
  grid-template-columns: 1fr 266px 240px 266px 205px;
  padding: 25px 30px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #686868;
  }

  &__title {
    display: flex;
    align-self: center;
    font-size: 1rem;
  }

  &__filter {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      .filterDefects__title {
        margin-right: 10px;
        font-family: $f-family-base;
        white-space: nowrap;
      }
    }
  }

  &__filterField {
    width: 75px;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #686868;
    background: none;
    appearance: none;

    @include color('fontColor');

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .rangeOpacity {
    accent-color: coral;
    margin-right: 10px;
  }
}

@media (width <= 1842px) {
  .filterDefects {
    flex-wrap: wrap;
    gap: 20px 15px;

    &__filter {
      &:nth-child(n + 3) {
        margin-left: 0;
      }
    }
  }

  .defects {
    &__charts,
    &__map {
      width: 100%;
    }
  }
}

@media (width <= 1650px) {
  .filterDefects {
    grid-gap: 15px;
    grid-template-columns: 250px 265px 240px;
    padding: 25px 20px;
  }
}
</style>
