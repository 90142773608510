import { uniqFieldsArray } from '../utils/uniq-fields-array'

import { LIMIT_IRI_VALUE, ROUNDING_IRI_VALUE } from '../utils/consts'

import { RoadDefect, RoadIRITableData, RoadSegment } from '../types/Road'
import { ChartData, ChartDataLines } from '../types/Chart'
import { standartIRI } from '../assets/data/road-iri'
import { useRoadStore } from '@/stores/road/road'

const chartColors = [
  '#6e9ef5',
  '#54b754',
  '#ff6666',
  '#007F73',
  '#ffcc33',
  '#FDE49C',
  '#3468C0',
  '#F5CCA0',
  '#CD104D',
  '#143F6B'
]

class RoadIRI {
  public toChartData(responseIRI: RoadDefect[], segmentsResponse: RoadSegment[]) {
    const segments = uniqFieldsArray<RoadDefect>(responseIRI, 'segment_id').sort(
      (a, b) => a - b
    ) as number[]

    /**
     * TODO нужен рефактор
     * */
    const data: ChartData[] = segments.map((id) => {
      const currentSegmentIri = responseIRI.filter((el) => el.segment_id === id)

      const linesData = currentSegmentIri.map((el) => ({
        line: el.line_number,
        coors: [el.last ?? 0, el.value ?? 0],
        standart: el.admissible_value ?? 0
      }))

      const lines = uniqFieldsArray(currentSegmentIri, 'line_number').sort((a, b) => a - b)

      const lineValues: ChartDataLines[][] = lines.map((line) =>
        linesData.filter((el) => el.line === line)
      )

      return {
        segmentId: id,
        name: segmentsResponse.find((el) => el.id === id)?.name as string,
        lines: lineValues
      }
    })

    this.setLimitValue(data)
    this.setColors(data)

    return data
  }

  setColors(data: ChartData[]) {
    data.forEach((segment) => {
      segment.colors = segment.lines.map((line, index) =>
        line.at(0)?.line === LIMIT_IRI_VALUE ? 'red' : chartColors[index]
      )
    })
  }

  setLimitValue(data: ChartData[]) {
    const roadStore = useRoadStore()
    const road = roadStore.getRoad

    const roadCategory = this.getRoadCategory(road!.technical_category)

    if (!roadCategory) return

    data.forEach((segment) => {
      const greaterCoors = [...segment.lines]
        .sort((a, b) => b.length - a.length)
        .at(0)
        ?.at(-1)?.coors

      segment.lines.push([
        {
          line: LIMIT_IRI_VALUE,
          coors: [0, standartIRI[roadCategory]] as number[],
          standart: standartIRI[roadCategory] as number
        },
        {
          line: LIMIT_IRI_VALUE,
          coors: [greaterCoors?.at(0) ?? 0, standartIRI[roadCategory]] as number[],
          standart: standartIRI[roadCategory] as number
        }
      ])
    })
  }

  public toTableData(response: RoadDefect[], category: string) {
    const roadCategory = this.getRoadCategory(category)

    if (!roadCategory) throw new Error('Отсутствует категория дороги')

    const legends = uniqFieldsArray(response, 'line_number').sort((a, b) => a - b)

    const positions = response
      .map((iri) => ({
        position: +(iri.last ?? 0).toFixed(3),
        standart: standartIRI[roadCategory] ? +standartIRI[roadCategory].toFixed(3) : 0,
        data: [] as RoadIRITableData[],
        segmentId: iri.segment_id
      }))
      .filter((iri) => !isNaN(iri.position))
      .sort((a, b) => a.position - b.position)

    positions.forEach((row) => {
      const rowLines = [
        ...response.filter(
          (iri) => +(iri.last ?? 0).toFixed(3) === row.position && row.segmentId === iri.segment_id
        )
      ]
      const otherLines = legends.filter(
        (el) => !rowLines.map((item) => item.line_number).includes(el)
      )
      const allLines = [...rowLines, ...otherLines.map((el) => ({ line_number: el, value: null }))]

      row.data = allLines
        .map((line) => {
          return {
            line: line.line_number,
            value: line.value ? +line.value?.toFixed(ROUNDING_IRI_VALUE) : line.value
          }
        })
        .sort((a, b) => ((a.line as number) ?? 0) - ((b.line as number) ?? 0))
    })

    return positions
  }

  calcDistanceCb(initial: number, current: RoadDefect, allIri: RoadDefect[]) {
    const index = allIri.findIndex(
      (el) => el.last === current.last && el.line_number === current.line_number
    )

    const isExist = index >= 0

    if (!isExist) return initial

    const existPrevPoint =
      index - 1 >= 0 && allIri[index - 1].line_number === allIri[index].line_number

    const prevPoint = existPrevPoint ? allIri[index - 1] : { last: 0 }

    const diff = (current.last ?? 0) - (prevPoint.last ?? 0)

    return initial + diff
  }

  private distance(currentLineIri: RoadDefect[], allIri: RoadDefect[]) {
    return currentLineIri.reduce(
      (initial, current) => this.calcDistanceCb(initial, current, allIri),
      0
    )
  }

  private separateIriByCorrespondence(
    iri: RoadDefect[],
    separated: RoadDefect[][],
    roadCategory: string
  ) {
    const lines = uniqFieldsArray(iri, 'line_number')

    const sortByLines = lines.map((el) => {
      return iri.filter((item) => item.line_number === el)
    })

    sortByLines.forEach((line) => {
      line.forEach((iri) => {
        const index = iri.value! <= standartIRI[roadCategory] ? 0 : 1

        separated[index].push(iri)
      })
    })
  }

  getRoadCategory(category: string) {
    const categories = Object.keys(standartIRI)

    return categories.find((el) => category.includes(el))
  }

  private calculateDistance(currentLineIri: RoadDefect[], allIri: RoadDefect[]) {
    return currentLineIri.length ? this.distance(currentLineIri, allIri) : 0
  }

  public getCorrespondence(data: RoadDefect[], category: string) {
    const roadCategory = this.getRoadCategory(category)

    const iri = data.filter((iri) => iri.value)

    const separated: RoadDefect[][] = [[], []]

    if (iri.length && roadCategory) {
      this.separateIriByCorrespondence(iri, separated, roadCategory)
    }

    const totalDistance = separated.map((el) => this.calculateDistance(el, data))
    const percent = totalDistance.reduce((initial, current) => initial + current, 0) / 100

    return separated.map((el, index) => {
      const name = index === 0 ? 'Соответствует' : 'Не соответствует'
      const distance = this.calculateDistance(el, data)
      const method = index === 0 ? 'floor' : 'ceil'

      return {
        name,
        distance: totalDistance ? Math.trunc(distance * 1000) : 'Нет данных',
        percent: totalDistance ? Math[method](distance / percent) : 'Нет данных'
      }
    })
  }

  public updateChart(
    response: RoadDefect[],
    segments: RoadSegment[],
    setChartCache: (iri: ChartData[]) => void,
    changeSegment: (id: number, type: string) => void,
    type: string
  ) {
    const segmentSorted = [...segments].sort((a, b) => a.id - b.id)

    const data = this.toChartData(response, segmentSorted)

    if (!data) return

    setChartCache(data)

    for (const element of segmentSorted) {
      const current = data.find((el) => el.segmentId === element.id)

      if (current) {
        changeSegment(current.segmentId, type)
        break
      }
    }
  }
}

export default RoadIRI
