<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import AppHeader from './layouts/header/AppHeader.vue'
import BackgroundContextMenu from './layouts/background/BackgroundContextMenu.vue'

const route = useRoute()

const names = ['Geometry', 'Map']

const loginPage = computed<undefined | boolean>(() => {
  if (route.name === undefined) return

  return route.path === '/login'
})
const defineLoginPageBeforeRoute = computed(() => window.location.href.includes('/login'))
const showBgCtxMenu = computed(() => names.includes(route.name as string))
</script>

<template>
  <div class="page__wrapper">
    <div v-if="loginPage !== undefined && !defineLoginPageBeforeRoute" class="page__header">
      <BackgroundContextMenu v-if="showBgCtxMenu" :opacity="false"></BackgroundContextMenu>
      <div class="container header-container">
        <AppHeader />
      </div>
    </div>
    <router-view v-slot="{ Component, route }">
      <Transition name="fade-page" mode="in-out">
        <Suspense>
          <div>
            <component :is="Component" :key="route.path"></component>
          </div>
        </Suspense>
      </Transition>
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
.page {
  &__header {
    position: relative;
    z-index: 10;
    height: 115px;
    overflow: hidden;
    border-bottom: 1px solid $c-gray-40;
  }
}

.delimiter {
  height: 1px;
  border: 0;
  background-color: $c-gray-40;
}
</style>
