<script setup lang="ts"></script>

<template>
  <div class="line-wrapper">
    <div class="line-dot"></div>
    <div class="line"></div>
    <div class="line-dot"></div>
  </div>
</template>

<style scoped lang="scss">
.line-wrapper {
  position: relative;
  margin-top: 80px;
}

.line {
  width: 100%;
  height: 10px;
  transform: rotate(180deg);
  background: linear-gradient(270deg, rgb(214 129 102 / 0%) 0%, rgb(214 129 102 / 71%) 100%);
}

.line-dot:first-child {
  display: block;
  position: absolute;
  top: -2px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: rgb(214 129 102 / 41%);
}

.line-dot:last-child {
  display: block;
  position: absolute;
  top: -2px;
  right: -3px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #d68166;
}
</style>
