import { useRoadDefectsPopupStore } from '@/stores'

import { Defect, DefectTypeMaskStyle, OptionsMask } from '@/types/RoadDiagnostic.d'
import { Point } from 'chart.js'
import { ECElementEvent, ElementEvent } from 'echarts'
import { ComputedRef } from 'vue'

const DEFECT_POPUP_WIDTH = 600
const MARGIN_POPUP = 50
const INITIAL_POPUP_COORDINATES = 75

/**
 * Открытие попапа дефекта
 */
async function openDefectPopup(chartDefects: Defect[]) {
  const roadDefectPopup = useRoadDefectsPopupStore()

  const isOpen = roadDefectPopup.isOpen

  if (isOpen) {
    roadDefectPopup.changePopupIsOpen(false)
  }

  roadDefectPopup.updateDefectPopupData(chartDefects)

  await new Promise((res) => {
    setTimeout(() => {
      roadDefectPopup.changePopupIsOpen(true)
      res(true)
    })
  })
}

function closeDefectPopup() {
  const roadDefectPopup = useRoadDefectsPopupStore()

  roadDefectPopup.changePopupIsOpen(false)
}

/**
 * Подсчет X координаты попапа
 */
function calculateXCoordinate(coorX: number) {
  const isIntersection = window.innerWidth - coorX < DEFECT_POPUP_WIDTH
  const intersectionX = window.innerWidth - (DEFECT_POPUP_WIDTH + MARGIN_POPUP)

  return isIntersection ? intersectionX : coorX
}

function eventOpeninigDefectPopup(coordinates: Partial<Point>): Partial<ECElementEvent> {
  return {
    event: {
      event: {
        x: coordinates?.x ?? INITIAL_POPUP_COORDINATES,
        y: coordinates?.y ?? INITIAL_POPUP_COORDINATES
      }
    } as ElementEvent
  }
}

function currentStyle(style: OptionsMask[], defect: Defect) {
  return style.find((el) => el.name === defect.class_name)
}

function visibleDefectInPopup(types: ComputedRef<DefectTypeMaskStyle[]>, defects?: Defect[]) {
  return defects?.filter((el) => {
    const current = types.value.find((item) => item.name === el.class_name)

    return current?.show
  })
}

function calcOpacity(
  model: ComputedRef<number>,
  minValue: ComputedRef<number>,
  maxValue: ComputedRef<number>
) {
  return `${(100 * (model.value - minValue.value)) / (maxValue.value - minValue.value) || 0}%`
}

export default function () {
  return {
    currentStyle,
    eventOpeninigDefectPopup,
    visibleDefectInPopup,
    calcOpacity,

    openDefectPopup,
    closeDefectPopup,
    calculateXCoordinate
  }
}
