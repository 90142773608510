<script setup lang="ts">
import BackgroundContextMenu from '@/components/layouts/background/BackgroundContextMenu.vue'

const props = defineProps<{ title: string; description: string; acceptTextBtn: string }>()

const emits = defineEmits(['close', 'accept'])

function close() {
  emits('close')
}

function accept() {
  emits('accept')
}
</script>

<template>
  <div class="confirm">
    <div class="confirm__backdrop" @click="close"></div>
    <div class="confirm__wrapper">
      <div class="confirm__background">
        <BackgroundContextMenu></BackgroundContextMenu>
      </div>
      <div class="confirm__inner">
        <div class="confirm__close" @click="close">
          <div class="confirm-close__wrapper"></div>
        </div>
        <h2 class="confirm__title">{{ props.title }}</h2>
        <p class="confirm__description">{{ props.description }}</p>
        <div class="confirm__nav">
          <button type="button" class="confirm__btn confirm__btn--cancel" @click="close">
            Отмена</button
          ><button type="button" class="confirm__btn confirm__btn--accept" @click="accept">
            {{ acceptTextBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.confirm {
  display: grid;
  position: fixed;
  z-index: 100;
  align-items: center;
  justify-content: center;
  inset: 0;

  &__background {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &__backdrop {
    position: absolute;
    inset: 0;
    background-color: rgba($c-gray-15, 0.5);
  }

  &__wrapper {
    display: grid;
    position: relative;
    z-index: 100;
    width: 525px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    @include border-bottom('confrimRemoveBorder');
    @include border-left('confirnRemoveBorder');

    cursor: pointer;
  }

  &__title {
    color: $c-orange;
    font-family: $f-family-base;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__description {
    margin-top: 30px;
    @include color('fontColor');

    font-family: $f-family-caption;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  &__nav {
    display: grid;
    grid-gap: 0 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
  }

  &__btn {
    height: 50px;
    transition: all 0.1s linear;
    border: 2px solid $c-orange;
    background-color: transparent;
    @include color('fontColor');

    font-family: $f-family-caption;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    cursor: pointer;

    &:hover {
      @include border-color('confirmDocRemoveAcceptBtnBorder');
    }

    &--accept {
      border: 2px solid transparent;
      background-color: $c-orange;
      color: $c-gray-15;

      &:hover {
        @include border-color('confirmDocRemoveAcceptBtnBorder');

        background-color: rgb(196 104 76);
        color: inherit;
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 1000;
    padding: 40px 50px;
    @include border('confirmRemoveBorder');
  }
}

.confirm-close {
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.1s linear;

    &::after,
    &::before {
      content: '';
      display: grid;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 2px;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all 0.1s linear;
      background-color: $c-gray-65;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      background-color: $c-gray-65;

      &::after,
      &::before {
        background-color: $c-gray-15;
      }
    }
  }
}
</style>
