import { APP_THEMES } from '@/utils/lists'
import { Ref } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'

const WHITE_THEME_WRAPPER_SIZE = '300vw'

interface ImmedParams {
  value: HTMLElement
  mapPages: string[]
  route: RouteLocationNormalizedLoaded
  theme: Ref<string>
}

class Theme {
  animateWhiteTheme(theme: string) {
    const whiteTheme: HTMLElement | null = document.querySelector(
      '.app-background-animate--white.main'
    )

    if (!whiteTheme) return

    const isLight = theme === APP_THEMES.LIGHT

    whiteTheme.style.transition = 'all 0.4s ease-in-out'
    whiteTheme.style.width = isLight ? WHITE_THEME_WRAPPER_SIZE : '0px'
    whiteTheme.style.height = isLight ? WHITE_THEME_WRAPPER_SIZE : '0px'

    setTimeout(() => {
      whiteTheme.style.transition = 'all 0s ease-in-out'
    }, 400)
  }

  setWhiteThemeImmed(params: ImmedParams) {
    const { value, mapPages, route, theme } = params

    const { width } = getComputedStyle(value, null)

    const showLightTheme =
      value &&
      !mapPages.includes(route.path) &&
      theme.value === APP_THEMES.LIGHT &&
      parseInt(width) === 0

    if (!showLightTheme) return

    value.style.transition = 'all 0s linear'
    value.style.width = WHITE_THEME_WRAPPER_SIZE
    value.style.height = WHITE_THEME_WRAPPER_SIZE
  }
}

export default Theme
