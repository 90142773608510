import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { fetchWrapper } from '@/api/fetchApi'
import { useRouter } from 'vue-router'

type TToken = {
  access_token: string
}

export const useAuthStore = defineStore('auth', () => {
  const tokenData = JSON.parse(<string>localStorage.getItem('tokenData')) || null
  const token: Ref<TToken | null> = ref(tokenData)
  const supportIsOpen = ref(false)
  const router = useRouter()

  const _setToken = (tokenData: TToken | object): void => {
    token.value = tokenData as TToken
    localStorage.setItem('tokenData', JSON.stringify(tokenData))
  }

  const login = async (username: string | null, password: string | null): Promise<void> => {
    const query = { username, password }

    const access_token = (await fetchWrapper.post('/users/login/', query)) as TToken
    if (!access_token) return

    _setToken(access_token)
  }

  function removeToken() {
    token.value = null
    localStorage.removeItem('tokenData')
  }

  const logout = async () => {
    await fetchWrapper.get('/users/logout/')
    removeToken()
    router.push('/login')
  }

  function toggleSupport() {
    supportIsOpen.value = !supportIsOpen.value
  }

  return {
    //state
    token,
    supportIsOpen,

    _setToken,

    //actions
    login,
    logout,
    toggleSupport,
    removeToken
  }
})
