import { SignOnScreen } from '@/types/Road'
import { Marker } from 'maplibre-gl'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

interface RoadSignState {
  signOnScreen?: SignOnScreen
  allSigns: Marker[]
}

export const useRoadSignStore = defineStore('road-sign-store', () => {
  const state: RoadSignState = reactive({
    signOnScreen: undefined,
    allSigns: []
  })

  const getSignOnScreen = computed(() => state.signOnScreen)
  const getAllSings = computed(() => state.allSigns)

  function setSignOnScreen(signs: SignOnScreen) {
    state.signOnScreen = signs
  }

  function setMarkers(signs: Marker[]) {
    state.allSigns = signs
  }

  return {
    getSignOnScreen,
    getAllSings,

    setSignOnScreen,
    setMarkers
  }
})
