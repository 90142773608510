<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'

const GRID_SIZE = 100
const SQUARE_T1_COUNT = 13
const SQUARE_T2_COUNT = 12
const CROSS_COUNT = 20
const CROSS_SIZE = 13

const getRandomUniNumbers = (from: number, to: number, n: number): number[] => {
  return [...Array(to - from + 1).keys()]
    .map((i) => i + from)
    .reduce((arr, elt) => (arr.splice(Math.random() * (arr.length + 1), 0, elt as never), arr), [])
    .slice(0, n)
}

const getRandomCoordinates = (n: number, gridSize: number): number[][] => {
  const maxXCount = Math.floor(window.innerWidth / gridSize)
  const maxYCount = Math.floor(window.innerHeight / gridSize)
  const xCoords = getRandomUniNumbers(0, maxXCount, n)
  const yCoords = getRandomUniNumbers(0, maxYCount, n)

  return xCoords.map((item, index) => [item * gridSize, yCoords[index] * gridSize])
}

const createFillRect = (
  context: CanvasRenderingContext2D,
  gridSize: number,
  color: string,
  n: number
): void => {
  context.fillStyle = color
  const coords = getRandomCoordinates(n, gridSize)
  coords.forEach((item) => {
    context.fillRect(item[0], item[1], gridSize, gridSize)
  })
}

const createCross = (
  context: CanvasRenderingContext2D,
  gridSize: number,
  color: string,
  size: number,
  n: number
): void => {
  const coordsCross = getRandomCoordinates(n, gridSize)

  coordsCross.forEach((item) => {
    context.moveTo(item[0] - size / 2, item[1])
    context.lineTo(item[0] + size / 2, item[1])
    context.moveTo(item[0], item[1] - size / 2)
    context.lineTo(item[0], item[1] + size / 2)
  })

  context.strokeStyle = color
  context.stroke()
}

const createGrid = (context: CanvasRenderingContext2D, gridSize: number, color: string): void => {
  for (let x = 0; x <= window.innerWidth; x += gridSize) {
    context.moveTo(x, 0)
    context.lineTo(x, window.innerHeight)
  }

  for (let x = 0; x <= window.innerHeight; x += gridSize) {
    context.moveTo(0, x)
    context.lineTo(window.innerWidth, x)
  }

  context.strokeStyle = color
  context.stroke()
}

const renderGrid = (): void => {
  const canvas: HTMLCanvasElement | null = document.querySelector('.appBackground canvas')

  if (canvas) {
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
    ctx.canvas.width = window.innerWidth
    ctx.canvas.height = window.innerHeight

    createFillRect(ctx, GRID_SIZE, 'rgba(255,255,255, .04)', SQUARE_T1_COUNT)
    createFillRect(ctx, GRID_SIZE, 'rgba(255,255,255, .07)', SQUARE_T2_COUNT)
    createCross(ctx, GRID_SIZE, 'white', CROSS_SIZE, CROSS_COUNT)
    createGrid(ctx, GRID_SIZE, 'rgba(43,43,43, .5)')
  }
}

onMounted(() => {
  renderGrid()
  window.addEventListener('resize', renderGrid)
})

onUnmounted(() => {
  window.removeEventListener('resize', renderGrid)
})
</script>

<template>
  <div class="appBackground">
    <canvas></canvas>
    <div class="appBackground__gradient"></div>
    <div class="appBackground__noise"></div>
  </div>
</template>

<style lang="scss" scoped>
.appBackground {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  > * {
    position: absolute;
    inset: 0;
  }

  &__noise {
    background: url('@/assets/images/noise-dark.png');
  }

  &__gradient {
    opacity: 0.8;
    background: radial-gradient(
        124.03% 39.25% at 100% 53.66%,
        rgb(35 20 27 / 70%) 0%,
        rgb(40 40 40 / 0%) 100%
      ),
      linear-gradient(134deg, #2c2827 0%, rgb(26 25 29 / 0%) 99.96%);
  }
}

.fadeDelay-enter-active,
.fadeDelay-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.2s;
}

.fadeDelay-enter-from,
.fadeDelay-leave-to {
  opacity: 0;
}
</style>
