import { BindingClickOutside, DomElementClickOutside } from '@/types/ClickOutside'

export default {
  mounted(el: HTMLElement, binding: BindingClickOutside) {
    const domElement = el as unknown as DomElementClickOutside

    domElement.handleOutsideClick = (e: Event) => {
      e.stopPropagation()

      const target = e.target as Node

      if (target && !el.contains(target)) {
        binding.value()
      }
    }

    document.addEventListener('click', domElement.handleOutsideClick)
    document.addEventListener('touchstart', domElement.handleOutsideClick)
  },

  beforeUnmount(el: DomElementClickOutside) {
    document.removeEventListener('click', el.handleOutsideClick)
    document.removeEventListener('touchstart', el.handleOutsideClick)
  }
}
