<script lang="ts" setup>
import { computed } from 'vue'

import useRoadWidgetsStore from '@/stores/road/road-widgets'
import isEmpty from '@/utils/is-empty.ts'

const PLACEHOLDER = 'Нет данных'

const widgetsStore = useRoadWidgetsStore()

const data = computed(() => Object.entries(widgetsStore.getWidgets))
</script>

<template>
  <ul class="road-widgets">
    <li
      v-for="(item, index) in data"
      :key="`widget-${index}`"
      :class="`road-widgets__widget--${item[0]}`"
      class="road-widgets__widget"
    >
      <div
        v-for="(elem, count) in item[1]"
        :key="`widget-wrapper-${count}`"
        class="road-widgets__wrapper"
      >
        <p class="road-widgets__title">{{ elem.name }}</p>
        <component
          :is="elem.icon"
          v-if="elem.icon"
          :class="`road-widgets__icon--${item[0]}`"
        ></component>
        <span :class="`road-widgets__icon--${item[0]}`" class="road-widgets__icon"></span>
        <p :class="[isEmpty(elem.value) ? 'empty' : '', `${item[0]}`]" class="road-widgets__value">
          {{ elem.value || PLACEHOLDER }}
        </p>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.road-widgets {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(2, 1fr);
  align-self: start;

  &__value {
    margin-top: 1.25rem;
    transition: color 0.3s $main-animation;
    font-family: $f-family-caption;
    font-size: 1.15em;
    font-weight: 700;
    text-align: center;
    @include color('fontColor');

    &.empty {
      color: $c-gray-65;
    }

    &.square {
      margin-top: 0;
    }
  }

  &__widget {
    height: 275px;
    padding: 30px;
    transition: background-color 0.3s $main-animation;

    @include background-color('backgroundGeomteryLayer');

    .line-wrapper {
      margin-top: 3.375rem;
    }

    &:first-child {
      .road-widgets__value {
        margin-top: 1.875rem;
      }
    }

    &--length,
    &--square {
      height: 195px;
    }
  }

  &__wrapper {
    display: grid;
  }

  &__title {
    transition: color 0.3s $main-animation;
    font-family: $f-family-base;
    font-size: 1.125em;
    font-weight: 700;
    text-align: center;

    @include color('fontColor');
  }

  &__icon {
    display: block;
    margin-right: auto;
    margin-left: auto;

    &--square {
      margin-top: 1.25rem;
      margin-right: auto;
      margin-left: auto;
    }

    &--road {
      display: block;
      width: 173px;
      height: 85px;
      margin-top: 3.125rem;
      mask-image: url('@/assets/images/icons/road.svg');
      background-color: $c-orange;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }

    &--width {
      display: block;
      width: 173px;
      height: 85px;
      margin-top: 3.125rem;
      mask-image: url('@/assets/images/icons/width-road.svg');
      background-color: $c-orange;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }

    &--ability {
      display: block;
      width: 167px;
      height: 83px;
      margin-top: 1.94rem;
      mask-image: url('@/assets/images/icons/ability.svg');
      background-color: $c-orange;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }

    &--speed {
      display: block;
      width: 148px;
      height: 84px;
      margin-top: 1.875rem;
      mask-image: url('@/assets/images/icons/speedometer.svg');
      background-color: $c-orange;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }
  }
}

@media (width <= 1845px) {
  .road-widgets {
    grid-template-columns: repeat(2, 342.5px);
  }
}
</style>
