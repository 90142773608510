import { computed, reactive, Ref, ref } from 'vue'
import { useElementBounding } from '@vueuse/core'

export default function useDraggable(
  element: Ref<HTMLElement | undefined>,
  settings: { x?: number; y?: number },
  page?: Ref<HTMLElement | undefined>
) {
  const coors = reactive({ x: settings.x || 0, y: settings.y || 0 })
  const mousePosInElement = ref({
    x: 0,
    y: 0
  })
  const isDrag = ref(false)

  function start(e: MouseEvent) {
    const isDraggable = (e.target as HTMLElement).closest('[draggable]')

    if (!element.value || !isDraggable) return

    const { left, top } = useElementBounding(element.value)

    isDrag.value = true

    mousePosInElement.value.x = e.clientX - left.value
    mousePosInElement.value.y = e.clientY - top.value
  }

  function move(e: MouseEvent) {
    if (!isDrag.value || !page?.value || !element.value) return

    const elementPosition = window.getComputedStyle(element.value, null).position

    const scroll = elementPosition === 'fixed' ? 0 : page.value.scrollTop

    coors.x = e.clientX - mousePosInElement.value.x
    coors.y = e.clientY - mousePosInElement.value.y + scroll
  }

  function drop() {
    isDrag.value = false
  }

  document.addEventListener('mousedown', start)
  document.addEventListener('mousemove', move)
  document.addEventListener('mouseup', drop)

  function removeDrag() {
    document.removeEventListener('mousedown', start)
    document.removeEventListener('mousemove', move)
    document.removeEventListener('mouseup', drop)
  }

  return computed(() => ({
    style: {
      left: `${coors.x}px`,
      top: `${coors.y}px`
    },
    x: coors.x,
    y: coors.y,
    removeDrag
  }))
}
