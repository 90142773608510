import { useScroll } from '@vueuse/core'

export function routerScroll() {
  const page = document.querySelector('.page') as HTMLElement

  if (page) {
    const { y } = useScroll(page)
    y.value = 0
  }
}
