<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, Ref, ref, watch } from 'vue'

import { useDefectFilterStore } from '@/stores/road/road-defects-filter'
import { useRoadDefectsMapStore } from '@/stores/road/defects/road-defects-map'
import { useRoadStore } from '@/stores/road/road'
import { useDefectsStore } from '@/stores/road/road-defects'
import { useRoadDefectsPopupStore } from '@/stores/road/defects/road-defects-popup'
import { useChartStore } from '@/stores/chart'

import useMapDefects from '@/composition/defects/useMapDefects'
import useMapDefectsFeature from '@/composition/defects/useMapDefectsFeature'
import useChartDefects from '@/composition/defects/useChartDefects'

import AppMap from '@/components/layouts/AppMap.vue'

import { AppMapIds } from '@/utils/lists'
import { ROAD_DEFECTS_SOURCE_NAME } from '@/utils/consts'

import { MapRenderParams } from '@/types/Map'
import { Map, MapMouseEvent } from 'maplibre-gl'
import useDefectPopup from '@/composition/defects/useDefectPopup'

const emits = defineEmits(['map-clicked'])

const roadDefectsMapStore = useRoadDefectsMapStore()
const roadStore = useRoadStore()
const defectsStore = useDefectsStore()
const roadDefectsPopupStore = useRoadDefectsPopupStore()
const chartStore = useChartStore()
const roadDefectFilterStore = useDefectFilterStore()

const { hoverOnDefect, leaveMouseFromDefect, getDataForRendering, flyToMap } = useMapDefects()
const { mapDefectClick, highlightMapFeature, propertiesToObject } = useMapDefectsFeature()
const { eventOpeninigDefectPopup } = useDefectPopup()
const { defectToChartData } = useChartDefects()

const layers: Ref<MapRenderParams[]> = ref([])

const bboxRoad = computed(() => roadStore.bbox)
const renderData = computed(() => roadStore.renderData)
const defects = computed(() => defectsStore.defects)
const coordinates = computed(() => roadDefectsPopupStore.coordinates)
const showedDefects = computed(() => roadDefectFilterStore.defectTypes)

function hoverMapFeature(e: MapMouseEvent) {
  hoverOnDefect(e)
}

function leaveMapFeature(e: MapMouseEvent) {
  leaveMouseFromDefect(e)
}

onBeforeUnmount(() => {
  const map = roadDefectsMapStore.getMapInstance()

  map?.off('click', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, mapDefectClickWrapper)
  map?.off('mousemove', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, hoverMapFeature)
  map?.off('mouseleave', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, leaveMapFeature)
  map?.off('moveend', highlightMapFeatureWrapper)
  map?.off('idle', highlightMapFeatureWrapper)
})

/**
 * Карта cмонтирована (хук)
 */
async function mapMounted(e: Map) {
  const map = roadDefectsMapStore.getMapInstance()
  if (map) map.remove()

  roadDefectsMapStore.updateInstance(e)
  const mapUdated = roadDefectsMapStore.getMapInstance()

  layers.value = getDataForRendering({
    map: mapUdated,
    defects: defects.value,
    renderData: renderData.value
  })

  if (!mapUdated || !bboxRoad.value) return

  mapUdated.fitBounds(bboxRoad.value, {
    duration: 0
  })

  mapUdated.on('click', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, mapDefectClickWrapper)
  mapUdated.on('mousemove', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, hoverMapFeature)
  mapUdated.on('mouseleave', `${ROAD_DEFECTS_SOURCE_NAME}-circle`, leaveMapFeature)
  mapUdated.on('moveend', highlightMapFeatureWrapper)
  mapUdated.on('idle', highlightMapFeatureWrapper)
}

function highlightMapFeatureWrapper(e?: MapMouseEvent) {
  nextTick(() => highlightMapFeature(e))
}

/**
 * Клик по дефекту на карте
 */
function click(e: MapMouseEvent) {
  const chart = chartStore.getChartInstance()
  const map = roadDefectsMapStore.getMapInstance()

  if (!map || !chart) return

  mapDefectClick(e, map)

  const defectPopupData = eventOpeninigDefectPopup(coordinates.value)

  const [first] = map.queryRenderedFeatures(e.point)
  const defects = propertiesToObject(first.properties)
  defectPopupData.items = defects.map((el) => defectToChartData(el))

  defectsStore.setCurrentDefect(defects)

  roadDefectsPopupStore.updateDefectPopupData(defects)

  return defectPopupData
}

function mapDefectClickWrapper(e: MapMouseEvent) {
  const map = roadDefectsMapStore.getMapInstance()

  if (!map) return

  const event = click(e)
  const zoom = map.getZoom()

  if (zoom < 16) {
    flyToMap(map)
  }

  emits('map-clicked', event)
}

watch(
  [defects, showedDefects],
  () => {
    const map = roadDefectsMapStore.getMapInstance()
    if (!map) return

    flyToMap(map)
  },
  { deep: true }
)
</script>

<template>
  <div>
    <AppMap
      :id="AppMapIds.ROAD_DIAGNOSTIC_DEFECTS_PAGE"
      height="550px"
      :layers="layers"
      :map-options="{ maxZoom: 19, minZoom: 13.5 }"
      @map-mounted="mapMounted"
    />
  </div>
</template>

<style lang="scss" scoped></style>
