<script lang="ts" setup>
import { ref } from 'vue'

const icon = ref(null)

interface Props {
  viewBox: string
  width: number | string
  height: number | string
  iconColor: number | string
}

const props = withDefaults(defineProps<Partial<Props>>(), {
  viewBox: '0 0 18 18',
  width: 18,
  height: 18,
  iconColor: ''
})

defineExpose({ icon })
</script>

<template>
  <svg
    ref="icon"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :viewBox="props.viewBox"
  >
    <g :fill="(props.iconColor as string) || 'currentFill'">
      <slot />
    </g>
  </svg>
</template>

<style scoped lang="scss">
svg {
  display: inline-block;
  margin-bottom: -2px;
  vertical-align: baseline;
}
</style>
