<script setup lang="ts">
import InputField from '@/components/ui/InputField.vue'
import Button from '@/components/ui/Button.vue'
import IconChangeElement from '@/components/icons/IconChangeElement.vue'
import IconBase from '@/components/icons/IconBase.vue'
import { useUserStore } from '@/stores/user.ts'
import { ref, computed } from 'vue'
import { TUser } from '@/types/User'
import userApi from '@/api/user'

const userStore = useUserStore()
const user = computed(() => userStore.user)
let changedUser = { ...user.value } as TUser
const isEditing = ref(false)

const handleEditClick = () => {
  isEditing.value = !isEditing.value
  changedUser = { ...user.value } as TUser
}
const handleSave = async () => {
  await userApi.editUser(changedUser)
  await userStore.aboutMe()

  handleEditClick()
}
</script>

<template>
  <div class="name">
    <div class="name__title">
      <p>Изменить профиль</p>
      <div class="name__toggleBtn" @click="handleEditClick">
        <div class="name__toggleBtn__icon">
          <IconBase width="25" height="25" view-box="7 7 25 25">
            <IconChangeElement />
          </IconBase>
        </div>
      </div>
    </div>
    <div v-if="!isEditing" class="name__fields">
      <div class="name__lastName">
        <label>Фамилия</label>
        <p>{{ user?.['last_name'] }}</p>
      </div>
      <div class="name__firstName">
        <label>Имя</label>
        <p>{{ user?.['first_name'] }}</p>
      </div>
      <div class="name__middleName">
        <label>Отчество</label>
        <p>{{ user?.['middle_name'] }}</p>
      </div>
    </div>
    <div v-if="isEditing" class="name__fields">
      <div class="name__lastName">
        <label>Фамилия</label>
        <InputField
          v-model="changedUser.last_name"
          :has-error="false"
          :type="'text'"
          placeholder="Фамилия"
        ></InputField>
      </div>
      <div class="name__firstName">
        <label>Имя</label>
        <InputField
          v-model="changedUser.first_name"
          :has-error="false"
          :type="'text'"
          placeholder="Имя"
        ></InputField>
      </div>
      <div class="name__patrName">
        <label>Отчество</label>
        <InputField
          v-model="changedUser.middle_name"
          :has-error="false"
          :type="'text'"
          placeholder="Отчество"
        ></InputField>
      </div>
    </div>
    <div v-if="isEditing" class="name__buttons">
      <Button text="Сохранить" type="submit" @click="handleSave" />
      <Button class="hollow" text="Отмена" type="reset" @click="handleEditClick" />
    </div>
  </div>
</template>

<style lang="scss">
.name {
  @include background-color('widgetBG');
  @include color('fontColor');

  &__title {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 15px 30px;
    border-bottom: 1px solid #686868;

    p {
      font-family: $f-family-base;
      font-weight: 600;
      font-size: 1.12em;
    }
  }

  &__fields {
    display: grid;
    grid-auto-flow: column;
    column-gap: 100px;
    margin: 30px;

    label {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 12px 5px;
      color: $c-gray-65;
      font-family: $f-family-base;
      font-size: 0.8rem;
    }
    input {
      border-width: 2px;
      border-color: $c-gray-65;
      max-height: 50px;
      font-size: 1.2rem;
    }
    p {
      padding: 0px 0px 12px 5px;
      @include color('fontColor');
      font-family: $f-family-base;
      font-size: 1.2rem;
    }
  }
  &__toggleBtn {
    &__icon {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      transition: all 0.2s $main-animation 0.1s;
      border-radius: 100%;

      @include background-color('headerNavIconBG');

      cursor: pointer;

      svg {
        transition: all 0.2s $main-animation;
      }

      &:hover {
        transition: all 0.2s $main-animation;

        @include background-color('headerNavIconHover');
      }
    }
  }
  &__buttons {
    display: grid;
    grid-auto-flow: column;
    column-gap: 30px;
    justify-content: center;

    margin: 30px;
    padding-top: 10px;

    .hollow {
      color: $c-orange-light;
      background: none;
      border: 3px solid;
      &:hover,
      &:focus,
      &:active {
        @include color('fontColor');
      }
    }
  }
}
</style>
