import { ResponseRoadWay, RoadWay, RoadWayElement, TransformLandPlot } from '@/types/Road.d'
import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'
import useRoadWayData from '@/composition/useRoadWayData'
import { RoadObjectsTypeId } from '@/utils/lists'

import { RoadWayState } from '@/types/Road'

const roadWayData = new useRoadWayData()

const PAGE_NUMBER = 1
const ELEMENTS_ON_PAGE = 50

const query = `page_number=${PAGE_NUMBER}&elements_on_page=${ELEMENTS_ON_PAGE}`

export const useRoadWayStore = defineStore('road-way', () => {
  const state: RoadWayState = reactive({
    data: {
      landPlot: [],
      square: undefined,
      lane: [],
      width: [],
      clothes: [],
      roadway: [],
      settlement_urban_type_string: '',
      settlement_region_name: '',
      settlement_name: ''
    }
  })

  const roadWayLane = computed(() => state.data.lane)
  const roadWayWidth = computed(() => state.data.width)
  const roadWayClothes = computed(() => state.data.clothes)
  const lanes = computed(() => {
    const laneCounters = state.data.lane.map((el) => {
      const counter = +el.at(0)!

      if (counter) return isNaN(counter) ? undefined : +counter

      return undefined
    })

    const currentLanes = laneCounters.filter((el) => el)

    const lanes = [...new Set(currentLanes)]

    lanes.sort((a, b) => a! - b!)

    return lanes.length > 0 ? lanes.join(';') : null
  })
  const widthAvg = computed(() => {
    const summ = state.data.width.map((el) => el[0])

    if (summ.length) {
      const width = summ.reduce((initial, current) => initial + +current, 0)

      const quantity = state.data.width.length

      return +(width / quantity).toFixed(2)
    }

    return undefined
  })
  const widthMinMax = computed(() => {
    const widths = state.data.width.map((el) => el[0])

    if (widths.length) {
      const floatWidths = widths.map((width) => parseFloat(width))

      const min = Math.min(...floatWidths).toFixed(2)
      const max = Math.max(...floatWidths).toFixed(2)

      if (min == max) {
        return min.toString()
      } else {
        return `${min} - ${max}`
      }
    }

    return undefined
  })
  const clothes = computed(() => {
    return state.data.clothes[0]?.[0]
  })
  const square = computed(() => state.data.square)
  const owner = computed(() => state.data.organization_name)
  const getDistrict = computed(() => state.data.settlement_raion_name)
  const getLocality = computed(() => state.data.settlement_name)
  const getRegion = computed(() => state.data.settlement_region_name)
  const getData = computed(() => state.data)

  async function fetchRoadWay(roadId: number) {
    try {
      const fetchRoadInfo = (await Promise.all([
        fetchWrapper.get(`/roads/${roadId}/objects/${RoadObjectsTypeId.ROADWAY}?${query}`, {}),
        fetchWrapper.get(`/roads/${roadId}/objects/${RoadObjectsTypeId.LAND_LOTS}?${query}`, {}),
        fetchWrapper.get(`/roads/${roadId}/objects/${RoadObjectsTypeId.ROAD_EXIT}?${query}`, {})
      ])) as (TransformLandPlot | ResponseRoadWay)[]

      roadWayData.transformRoadWayData(fetchRoadInfo, updateLandPlot, updatePartState)

      setStoreBySegmentProperty(fetchRoadInfo.at(1) as ResponseRoadWay, 'organization_name')

      setStoreBySegmentProperty(fetchRoadInfo.at(2) as ResponseRoadWay, 'settlement_raion_name')
      setStoreBySegmentProperty(fetchRoadInfo.at(2) as ResponseRoadWay, 'settlement_region_name')
      setStoreBySegmentProperty(fetchRoadInfo.at(2) as ResponseRoadWay, 'settlement_name')

      state.data.square = calcSquare(fetchRoadInfo.at(0) as ResponseRoadWay)
    } catch (e) {
      throw new Error(e)
    }
  }

  function calcSquare(response: Partial<ResponseRoadWay>) {
    return (
      response.data
        ?.reduce((initial, current) => initial + +current.data.square, 0)
        .toLocaleString() || ''
    )
  }

  function setStoreBySegmentProperty(arr: ResponseRoadWay, key: string) {
    const data: (string | number | RoadWayElement | null | undefined)[] = []

    for (let i = 0; i < arr.data.length; i++) {
      const element = arr.data[i] as RoadWay

      if (element[key]) {
        data.push(element[key])
      }
    }

    state.data[key] = [...new Set(data)].join(', ')
  }

  function updateLandPlot(landPlot: TransformLandPlot) {
    state.data.landPlot = landPlot.data
  }

  function updatePartState(field: string, data: (string | number)[][]) {
    state.data[field] = data
  }

  return {
    roadWayLane,
    roadWayWidth,
    roadWayClothes,
    lanes,
    widthAvg,
    widthMinMax,
    clothes,
    square,
    owner,

    getDistrict,
    getLocality,
    getRegion,
    getData,

    fetchRoadWay
  }
})
