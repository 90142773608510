import { TransformLandPlot, ResponseRoadWay, RoadWay } from '@/types/Road.d'
import { stringToNumber } from '@/utils/string'

enum WidgetFields {
  LANE_COUNT = 'lane_count',
  MEDIUM_WODTH = 'medium_width',
  COVER_TYPE_NAME = 'cover_type_name'
}

enum WidgetState {
  LANE = 'lane',
  WIDTH = 'width',
  CLOTHES = 'clothes'
}

class RoadWayData {
  fixedExceptions = ['lane_count']

  transformRoadWayData(
    response: Array<ResponseRoadWay | TransformLandPlot>,
    updateLandPlot: (landPlot: TransformLandPlot) => void,
    updatePartState: (field: string, data: (string | number)[][]) => void
  ) {
    const [roadway, landPlot] = response

    if (roadway) {
      this.transformResponseRoadWay(roadway as ResponseRoadWay, updatePartState)
    }

    if (landPlot) {
      updateLandPlot(landPlot as TransformLandPlot)
    }
  }

  transformResponseRoadWay(
    roadway: ResponseRoadWay,
    updatePartState: (field: string, data: (string | number)[][]) => void
  ) {
    const fields = Object.values(WidgetFields)
    const stateNames = Object.values(WidgetState)

    fields.forEach((field, index) => {
      const stateName = stateNames[index]

      const data = roadway.data.map((segment) => this.responseFieldToStateData(segment, field))

      updatePartState(stateName, data)
    })
  }

  responseFieldToStateData(segment: RoadWay, field: string) {
    const valueToNumber = stringToNumber(segment.data[field])

    const value =
      typeof valueToNumber === 'number' && !this.fixedExceptions.includes(field)
        ? valueToNumber.toFixed(2)
        : valueToNumber

    const start = (+segment.start / 1000).toFixed(2)
    const end = (+segment.end / 1000).toFixed(2)

    return [value, start, end]
  }
}

export default RoadWayData
