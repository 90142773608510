<script setup lang="ts">
import { computed, ref, reactive, watch } from 'vue'
import InputField from '@/components/ui/InputField.vue'
import RoadItems from '@/components/layouts/profile/Import/RoadItems.vue'
import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import Button from '@/components/ui/Button.vue'
import AppPagination from '@/components/ui/AppPagination.vue'
import { ImportPaginator } from './importPaginator'
import {
  Road,
  ProcessedRoad,
  IRoadSearchFilter,
  fetchRoads,
  multiSubmit,
  updateQueue
} from './importPage'


const FILTER: IRoadSearchFilter = reactive({
  name: ''
})

const paginator = reactive(new ImportPaginator())

const data: Road[] = reactive([])
await getRoads(FILTER)
console.log(data)

const staged: Road[] = reactive([])
const processing: ProcessedRoad[] = reactive([])
const isProcessing = computed(() => {
  return (
    processing.length > 0 && !processing.every((road) => road.queue_data.status === 'COMPLETED')
  )
})

const intervalSeconds = 1
let updateIntervalId: any = 0

watch(isProcessing, (newval) => {
  if (!newval) {
    clearInterval(updateIntervalId)
  }
})

const showStaged = ref(false)
const submitDisabled = computed(() => !staged.length || isProcessing.value || processing.length > 0)

watch(showStaged, (newval) => {
  if (newval) {
    paginator.setData(staged)
  } else {
    paginator.setData(data)
  }
})

async function getRoads(filter: IRoadSearchFilter): Promise<void> {
  await fetchRoads(filter)
    .then((res) => {
      // удаляем пустые
      res = res.filter((r) => !!r.name)
      // показываем сначала новые
      res.sort((a, b) => b.id_indoroad - a.id_indoroad)

      data.length = 0
      data.push(...res)
      paginator.setData(data)
    })
    .catch((err) => console.error(err))
}

async function handleSubmit() {
  showStaged.value = true
  const submitted = await multiSubmit(staged)
  processing.push(...(await updateQueue(submitted)))
  updateIntervalId = setInterval(updateProcessed, intervalSeconds * 1000)
}

async function updateProcessed() {
  const newRoads = await updateQueue(processing)
  processing.length = 0
  processing.push(...newRoads)
}

function toggleRoadStaged(id: number) {
  const road = data.find((r) => r.id_indoroad == id)

  if (road) {
    if (staged.includes(road)) {
      // unstage
      staged.splice(staged.indexOf(road), 1)
    } else {
      staged.push(road)
    }
  }
}

async function refetch() {
  await getRoads(FILTER)
}

async function clearStaged() {
  staged.length = 0
  processing.length = 0
  showStaged.value = false
  await getRoads(FILTER)
}
</script>

<template>
  <div class="import">
    <InputField
      class="import__search"
      :placeholder="'Название дороги'"
      v-model="FILTER.name"
      @on-change="refetch"
    />
    <div class="import__controls">
      <AppCheckbox
        class="import__controls-item import__controls-item--show-staged"
        :checked="showStaged"
        @toggle-checkbox="showStaged = !showStaged"
        :name="'Показать только выбранные дороги'"
      />
      <div class="import__controls-buttons">
        <Button
          class="import__controls-buttons-item import__controls-buttons-item--hollow"
          text="Очистить"
          type="reset"
          @click="clearStaged"
        />
        <Button
          class="import__controls-buttons-item import__controls-buttons-item--submit"
          text="Применить"
          type="submit"
          @click="handleSubmit"
          :disabled="submitDisabled"
        />
      </div>
    </div>
    <div class="import__road-items">
      <RoadItems
        :roads="paginator.pagination"
        :staged="staged"
        :processing="processing"
        @toggle-road-staged="toggleRoadStaged"
      />
      <AppPagination
        class="import__road-items--pagination"
        :current="paginator.currPage"
        :left="5"
        :max="paginator.pages"
        :navigation="true"
        @change-page="(pgn) => paginator.changePage(pgn)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.import {
  @include color('fontColor');

  &__road-items {
    width: 100%;

    &--pagination {
      padding-top: 10px;
    }
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    column-gap: 30px;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px 0px;

    &-buttons {
      display: grid;
      grid-auto-flow: column;
      column-gap: 10px;

      &-item {
        &--hollow {
          @include color('fontColor');
          @include background-color('widgetBG');
          border: 3px solid !important;
        }
      }
    }
  }
}
</style>
