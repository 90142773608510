<script lang="ts" setup>
import { onMounted, reactive, computed } from 'vue'

import IconBase from '@/components/icons/IconBase.vue'
import IconClose from '@/components/icons/IconClose.vue'

const props = defineProps<{
  type: 'success' | 'error' | 'info' | 'warning'
  title: string
  text: string
  duration: number
}>()

const duration = computed(() => (props.duration === -1 ? props.text.length * 100 : props.duration))

const toast = reactive({
  show: true,
  timer: () => {
    return setTimeout(() => {
      toast.show = false
    }, duration.value)
  }
})

const closeToast = () => {
  toast.show = false
  clearTimeout(toast.timer())
}

onMounted(() => {
  toast.timer()
})
</script>

<template>
  <transition name="slide-fade">
    <div v-show="toast.show" :class="'toast--' + type" class="toast">
      <div class="toast__inner">
        <div class="toast__close" @click="closeToast">
          <icon-base height="13" icon-color="white" view-box="0 0 13 13" width="13">
            <icon-close />
          </icon-base>
        </div>
        <div class="toast__title">{{ title }}</div>
        <div class="toast__text">{{ text }}</div>
        <div class="toast__progress">
          <div :style="{ 'animation-duration': duration + 'ms' }" class="toast__progressLine"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.toast {
  margin-top: 1rem;

  &__inner {
    position: relative;
    min-width: 25rem;
    max-width: 25rem;
    padding: 1.5rem;
    opacity: 1;
    color: $c-white;
  }

  &--info {
    background-color: rgba(#a7a7a7, 0.4);

    .toast__progressLine {
      background-color: #a7a7a7;
    }
  }

  &--warning {
    background-color: rgba(#d6b666, 0.4);

    .toast__progressLine {
      background-color: #d6b666;
    }
  }

  &--success {
    background-color: rgba(#66d66a, 0.4);

    .toast__progressLine {
      background-color: #66d66a;
    }
  }

  &--error {
    background-color: rgba(#d68166, 0.4);

    .toast__progressLine {
      background-color: #d68166;
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    transition: opacity 0.5s;

    &:hover {
      transition: opacity 0.5s;
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__title {
    margin-bottom: 1rem;
    font-family: $f-family-caption;
    font-weight: bold;
  }

  &__text {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }

  &__progress {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.3rem;
    background-color: rgba($c-white, 0.2);
  }

  &__progressLine {
    width: 100%;
    height: 100%;

    //animation-duration: 3s;
    animation-name: progress-down;
    animation-iteration-count: 1;
    background-color: $c-white;
    animation-fill-mode: forwards;
  }
}

@keyframes progress-down {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
