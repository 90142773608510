<script setup lang="ts">
import InputField from '@/components/ui/InputField.vue'
import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import AppSelect from '@/components/ui/AppSelect.vue'
import Button from '@/components/ui/Button.vue'
import { computed, reactive, ref } from 'vue'
import { useTerritoriesStore } from '@/stores/territories'
import { fetchWrapper } from '@/api/fetchApi'

type TNewUser = {
  last_name: string | null
  first_name: string | null
  middle_name: string | null
  is_admin: boolean | undefined

  territory_id: number | null

  username: string | null
  password: string | null
  confirm_password: string | null

  error: string | null
  success: boolean
  lock: boolean
}

const newUserBase: TNewUser = {
  last_name: '',
  first_name: '',
  middle_name: '',
  is_admin: false,

  territory_id: null,

  username: '',
  password: '',
  confirm_password: '',

  error: null,
  success: false,
  lock: false
}
const newUser = reactive({ ...newUserBase })

const terrStore = useTerritoriesStore()

const onSubmit = async (): Promise<void> => {
  newUser.confirm_password = newUser.password
  newUser.lock = true

  await fetchWrapper
    .post('/users/register/', newUser)
    .then(() => {
      newUser.error = null
      showSuccess()
      newUser.lock = false
    })
    .catch((error) => {
      newUser.error = error
      newUser.lock = false
    })
}

const changeTerritory = () => {
  newUser.territory_id = currTerritory.value.id
}

const toggleAdmin = (e: InputEvent) => {
  const checked = (e.target as HTMLInputElement).checked
  newUser.is_admin = checked
}

const disabled = computed<boolean>(() => {
  return (
    newUser.lock ||
    !newUser.first_name ||
    !newUser.last_name ||
    !newUser.username ||
    !newUser.password
  )
})

const showSuccess = () => {
  // clear fields
  Object.assign(newUser, newUserBase)
  newUser.success = true
  setTimeout(() => {
    newUser.success = false
  }, 5000)
}

await terrStore.fetchAll()
const currTerritory = ref(terrStore.asOptions[0])
newUser.territory_id = currTerritory.value.id
</script>

<template>
  <form class="createUser" @submit.prevent="onSubmit">
    <div class="user-personal">
      <InputField
        v-model="newUser.last_name"
        :type="'text'"
        :placeholder="'Фамилия'"
      ></InputField>
      <InputField
        v-model="newUser.first_name"
        :type="'text'"
        :placeholder="'Имя'"
      ></InputField>
      <InputField
        v-model="newUser.middle_name"
        :type="'text'"
        :placeholder="'Отчество'"
      ></InputField>
      <div class="user-personal__checkboxes">
        <AppCheckbox
          :name="'Права администратора'"
          :checked="newUser.is_admin"
          @toggle-checkbox="(e) => toggleAdmin(e)"
        />
      </div>
    </div>
    <div class="user-territory">
      <AppSelect
        class="user-territory__select"
        :options="terrStore.asOptions"
        v-model="currTerritory"
        :placeholder="'Название территории'"
        @update:model-value="changeTerritory"
      />
    </div>
    <div class="user-account">
      <InputField
        v-model="newUser.username"
        :type="'text'"
        :has-error="!!newUser.error"
        :placeholder="'Логин'"
      ></InputField>
      <InputField
        v-model="newUser.password"
        :type="'password'"
        :placeholder="'Пароль'"
        autocomplete="new-password"
      ></InputField>
      <div class="user-account__buttons">
        <Button text="Создать" type="submit" :disabled="disabled" />
      </div>
      <div v-if="newUser.error" class="user-account user-account__error">
        {{ newUser.error }}
      </div>
      <Transition name="fade">
        <div v-if="newUser.success" id="success" class="user-account user-account__success">
          <p>Пользователь добавлен</p>
        </div>
      </Transition>
    </div>
  </form>
</template>

<style lang="scss">
.createUser {
  @include color('fontColor');
  @include background-color('widgetBG');
  border: 1px solid #686868;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    padding: 30px;

    &:not(:last-child) {
      border-bottom: 1px solid #686868;
    }
  }

  input {
    margin: 20px 0 20px 0;
    font-family: $f-family-base;
    font-size: 1.12rem;
    font-weight: 600;
  }
}
.user-personal {
  &__checkboxes {
    display: grid;
    grid-auto-flow: row;
    align-content: space-evenly;
  }
}

.user-territory {
  margin-bottom: 12px;

  &__select .select__input {
    height: auto;
  }
}

.user-account {
  &__buttons {
    margin-top: 30px;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: 1rem 2rem;
    background-color: rgba($c-orange, 0.3);
    font-weight: 700;
  }

  &__success {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: 1rem 2rem;
    background-color: rgba(lightgreen, 0.3);
    font-weight: 700;

    .fade-leave-active,
    .fade-enter-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
}
</style>
