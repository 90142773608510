import useUnits from '@/composition/useUnits'
import useDefects from '@/composition/defects/useDefects'

import { l } from '@/utils/lang'

import type {
  Defect,
  DefectTypeMaskStyle,
  OptionsMask,
  RoadNeiroDefect,
  ShowDefectItem
} from '@/types/RoadDiagnostic'
import type {
  ChartData,
  ChartDataLines,
  ChartPoint,
  DataTypes,
  ToChartDataParams
} from '@/types/Chart'
import type { ECElementEvent, ECharts, SeriesOption } from 'echarts'
import type { ComputedRef } from 'vue'

const { updateDefectInfo, defectsToInfo } = useDefects()
const { fromSmToSqM } = useUnits()

/**
 * Определяем графики(название, количество) в диаграмме
 */
function getInitialData(names: string[]) {
  const chart: DataTypes = {}

  names.forEach((name) => {
    chart[name] = [{ line: l(name) }]
  })

  return chart
}

/**
 * Получение координат дефекта в графике
 */
function getInfoCoordinates(info: Defect) {
  const name = info.class_name

  const coors = {
    line: l(info.class_name),
    coors: [+(info.position || 0), fromSmToSqM(+info.sum_square)]
  }

  return [name, coors] as [string, ChartDataLines]
}

/**
 * Преобразование данных дефектов в данные для диаграммы
 * */
function dataToChart(params: ToChartDataParams, roadId: number): ChartData {
  const { defects, names, currentSegment } = params

  if (!defects?.length)
    return { name: currentSegment.name, segmentId: currentSegment.id, lines: [] }

  const initial = getInitialData(names)
  const defectsInfo = defectsToInfo(updateDefectInfo(defects, roadId))

  defectsInfo.forEach((info) => {
    const [name, coors] = getInfoCoordinates(info)

    initial[name].push(coors)
  })

  return {
    name: currentSegment.name,
    segmentId: currentSegment.id,
    lines: [...Object.values(initial)]
  }
}

/**
 * Преобразование дефекта в данные для выделения
 */
function defectToChartData(item: Defect) {
  return {
    data: [+(item.position || 0), fromSmToSqM(item.sum_square)],
    seriesName: l(item.class_name)
  }
}

/**
 * Показать/скрыть графики
 */
function toggleSelectChartLegend(val: ShowDefectItem[], chart: ECharts) {
  if (!chart) throw new Error('Нет экземпляра графика')

  val.forEach((item) => toggleChartLegend(item, chart))
}

/**
 * Показать/скрыть график(один)
 */
function toggleChartLegend(item: ShowDefectItem, chart: ECharts) {
  chart.dispatchAction({
    type: item.show ? 'legendSelect' : 'legendUnSelect',
    name: l(item.name)
  })
}

/**
 * Отфильтровываем дефекты без позиции
 */
function existDefectsChartData(defects: Defect[]) {
  const data = defects.filter((item) => item.position)
  const existed = data.map((el) => defectToChartData(el))

  return existed as ChartPoint[]
}

/**
 * Получение данных для легенды графика
 */
function defectLegends(
  defectTypes: ComputedRef<DefectTypeMaskStyle[]>,
  style: ComputedRef<OptionsMask[]>
) {
  const legends = defectTypes.value.filter((el) => el.show)

  return legends.map((el) => ({
    name: el.label,
    id: el.id,
    color: style.value?.find((item) => item.id === el.id)?.color
  }))
}

/**
 * Найти индекс точки на графике
 */
function fromChartPointToSeriesItem(el: ChartPoint, series: SeriesOption[]) {
  const {
    data: [position, square],
    seriesName
  } = el

  const currentSeries = series.find((series) => series.name === seriesName)
  if (!currentSeries) throw new Error('Не найдено нужного графика')

  const coordinates = currentSeries?.data as (number[] | undefined)[]
  const dataIndex = coordinates?.findIndex(
    (coor) => coor?.at(0) === position && coor?.at(1) === square
  )

  return {
    dataIndex,
    seriesName
  }
}

/**
 * Получение данных для выделенных точек на графике
 */
function currentDefectsOnChart(e: Partial<ECElementEvent>) {
  const point: ChartPoint = {
    data: eventDataToChartCoordinates(e),
    seriesName: e.seriesName
  } as ChartPoint

  return [point]
}

/**
 * Получение координат выделенной точки на графике
 */
function eventDataToChartCoordinates(e: Partial<ECElementEvent>) {
  return Array.isArray(e.data) ? e.data : []
}

/**
 * Функция поиска текущего дефекта
 */
function defineChartDefect(defects: RoadNeiroDefect[], data: ChartPoint[]) {
  const defectsList = defectsToInfo(defects)

  const current = defectsList.filter((defect) => {
    return data.find(
      (item) =>
        item.data[1] === fromSmToSqM(defect.sum_square) &&
        item.data[0] === defect.position &&
        l(item.seriesName, true) === defect.class_name
    )
  })

  return current
}

export default function () {
  return {
    getInitialData,
    getInfoCoordinates,
    defectToChartData,
    dataToChart,
    toggleSelectChartLegend,
    toggleChartLegend,
    existDefectsChartData,
    defectLegends,
    fromChartPointToSeriesItem,
    currentDefectsOnChart,
    eventDataToChartCoordinates,
    defineChartDefect
  }
}
