import { ECElementEvent, SeriesOption } from 'echarts'

import { useChartStore } from '@/stores/chart'
import { useDefectsStore } from '@/stores/road/road-defects'

import useChartDefects from './useChartDefects'
import { ChartPoint, SelectedChartItem } from '@/types/Chart'
import { Defect } from '@/types/RoadDiagnostic'

const { currentDefectsOnChart, defineChartDefect, fromChartPointToSeriesItem } = useChartDefects()

/**
 * Определяем выбранный дефект и его позицию в графике
 */
function chartDefectClick(e: Partial<ECElementEvent>) {
  const defectsStore = useDefectsStore()
  const chartStore = useChartStore()
  const defectsOnChart = (e.items as ChartPoint[]) || currentDefectsOnChart(e)

  const chart = chartStore.getChartInstance()
  if (!chart) return []

  const { series } = chart.getOption()

  const selected = defectsOnChart.map((el) =>
    fromChartPointToSeriesItem(el, series as SeriesOption[])
  )

  const defect = defineChartDefect(defectsStore.defects, defectsOnChart)
  return [selected, defect] as [SelectedChartItem[], Defect[]]
}

export default function () {
  return {
    chartDefectClick
  }
}
