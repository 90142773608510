import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

interface PreloaderState {
  isLoading: boolean
  timeout: number | null
  progress: number
}

const PRELOADER_TIMEOUT = 400

export const usePreloaderStore = defineStore('preloader-store', () => {
  const state: PreloaderState = reactive({
    isLoading: false,
    timeout: null,
    progress: 0
  })

  const isLoading = computed(() => state.isLoading)
  const progress = computed(() => state.progress)

  function togglePreloader() {
    if (state.timeout) {
      clearTimeout(state.timeout)
      state.timeout = null
      state.isLoading = false

      return
    }

    setProgress(0)

    state.timeout = setTimeout(() => {
      state.isLoading = true
    }, PRELOADER_TIMEOUT) as unknown as number
  }

  function togglePreloaderWithoutDelay() {
    if (!state.isLoading) {
      setProgress(0)
    }

    state.isLoading = !state.isLoading
  }

  function setProgress(progress: number) {
    state.progress = progress
  }

  return {
    progress,
    isLoading,
    setProgress,
    togglePreloader,
    togglePreloaderWithoutDelay
  }
})
