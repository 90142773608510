<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24.7006 17.4489C24.7006 17.0078 25.0581 16.6499 25.4995 16.6499C25.941 16.6499 26.2985 17.0078 26.2985 17.4489V20.0788C26.2985 20.5199 25.941 20.8778 25.4995 20.8778C25.058 20.8778 24.7006 20.5196 24.7006 20.0788V17.4489Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24.7006 30.9199C24.7006 30.4789 25.0581 30.1209 25.4995 30.1209C25.9411 30.1209 26.2985 30.4792 26.2985 30.9199V33.5499C26.2985 33.991 25.941 34.3489 25.4995 34.3489C25.0581 34.3489 24.7006 33.991 24.7006 33.5499V30.9199Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.2424 19.2424C19.5545 18.9303 20.0603 18.9303 20.3724 19.2424L22.2323 21.1022C22.5444 21.4143 22.5444 21.9202 22.2323 22.2323C22.076 22.3885 21.8709 22.4661 21.6672 22.4661C21.4637 22.4661 21.2583 22.3886 21.102 22.2323L19.2424 20.3724C18.9303 20.0603 18.9303 19.5545 19.2424 19.2424Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M29.8975 28.7675L31.7575 30.6273C32.0696 30.9394 32.0697 31.4452 31.7576 31.7573C31.6013 31.9136 31.3961 31.9912 31.1924 31.9912C30.989 31.9912 30.7836 31.9137 30.6273 31.7573L28.7675 29.8975C28.4554 29.5854 28.4554 29.0796 28.7675 28.7675C29.0796 28.4554 29.5854 28.4554 29.8975 28.7675Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22.4568 28.7017C22.4827 28.7218 22.5075 28.7438 22.5313 28.7675C22.8434 29.0796 22.8434 29.5854 22.5313 29.8975L20.6715 31.7573C20.5152 31.9136 20.3101 31.9912 20.1064 31.9912C19.903 31.9912 19.6976 31.9137 19.5412 31.7573C19.2292 31.4453 19.2291 30.9394 19.5412 30.6273L21.4011 28.7675C21.6741 28.4945 22.0956 28.4603 22.4058 28.665L22.4568 28.7017Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M30.9272 19.2424C31.2393 18.9303 31.7451 18.9303 32.0572 19.2424C32.3693 19.5545 32.3693 20.0603 32.0572 20.3724L30.1974 22.2323C30.0411 22.3885 29.836 22.4661 29.6323 22.4661C29.429 22.4661 29.2234 22.3886 29.0672 22.2321C28.7553 21.92 28.7553 21.4143 29.0674 21.1022L30.9272 19.2424Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M30.122 25.4995C30.122 25.0585 30.4795 24.7006 30.921 24.7006H33.5509C33.9921 24.7006 34.3499 25.0584 34.3499 25.4995C34.3499 25.9406 33.9924 26.2985 33.5509 26.2985H30.921C30.4795 26.2985 30.122 25.9406 30.122 25.4995Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.6499 25.4995C16.6499 25.0585 17.0074 24.7006 17.4489 24.7006H20.0788C20.5203 24.7006 20.8778 25.0585 20.8778 25.4995C20.8778 25.9404 20.5204 26.2987 20.0788 26.2985H17.4489C17.0074 26.2985 16.6499 25.9406 16.6499 25.4995Z"
    fill="currentFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.483 25.5129C21.483 23.256 23.3182 21.4207 25.5752 21.4207C27.8322 21.4207 29.6674 23.256 29.6674 25.5129C29.6674 27.7699 27.8322 29.6051 25.5752 29.6051C23.3182 29.6051 21.483 27.7699 21.483 25.5129Z"
    fill="currentFill"
  />
</template>
