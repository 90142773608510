<script setup></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7042 14.3L15.3 16.8958V14.3H20C21.2703 14.3 22.3 13.2703 22.3 12V4C22.3 2.72975 21.2703 1.7 20 1.7H4C2.72975 1.7 1.7 2.72975 1.7 4V12C1.7 13.2703 2.72975 14.3 4 14.3H12.7042ZM17 21L12 16H4C1.79086 16 0 14.2091 0 12V4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V12C24 14.2091 22.2091 16 20 16H17V21Z"
    fill="currentFill"
  />
  <path
    d="M9 8C9 8.55229 8.55229 9 8 9C7.44772 9 7 8.55229 7 8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8Z"
    fill="currentFill"
  />
  <path
    d="M13 8C13 8.55229 12.5523 9 12 9C11.4477 9 11 8.55229 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
    fill="currentFill"
  />
  <path
    d="M17 8C17 8.55229 16.5523 9 16 9C15.4477 9 15 8.55229 15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8Z"
    fill="currentFill"
  />
</template>

<style lang="scss" scoped></style>
