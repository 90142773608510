<script lang="ts" setup>
import { computed, provide, ref } from 'vue'

import { useRoute } from 'vue-router'

import { usePreloaderStore, useThemeStore } from '@/stores'

import AppBackgroundMain from '@/components/layouts/background/AppBackgroundMain.vue'
import AppToasts from '@/components/ui/AppToasts.vue'
import AuthPage from './pages/AuthPage.vue'
import AppWrapper from './components/AppWrapper.vue'
import AppPreloader from '@/components/ui/AppPreloader.vue'

const route = useRoute()

const themeStore = useThemeStore()
const preloaderStore = usePreloaderStore()

const theme = computed(() => themeStore.theme)
const loginPage = computed<undefined | boolean>(() => {
  if (route.name === undefined) return

  return route.path === '/login'
})
const isLoading = computed(() => preloaderStore.isLoading)

const page = ref()

provide('pageWrapper', page)
</script>

<template>
  <div class="app custom-scroll" :class="theme">
    <div>
      <Transition name="fade">
        <AppPreloader v-show="isLoading"></AppPreloader>
      </Transition>
      <div v-show="!isLoading">
        <AppBackgroundMain :customclass="'main'" />
        <div
          ref="page"
          class="page custom-scroll"
          :class="{ 'hide-scroll': route.name === 'Geometry' }"
        >
          <AuthPage v-if="loginPage"></AuthPage>
          <AppWrapper v-else />
        </div>
        <AppToasts />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container.header-container {
  max-width: 100vw;
}
</style>

<style scoped lang="scss">
.app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.page {
  position: relative;
  z-index: 4;
  height: 100svh;
  overflow-y: auto;
}

.delimiter {
  height: 1px;
  border: 0;
  background-color: $c-gray-40;
}

.hide-scroll {
  overflow: hidden;
}
</style>
