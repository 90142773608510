<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, inject, Ref } from 'vue'

import { useElementBounding } from '@vueuse/core'

import IconBase from '@/components/icons/IconBase.vue'
import IconsSelect from '@/components/icons/IconSelect.vue'
import BackgroundContextMenu from '../layouts/background/BackgroundContextMenu.vue'

import { CustomSelect, PropsSelect, SelectOption } from '@/types/Select'

const props = withDefaults(defineProps<PropsSelect>(), {
  placeholder: '',
  search: false
})

const pageWrapper: Ref<HTMLElement> | undefined = inject('pageWrapper')

const emit = defineEmits(['update:modelValue'])
const options = ref<Array<SelectOption>>([])

const isOpen = ref(false)
const readonly = computed(() => (props.search ? '' : 'readonly'))
const selectClasses = computed(() => ({
  opened: isOpen.value
}))
const selectEl = ref(null)
const listOptionsEl = ref()
const input = ref(null)
const id = ref(Math.random() * 1_000_000)

const setListWidth = () => {
  if (!(listOptionsEl.value && selectEl.value)) return

  const listOption = listOptionsEl.value as HTMLElement
  const select = selectEl.value as HTMLElement

  setTopCoors()

  listOption.style.width = `${select.offsetWidth}px`
}

const setTopCoors = () => {
  const listOptions: HTMLElement | null = listOptionsEl.value
  const select: HTMLElement | null = selectEl.value

  if (!(listOptions && select)) return

  const { bottom, left } = useElementBounding(selectEl.value)

  listOptions.style.top = bottom.value + 3 + 'px'
  listOptions.style.left = left.value + 'px'
}

onBeforeMount(() => {
  window.addEventListener('resize', setListWidth)
  document.addEventListener('click', closeDropDown)

  if (pageWrapper?.value) pageWrapper.value.addEventListener('scroll', setTopCoors)
})

onMounted(() => {
  if (!input.value) return

  const select = (input.value as HTMLElement).closest('.select')

  if (!select) return
  ;(select as CustomSelect).closeDropDown = closeDropDown
})

function closeOtherSelects() {
  const selects = [...document.querySelectorAll('.select')] as Array<CustomSelect>

  const otherSelects = selects.filter((el) => el.getAttribute('id') !== `${id.value}`)

  otherSelects.forEach((el: CustomSelect) => {
    el.closeDropDown()
  })
}

function closeAllSelects() {
  const selects = [...document.querySelectorAll('.select')] as Array<CustomSelect>

  selects.forEach((el: CustomSelect) => {
    el.closeDropDown()
  })
}

const openDropDown = () => {
  closeOtherSelects()

  isOpen.value = true

  options.value = props.options

  nextTick(setListWidth)
}

const closeDropDown = (e?: Event) => {
  if (!isOpen.value) return

  if (e) {
    const { target } = e

    const isNotSelect = (target as HTMLElement).closest('.select')

    if (!isNotSelect && isOpen.value) {
      isOpen.value = false
    }
  } else {
    isOpen.value = false
  }
}

const selectOption = (selected: SelectOption) => {
  emit('update:modelValue', selected)

  closeAllSelects()
}

const searchHandler = (event: Event) => {
  if (!event.target) return

  const value = (event.target as HTMLInputElement).value

  if (!value) {
    options.value = props.options

    return
  }

  options.value = props.options.filter((el) => el.title.toLowerCase().includes(value.toLowerCase()))
}

onUnmounted(() => {
  window.removeEventListener('resize', setListWidth)
  document.removeEventListener('click', closeDropDown)
})

defineExpose({
  closeDropDown
})
</script>

<template>
  <div :id="`${id}`" ref="selectEl" :class="selectClasses" class="select">
    <input
      ref="input"
      :[readonly]="'true'"
      :class="{
        search: !readonly
      }"
      :placeholder="props.placeholder"
      :value="modelValue?.title"
      class="select__input"
      type="text"
      :style="{
        backgroundColor: mode === 'color' ? (modelValue?.value as string) : ''
      }"
      @input="searchHandler"
      @click="openDropDown"
    />

    <IconBase class="select__icon" height="8" view-box="0 0 12 8" width="12">
      <IconsSelect></IconsSelect>
    </IconBase>

    <Transition name="fade">
      <ul v-if="isOpen" ref="listOptionsEl" class="select__list">
        <li>
          <BackgroundContextMenu :second-gradient="false"></BackgroundContextMenu>
          <ul class="select-list__wrapper custom-scroll">
            <slot>
              <li
                v-for="(option, index) in options"
                :key="`${index}-option`"
                :class="{
                  active: option.id === modelValue?.id,
                  color: mode === 'color'
                }"
                class="select__option"
                :style="{
                  backgroundColor: mode === 'color' ? (option?.value as string) : ''
                }"
                @click="() => selectOption(option)"
              >
                {{ option.title }}
              </li>
            </slot>
          </ul>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<style lang="scss">
.select {
  &__option {
    display: inline-block;
    width: 100%;
    padding: 15px 2rem;
    transition: 0.3s;
    border-block-end: solid 1px #686868;
    font-family: $f-family-base;
    font-size: 0.875em;
    letter-spacing: 0;
    line-height: 1.14rem;
    text-align: left;

    @include color('fontColor');

    cursor: pointer;

    &.active {
      color: #d68166;
    }

    &.color {
      border-block-end: none;
    }

    &:hover {
      @include background-color('selectMenuFocusedBG');
    }

    &.disabled {
      cursor: auto;

      &:hover {
        background-color: transparent;
      }
    }

    &:last-child {
      border-block-end: none;
    }
  }
}
</style>

<style lang="scss">
.select__icon {
  g path {
    @include fill('headerNavIconHover');
  }
}
</style>

<style lang="scss" scoped>
.select {
  position: relative;
  width: 100%;
  height: 100%;

  &__input {
    width: 100%;
    height: 100%;
    padding: 0 2.14rem;
    border: 0;
    background-color: transparent;
    font-family: $f-family-caption;
    font-size: 1em;
    cursor: pointer;

    @include color('fontColor');

    &::placeholder {
      @include color('fontColor');
    }

    &.search {
      cursor: auto;
    }
  }

  &__list {
    position: fixed;
    z-index: 1000;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: 10px 10px 15px 0 rgba(0 0 0 / 30%);
  }

  &__icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 40px;
    height: max-content;
    transform: translateY(-50%);
    pointer-events: none;

    path {
      @include fill('fontColor');
    }
  }

  &-list {
    &__wrapper {
      display: grid;
      position: relative;
      z-index: 10;
      max-height: 350px;
      overflow-y: auto
    }
  }

  &.opened {
    .select__icon {
      transform: rotate(180deg) translateY(7px);
    }
  }
}
</style>
