<script setup lang="ts"></script>

<template>
  <!-- <path d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20Z" /> -->
  <path
    fill="#D68166"
    d="M12 25.538a.692.692 0 0 0 .692.692h1.167a6.19 6.19 0 0 0 4.407-1.824l8.045-8.045a3.14 3.14 0 1 0-4.442-4.441l-8.045 8.045A6.19 6.19 0 0 0 12 24.372v1.166ZM25.331 12.9a1.752 1.752 0 0 1 0 2.482l-.869.87-2.482-2.483.87-.869a1.794 1.794 0 0 1 2.481 0ZM13.385 24.372a4.818 4.818 0 0 1 1.42-3.427L21 14.748l2.482 2.482-6.197 6.197a4.818 4.818 0 0 1-3.427 1.419h-.474v-.474Z"
  />
  <path
    fill="#D68166"
    fill-rule="evenodd"
    d="M12 28.271c0-.402.326-.728.729-.728H26.7a.729.729 0 1 1 0 1.457H12.729a.729.729 0 0 1-.729-.729Z"
    clip-rule="evenodd"
  />
</template>
