<script setup lang="ts">
import AppPopup from '@/components/ui/AppPopup.vue'
import CreateUser from '@/components/layouts/profile/Users/CreateUser.vue'
import UserTable from '@/components/layouts/profile/Users/UserTable.vue'
import AppPagination from '@/components/ui/AppPagination.vue'
import InputField from '@/components/ui/InputField.vue'
import { computed, ref, reactive } from 'vue'
import { useTerritoriesStore } from '@/stores/territories'
import { useUserStore } from '@/stores'
import IconProfile from '@/components/icons/IconProfile.vue'
import IconBase from '@/components/icons/IconBase.vue'
import IconClose from '@/components/icons/IconClose.vue'

const isOpen = ref(false)

const territoriesStore = useTerritoriesStore()
territoriesStore.fetchAll()
const territories = computed(() => territoriesStore.territories)

const userStore = useUserStore()
// userStore.fetchPagination()
const pagination = userStore.pagination

const filters = reactive({
  terr_name: '',
  user_name: ''
})

function toggleCreateUser() {
  isOpen.value = !isOpen.value
}

const slicedTerritories = computed(() => {
  const withUsers = territories.value.filter((t) => {
    const needed = pagination.data?.map((u) => {
      return u.territory_id
    })
    return needed?.includes(t.id)
  })

  return withUsers

  // const rest = territories.value.filter((t) => {
  //   return !withUsers.includes(t)
  // })

  // return withUsers.concat(rest)
})

async function changePage(pageNumber: number) {
  pagination.page_number = pageNumber
  await userStore.fetchPagination()
}

// @ts-ignore
const filteredUsers = computed(() => {
  if (!filters.user_name) {
    return pagination.data
  }
  return pagination.data?.filter((user) => {
    return (user.last_name + ' ' + user.first_name + ' ' + user.middle_name)
      .toLowerCase()
      .includes(filters.user_name.toLowerCase())
  })
})
</script>

<template>
  <div class="users">
    <div class="users__title">
      <h3>Пользователи</h3>
      <div class="users__title--buttons">
        <div class="users__title--button" @click="toggleCreateUser">
          <div class="users__title--icon">
            <IconBase width="25" height="25" view-box="12.5 13.5 24 25">
              <IconProfile />
            </IconBase>
          </div>
          <span> Новый пользователь </span>
        </div>
        <div class="users__title--button" @click="console.log('территория')">
          <div class="users__title--icon rotate">
            <IconBase width="15" height="15" view-box="0 0 14 14" transform="rotate(45)">
              <IconClose />
            </IconBase>
          </div>
          <span> Новая территория </span>
        </div>
      </div>
    </div>
    <div class="users__filters">
      <InputField
        v-model="filters.terr_name"
        class="user__filters__input"
        :type="'text'"
        :placeholder="'Название территории'"
        disabled="true"
      ></InputField>
      <InputField
        v-model="filters.user_name"
        class="user__filters__input"
        :type="'text'"
        :placeholder="'ФИО пользователя'"
        disabled="true"
      ></InputField>
    </div>
    <div class="users__table">
      <UserTable :territories="slicedTerritories" :users="pagination.data" />
    </div>
    <div class="users__table__pagination">
      <AppPagination
        :current="pagination.page_number"
        :left="5"
        :max="pagination.number_of_pages"
        :navigation="true"
        @change-page="changePage"
      />
    </div>
    <AppPopup :is-open="isOpen" :title="'Новый пользователь'" @close-modal="toggleCreateUser">
      <CreateUser />
    </AppPopup>
  </div>
</template>

<style lang="scss">
.page .modal__slot {
  background-color: rgb(32 31 34 / 100%);
}
.users {
  // display: flex;
  // flex-direction: column;
  @include background-color('widgetBG');
  @include color('fontColor');

  > div {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 30px;

    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #686868;
    }
  }

  &__title {
    &--buttons {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 50px;
    }

    &--button {
      display: grid;
      grid-auto-flow: column;
      column-gap: 10px;
      align-items: center;
      cursor: pointer;
    }
    &--icon {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      transition: all 0.2s $main-animation 0.1s;
      border-radius: 100%;
      fill: $c-orange;
      @include background-color('headerNavIconBG');

      cursor: pointer;

      svg {
        transition: all 0.2s $main-animation;
      }
    }
  }

  &__filters {
    display: grid;
    column-gap: 50px;
    justify-content: start !important;

    input {
      min-width: 400px;
      font-family: $f-family-base;
      font-weight: 600;
    }
  }

  &__table {
    display: block !important;
  }
}

.rotate {
  svg {
    transform: rotate(90);
  }
}
</style>
