export enum SemaphoresGost {
  T1 = 'Т.1',
  T1r = 'Т.1.п',
  T1l = 'Т.1.л',
  T1rl = 'Т.1.п.л',
  T1h = 'Т.1.г',
  T2 = 'Т.2',
  T3 = 'Т.3',
  T3l = 'Т.3.л',
  T3r = 'Т.3.р',
  T4 = 'Т.4',
  T4y = 'Т.4.ж',
  T5 = 'Т.5',
  T6 = 'Т.6',
  T6d = 'Т.6.д',
  T7 = 'Т.7',
  T8 = 'Т.8',
  T9 = 'Т.9',
  T10 = 'Т.10',
  P1 = 'П.1',
  P2 = 'П.2'
}
