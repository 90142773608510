import { DefectUrlParams } from '@/types/RoadDiagnostic'

export function getDefectUrl(defectUrlParams: DefectUrlParams) {
  const { roadId, segmentId, activeLine, currentPage, elementsOnPage } = defectUrlParams

  const road = `/roads/${roadId}`
  const defects = `/defects`

  let page = '',
    numbersOfElements = '',
    segment = '',
    line = ''

  if (segmentId) {
    page = currentPage ? `/?page_number=${currentPage}` : ''
    numbersOfElements = elementsOnPage ? `&elements_on_page=${elementsOnPage}` : ''

    segment = segmentId
      ? !currentPage && !numbersOfElements
        ? `/?segments_id=${segmentId}`
        : `&segments_id=${segmentId}`
      : ''

    line = activeLine ? (activeLine ? `&line_number=${activeLine}` : '') : ''
  }

  return road + defects + page + numbersOfElements + segment + line
}
