<script setup lang="ts"></script>

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.1736 20L25 13.1071L22.9132 11L14 20L22.9132 29L25 26.8929L18.1736 20Z"
    fill="currentFill"
  />
</template>
