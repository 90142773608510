export const roadObjectOpacity = [
  'interpolate',
  ['exponential', 15],
  ['zoom'],
  0,
  0,
  16.5,
  0.5,
  19.5,
  1
]
